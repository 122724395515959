import React from "react"

export default props => {

    const { data, widthScreen } = props

    return (
        data.map((item, i) => (
            <table width="100%" key={i} style={{ border: "none" }}>
                <thead>
                    <tr>
                        <td rowSpan="4" style={{ textAlign: "left", width: widthScreen <= 991 ? "15%" : "7%" }}>
                            <img width="40" src={item.ownerPhoto} alt="" />
                        </td>

                        <td style={{ textAlign: "left" }}>
                            <b>{item.ownerName}</b>
                        </td>

                        <td id="desktop-mode" style={{ width: "50%" }} rowSpan="4">
                            <b style={{ color: "#ff5100", fontSize: "20px" }}>
                                Rp. {item.totalDonation.toLocaleString()}
                            </b>
                        </td>
                    </tr>

                    <tr>
                        <td style={{ textAlign: "left" }}>
                            <a href={`/${item.key}`} >
                                <b style={{ color: "#ff5100" }}>
                                    {item.title}
                                </b>
                            </a>
                        </td>
                    </tr>

                    <tr>
                        <td style={{ textAlign: "left" }}>
                            Telah mengajak {item.totalContribute} orang berdonasi
                        </td>
                    </tr>

                    <tr id="mobile-mode">
                        <td>
                            <b style={{ color: "#ff5100", fontSize: "20px" }}>
                                Rp. {item.totalDonation.toLocaleString()}
                            </b>
                        </td>
                    </tr>
                </thead>
            </table>
        ))
    )
}