import React, { useEffect, useState } from "react"
import edotensei from "edotensei"
// import axios from "axios"

import Navigation from "../includes/non-admin-components/navigation"
import Footer from "../includes/non-admin-components/footer"
import scripts from "../includes/external-scripts"
import bestfriend from "./bestfriend-components/bestfriend-data"

const { add } = edotensei

var componentDidmount = false

export default props => {

    const [values, setValues] = useState({ bestfriend: [] })

    useEffect(() => {

        (async () => {

            if (!componentDidmount) {

                try {

                    setValues({ ...values, bestfriend: bestfriend() })

                    add(scripts())

                } catch (error) {

                    // add(scripts())
                }

                componentDidmount = true
            }
        })()
    })

    const { name, picture } = props.auth.data || { name: undefined, picture: undefined }

    return (

        <div style={{ background: "#fff" }}>

            <Navigation auth={{ name, picture }} />

            <div id="titlebar" style={{ marginBottom: "0", background: "#23cbf5" }} className="gradient">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <center>
                                <h2 style={{ color: "#fff" }}><b>Sahabat Kebaikan</b></h2>
                                {/* <p style={{ color: "#fff" }}>Langkah mudah Berdonasi di Satukebaikan</p> */}
                                <br />
                                <br />
                            </center>
                        </div>
                    </div>
                </div>
            </div>

            <div id="background-summary" className="container" style={{ background: "#fff" }}>
                <br />
                <div className="row">
                    <div
                        style={{
                            maxWidth: "100%",
                            paddingTop: "30px",
                            paddingBottom: "30px",
                            textAlign: "center",
                            height: "200px",
                            verticalAlign: "center"
                        }}
                    >

                        {
                            values.bestfriend.map((i, x) => (
                                <div key={x} className="col-md-2 col-xs-4">
                                    <br />
                                    <center>

                                        <img
                                            style={{ width: i.width }}
                                            src={i.img}
                                            alt=""
                                        />

                                        {/* <hr /> */}
                                    </center>
                                </div>
                            ))
                        }

                    </div>
                </div>
            </div>

            <br />
            <br />
            <Footer />

        </div>
    )

}
