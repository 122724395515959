import React, { Component } from "react"
import Edotensei from "edotensei"

import Navigation from "../includes/non-admin-components/navigation"
import Footer from "../includes/non-admin-components/footer"

const { add } = Edotensei;

const Scripts = [{ src: "/scripts/jquery-2.2.0.min.js", rel: "preload" }, { src: "/scripts/mmenu.min.js", defer: true }, { src: "/scripts/chosen.min.js", defer: true }, { src: "/scripts/slick.min.js", defer: true }, { src: "/scripts/rangeslider.min.js", defer: true }, { src: "/scripts/magnific-popup.min.js", defer: true }, { src: "/scripts/waypoints.min.js", defer: true }, { src: "/scripts/counterup.min.js", defer: true }, { src: "/scripts/tooltips.min.js", defer: true }, { src: "/scripts/custom.js", defer: true }]

class Notfound extends Component {
  componentDidMount() {
    add(Scripts);
  }

  render() {

    const { name, picture } = this.props.auth.data || { name: undefined, picture: undefined }

    return (
      <div>
        {/* Load Navigation html Syntax */}
        <Navigation auth={{ name, picture }} />
        {/*End*/}

        {/*Content 404 Not found*/}
        <div className="container responsive-margin-top">
          <div className="row">
            <div className="col-md-12">
              <section id="not-found" className="center">
                <h2>
                  404 <i className="fa fa-question-circle" />
                </h2>
                <p>
                  We're sorry, but the page you were looking for doesn't exist.
                </p>
              </section>
            </div>
          </div>
        </div>
        {/*End*/}

        {/* Load footer html Syntax */}
        <Footer />
      </div>
    );
  }
}

export default Notfound;
