import React, { Component } from "react"

class Nav extends Component {
  /**
   * render the component
   */
  render() {

    const { picture, name } = this.props

    return (
      <header id="header-container" className="fixed fullwidth dashboard">
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />

        <div id="header" className="not-sticky">
          <div className="container">
            <div className="left-side">
              <div id="logo">
                <a href="/">
                  <img style={{ width: "200px" }} src="/images/logo-a.png" alt="" />
                </a>
                <a href="/" className="dashboard-logo">
                  <img style={{ width: "200px" }} src="/images/logo-a.png" alt="" />
                </a>
              </div>

              <div className="mmenu-trigger" style={{ background: "transparent" }}>
                <button className="hamburger hamburger--collapse" type="button">
                  <span className="hamburger-box">
                    <span className="hamburger-inner" />
                  </span>
                </button>
              </div>

              <nav id="navigation" className="style-1">
                <ul id="responsive">
                  <li className="navigation">
                    <a href="/dashboard/galang-dana">Galang dana</a>
                  </li>
                </ul>
              </nav>
              <div className="clearfix" />
            </div>

            <div className="right-side">
              <div className="header-widget">
                <div className="user-menu">
                  <div className="user-name">
                    <span style={{ width: "40px", height: "40px" }}>
                      <img
                        style={{ border: "1px solid #eee" }}
                        width="100%"
                        height="100%"
                        src={picture}
                        alt=""
                      />
                    </span>
                    Hi,{" "}
                    {name}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Nav;
