import React, { Component } from "react"
import firebase from "firebase/app"
import "firebase/database"
import "firebase/storage"
import Dropzone from "react-dropzone"
import Edotensei from "edotensei"
import iziToast from "izitoast"

import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import uploder from "./adapter"

import Navbar from "../includes/admin-components/admin-navigations"
import Sidebar from "../includes/admin-components/admin-sidebar"
import Regency from "../non-admin/explorer-all-components/regency"

import "./css/datedropper.css"
// import "./css/jodit-custom.css"
import "izitoast/dist/css/iziToast.min.css"

const { add } = Edotensei;

const Scripts = [{ src: "/scripts/jquery-2.2.0.min.js", rel: "preload" }, { src: "/scripts/mmenu.min.js", defer: true }, { src: "/scripts/chosen.min.js", defer: true }, { src: "/scripts/slick.min.js", defer: true }, { src: "/scripts/rangeslider.min.js", defer: true }, { src: "/scripts/magnific-popup.min.js", defer: true }, { src: "/scripts/waypoints.min.js", defer: true }, { src: "/scripts/counterup.min.js", defer: true }, { src: "/scripts/tooltips.min.js", defer: true }, { src: "/scripts/custom.js", defer: true }]

const readData = (pathname, callback) => {
  firebase
    .database()
    .ref()
    .child(pathname)
    .once("value", snap => {
      callback(snap.val());
    });
};

const createData = (key, data) => {

  firebase
    .database()
    .ref("/campaign/" + key)
    .set(data, () => {

      iziToast.show({
        class: "izimodal",
        timeout: 3000,
        close: true,
        overlay: true,
        progressBar: false,
        title: "Selamat! kampanyemu sudah live dan siap menerima donasi",
        message: "ajak teman dan keluarga untuk berdonasi dengan membagikan link dibawah ini",
        messageLineHeight: "50px",
        position: "center",
        backgroundColor: "#25d366",
        messageColor: "#fff",
        titleColor: "#fff",
        iconColor: "#fff",
        layout: 2,
        balloon: true,
        icon: "fa fa-check",
        transitionIn: "bounceInLeft",
        onClosing: () => {

          window.location.href = "/" + key
        }
      })
    })
}

class addCampaign extends Component {

  constructor(props) {
    super(props);

    this.state = {

      isVerified: {
        status: true,
        message: "",
        img: "",
        info: ""
      },

      category: { title: "Loading..." },
      isUploading: false,
      progress: 0,
      linkIsValid: false,
      isLoaded: false,
      syarat: false,

      title: "",
      donationTarget: "",
      tags: undefined,
      deadline: "",
      location: "",
      link: "",
      image: undefined,
      shortDescription: "",
      content: "" //deskripsi lengkap
    }

    this.inputHandler = this.inputHandler.bind(this);
  }

  componentWillUnmount() {

    this.isCancelled = true;
  }

  async componentWillMount() {

    const { uid } = this.props.auth.data

    this.setState({ tags: undefined });

    const snap = await firebase.database().ref("users").child(uid).child("verificationData").once("value")

    snap.exists()
      ? snap.val().status === "pending"
        ? this.setState({
          isVerified:
          {
            status: false,
            message: "Mohon menunggu, akun anda sedang dalam proses verifikasi.",
            img: "http://c2tiapps.com//Repositorio/843/Publicacoes/14512/0a9ee321-c.png",
            info: "pending"
          }
        })
        : this.setState({
          isVerified:
          {
            status: true,
          }
        })
      : this.setState({
        isVerified:
        {
          status: false,
          message: "Penting! Untuk memulai GALANG DANA, Anda harus melakukan verifikasi akun.",
          img: "https://sharinghappiness.org/files/email-notverified.png",
          info: "non"
        }
      })

    await readData("/tags", value => {

      !this.isCancelled && this.setState({ category: value, isLoaded: true });

      add(Scripts);
    })
  }

  componentDidMount() {

    add([
      {
        src: "/scripts/jquery-2.2.0.min.js",
        rel: "preload"
      },
      {
        src: "/scripts/datedropper.js",
        defer: true
      },
      {
        src: "/scripts/datedropperEx.js",
        defer: true
      }
    ])
  }

  handleSyarat() {

    this.setState({ syarat: !this.state.syarat })
  }

  /**
   * image handler
   * @param image
   */
  imageHandler(image) {

    const fileUpload = image[0];

    this.setState({ isUploading: true, image: undefined });

    const setProgress = percent => {
      this.setState({ progress: percent });
    };

    const setImageURl = url => {
      this.setState({ image: { preview: url }, isUploading: false });
    };

    const storageRef = firebase.storage().ref();

    const campaignImageRef = storageRef.child("campaign/" + fileUpload.lastModified + new Date() + ".jpg");

    const metadata = {
      contentType: "image/jpeg"
    };

    const uploadTask = campaignImageRef.put(fileUpload, metadata);

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      snapshot => {

        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        setProgress(Math.ceil(progress));
      },

      error => {

        alert(error.code);
      },
      async () => {

        const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()

        setImageURl(downloadURL);
      }
    )
  }

  handleDescription(e) {

    if (e.target.value.length >= 161) return;

    this.setState({
      shortDescription: e.target.value
    })
  }

  updateContent(event, editor) {

    const value = editor.getData()

    this.setState({ content: value })
  }

  inputHandler(e) {

    this.setState({
      [e.target.name]: e.target.value
    })
  }

  checkLinkHandler(e) {

    let { value } = e.target;

    value = value.replace(/ |\.|#|\$|\[|\]/gi, "");

    this.setState({ link: value });

    if (value.length <= 0) return this.setState({ linkIsValid: "" })

    readData("/campaign/" + value, snap => {

      iziToast.show({
        message: snap ? "Mohon maaf link kampanye yang anda masukkan telah digunakan, silahkan coba yang lain!" : "Yeah, link kampanye tersedia.",
        position: "topRight",
        icon: snap ? "fa fa-frown-o" : "fa  fa-smile-o",
        backgroundColor: snap ? "#f96161" : "#25d366",
        messageColor: "#fff",
        iconColor: "#fff",
        balloon: true,
        transitionIn: "bounceInLeft",
        displayMode: 1,
        layout: 1,
        maxWidth: '500px',
        progressBar: false
      })

      this.setState({ linkIsValid: snap ? false : true })

    })
  }

  formateCurrancy(e) {

    let { value } = e.target;

    value = value.replace(new RegExp(",", "g"), "");
    value = value.replace(/\./g, "");

    value = parseInt(value, 0);

    if (isNaN(value)) return this.setState({ donationTarget: "" });

    const valueToLocale = value.toLocaleString();

    this.setState({ donationTarget: valueToLocale.toString() })

  }

  submitHandler(e) {

    e.preventDefault()

    if (!this.state.syarat) return

    let piece = Object.values(this.state.category).filter(key => key.title === document.querySelector("#tags").value)

    if (piece.length <= 0) {

      iziToast.info({
        message: "Potongan donasi belum tersedia, silahkan memilih kategori sekali lagi!",
        position: "topRight",
        icon: "fa fa-frown-o",
        backgroundColor: "	#f96161",
        messageColor: "#fff",
        iconColor: "#fff",
        balloon: true,
        transitionIn: "bounceInLeft",
        displayMode: 2,
        layout: 2,
        maxWidth: '500px'
      })

      return
    }

    piece = piece[0].piece

    if (!this.state.linkIsValid || this.state.linkIsValid === "") {

      iziToast.show({
        message: "Mohon maaf link kampanye yang anda masukkan telah digunakan, silahkan coba yang lain!",
        position: "topRight",
        icon: "fa fa-frown-o",
        backgroundColor: "#f96161",
        messageColor: "#fff",
        iconColor: "#fff",
        balloon: true,
        transitionIn: "bounceInLeft",
        displayMode: 2,
        layout: 1,
        maxWidth: '500px',
        progressBar: false
      })

      return
    }

    if (!this.state.image) {

      iziToast.show({
        message: "Gambar utama belum ada, silahkan menggungah gambar utama anda!",
        position: "topRight",
        icon: "fa fa-frown-o",
        backgroundColor: "#f96161",
        messageColor: "#fff",
        iconColor: "#fff",
        balloon: true,
        transitionIn: "bounceInLeft",
        displayMode: 2,
        layout: 1,
        maxWidth: '500px',
        progressBar: false
      })

      return
    }

    if (!this.state.content) {

      iziToast.show({
        message: "Deskripsi belum ada, silahkan isi deskripsi campaign terlebih dahulu!",
        position: "topRight",
        icon: "fa fa-frown-o",
        backgroundColor: "#f96161",
        messageColor: "#fff",
        iconColor: "#fff",
        balloon: true,
        transitionIn: "bounceInLeft",
        displayMode: 2,
        layout: 1,
        maxWidth: '500px',
        progressBar: false
      })

      return
    }

    const target = parseInt(this.state.donationTarget.replace(/,|\./gi, ""), 0);

    if (target < 1000000 || isNaN(target)) return iziToast.show({
      message: "Target donasi minimal Rp. 1.000.000!",
      position: "topRight",
      icon: "fa fa-frown-o",
      backgroundColor: "#f96161",
      messageColor: "#fff",
      iconColor: "#fff",
      balloon: true,
      transitionIn: "bounceInLeft",
      displayMode: 2,
      layout: 1,
      maxWidth: '500px',
      progressBar: false
    })

    const { email, name, picture, uid } = this.props.auth.data

    const data = {
      isFundraiser: false,
      title: this.state.title,
      target: target,
      piece: piece,
      category: document.querySelector("#tags").value,
      deadline: document.querySelector("#deadline").value,
      location: document.querySelector("#location").value,
      image: this.state.image.preview,
      shortDescription: this.state.shortDescription,
      description: this.state.content,
      date: firebase.database.ServerValue.TIMESTAMP,
      owner: email,
      ownerName: name,
      ownerPhoto: picture,
      uid: uid
    };

    iziToast.info({
      timeout: 20000,
      close: true,
      overlay: true,
      zindex: 999,
      title: `Hai ${name}`,
      icon: "fa fa-question-circle",
      message: "Apakah informasi yang anda masukkan sudah benar?",
      position: "center",
      backgroundColor: "rgb(35, 203, 245)",
      titleColor: "#fff",
      color: "#fff",
      iconColor: "#fff",
      messageColor: "#fff",
      layout: 1,
      balloon: true,
      transitionIn: "bounceInLeft",
      progressBar: false,
      buttons: [
        [
          "<button class='button' style='background: #fff; color :rgb(35, 203, 245), border-color: #fff '>Ya, sudah benar!</button>",
          () => {
            //hide
            iziToast.destroy();
            //confirm
            createData(this.state.link, data);
          },
          true
        ]
      ]
    });
  }

  upload(editor) {

    const toolbar = document.querySelector('.ck-toolbar')

    toolbar.style = "background : white"

    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      // Configure the URL to the upload script in your back-end here!
      return new uploder(loader);
    }

  }

  render() {

    const { title, donationTarget, category, progress, isUploading, link, isVerified } = this.state

    const { picture, uid, name } = this.props.auth.data

    return (
      <div>

        <Navbar picture={picture} name={name} />

        <div id="dashboard">
          {/* Sidebar*/}
          <Sidebar uid={uid} />
          {/* End Sidebar*/}

          {/*Content*/}
          <div className="dashboard-content">
            <div id="titlebar">
              <div className="row">
                <div className="col-md-12">
                  <h2>Galang dana</h2>
                  <nav id="breadcrumbs">
                    <ul>
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li>Galang dana</li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div id="add-listing">

                  {
                    !isVerified.status
                      ? (
                        <div className="add-listing-section" hidden={!this.state.isLoaded}>
                          <center>
                            <br />
                            <img width="250" src={isVerified.img} alt="" />
                            <h5>
                              <b>{isVerified.message}</b>
                            </h5>

                            {
                              isVerified.info === "non"
                                ? (
                                  <a href="/dashboard/verifikasi-akun" className="button">
                                    Verifikasi Sekarang
                                  </a>
                                )
                                : null
                            }

                          </center>
                        </div>
                      )
                      : (
                        <form
                          hidden={!this.state.isLoaded}
                          onSubmit={this.submitHandler.bind(this)}
                        >
                          <div className="add-listing-section clearfix">
                            <div className="add-listing-headline">
                              <h3>
                                <i className="sl sl-icon-doc" /> Informasi Kampanye
                              </h3>
                            </div>

                            <div className="row with-forms">
                              <div className="col-md-6">
                                <h5>
                                  Judul Kampanye{" "}
                                  <i
                                    className="tip"
                                    data-tip-content="Judul Kampanye yang ingin dibuat"
                                  />
                                </h5>
                                <input
                                  className="search-field"
                                  type="text"
                                  onChange={this.inputHandler}
                                  name="title"
                                  value={title}
                                  required
                                />
                              </div>

                              <div className="col-md-6">
                                <div className="input-with-icon medium-icons">
                                  <h5>Target donasi </h5>
                                  <input
                                    style={{ fontSize: "24px" }}
                                    className="search-field"
                                    type="text"
                                    onChange={this.formateCurrancy.bind(this)}
                                    value={donationTarget}
                                    name="donationTarget"
                                    required
                                  />
                                  <i style={{ fontStyle: "normal" }}>
                                    <b>Rp</b>
                                  </i>
                                </div>
                              </div>
                            </div>

                            <div className="row with-forms">
                              <div className="col-md-6">
                                <h5>Kategori</h5>
                                <select className="chosen-select" id="tags" required>
                                  {Object.values(category).map((item, i) => (
                                    <option key={i}>{item.title}</option>
                                  ))}
                                </select>
                              </div>

                              <div className="col-md-6">
                                <h5>Deadline Kampanye</h5>
                                <input
                                  type="text"
                                  id="deadline"
                                  data-lang="en"
                                  data-large-mode="true"
                                  data-large-default="true"
                                  data-min-year="2017"
                                  data-max-year="2040"
                                  data-format="d F Y"
                                  data-lock="from"
                                />
                              </div>
                            </div>

                            <div className="row with-forms">
                              <div className="col-md-6">
                                <h5>Lokasi penerima dana (kota/kabupaten)</h5>
                                <select
                                  className="chosen-select"
                                  id="location"
                                  required
                                >
                                  {Regency().map((item, i) => (
                                    <option key={i}>{item}</option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-md-6">
                                <h5>Link Kampanye</h5>

                                <div
                                  style={{
                                    position: "absolute",
                                    padding: "13px 10px",
                                    background: "#eee"
                                  }}
                                >
                                  <b>Satukebaikan.com/</b>
                                </div>
                                <input
                                  style={{ paddingLeft: "170px", marginBottom: "0", fontWeight: "bold" }}
                                  placeholder=""
                                  autoComplete="false"
                                  className="search-field"
                                  type="text"
                                  name="link"
                                  onChange={this.checkLinkHandler.bind(this)}
                                  value={link}
                                  required
                                />

                                <span className="margin-top-5" style={{ fontSize: "12px" }}>
                                  Link harus dimulai dengan huruf, tanpa spasi.
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* content */}
                          <div className="row margin-top-30">
                            <div className="col-lg-12">
                              <div id="add-listing">
                                <div className="add-listing-section">
                                  <div className="add-listing-headline">
                                    <h3>
                                      <i className="sl sl-icon-docs" /> Cerita Kampanye
                                    </h3>
                                  </div>

                                  <div className="row with-forms">
                                    <div
                                      className="col-md-12"
                                    >
                                      <h5>
                                        Gambar Utama{" "}
                                        <i
                                          className="tip"
                                          data-tip-content="Rekomendasi Ukuran: 664px X 357px. Format : PNG/ JPG/ JPEG"
                                        />
                                      </h5>

                                      <Dropzone
                                        className="dropzone"
                                        style={{ cursor: "pointer", height: "100%", overflow: "hidden" }}
                                        onDrop={this.imageHandler.bind(this)}
                                        accept="image/jpeg,image/jpg,image/tiff,image/png,image/gif"
                                        multiple={false}
                                        disabled={isUploading}
                                      >

                                        <div style={{ cursor: "pointer", textAlign: "center" }}>
                                          <div
                                            // hidden={!isUploading}
                                            className="progress-bar"
                                            style={{ overflow: "hidden", position: "absolute", left: "0", bottom: "0" }}
                                          >
                                            <span
                                              className="progress-bar-fill"
                                              style={{ width: `${progress}%` }}
                                            />
                                          </div>

                                          {
                                            this.state.image
                                              ? <img width="100%" src={this.state.image ? this.state.image.preview : null} alt="" />
                                              : (
                                                <div style={{ cursor: "pointer", textAlign: "center" }}>
                                                  <h2>
                                                    <b style={{ color: "#ccc" }}><i className="fa fa-upload" /></b>
                                                  </h2>
                                                  <h4>
                                                    <b style={{ color: "#ccc" }}>Upload gambar utama</b>
                                                  </h4>
                                                </div>
                                              )
                                          }

                                        </div>
                                      </Dropzone>

                                    </div>
                                  </div>

                                  <div className="row with-forms margin-top-10">
                                    <div
                                      style={{ height: "200px" }}
                                      className="col-md-12"
                                    >
                                      <h5 style={{ paddingBottom: "7px" }}>
                                        Deskripsi ({" "}
                                        {this.state.shortDescription.length}/160
                                        Karakter)
                                      </h5>
                                      <textarea
                                        style={{ height: "100%" }}
                                        value={this.state.shortDescription}
                                        name="shortDescription"
                                        onChange={this.handleDescription.bind(this)}
                                        required
                                      />
                                    </div>
                                  </div>

                                  <div className="row with-forms margin-top-60">
                                    <div className="col-md-12">
                                      <h5 style={{ paddingBottom: "7px" }}>
                                        Deskripsi Lengkap
                                      </h5>
                                      {/* <JoditEditor
                                        editorRef={this.setRef}
                                        config={this.config}
                                        value={this.state.content}
                                        onChange={this.updateContent.bind(this)}
                                      /> */}

                                      <CKEditor
                                        data={this.state.content}
                                        onInit={this.upload}
                                        config={{
                                          toolbar: ['imageUpload', 'link', '|', 'heading', '|', 'bold', 'italic', '|', 'undo', 'redo',]
                                        }}
                                        editor={ClassicEditor}
                                        onChange={this.updateContent.bind(this)}
                                      />

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="add-listing-section margin-top-20 clearfix">
                            <div className="checkboxes margin-top-30" style={{ padding: "10px 20px", background: "#f9f9f9" }}>
                              <input
                                id="anonim"
                                type="checkbox"
                                name="anonim"
                                defaultChecked={this.state.syarat}
                                onClick={this.handleSyarat.bind(this)}
                              />
                              <label
                                htmlFor="anonim"
                                style={{ fontSize: "12px" }}
                              >
                                Saya setuju dengan Syarat & Ketentuan Campaign di satukebaikan, termasuk biaya administrasi platform paling besar 5% dari total donasi onilne yang terkumpul.
                                    </label>
                            </div>

                            <button
                              type="submit"
                              className="button preview right"
                              style={{ float: "right", borderRadius: "5px", background: "#fff", border: "1px solid #23cbf5", color: "#23cbf5", marginTop: "20px" }}
                            >
                              Terbitkan Kampanye <i className="fa fa-arrow-circle-up" />
                            </button>
                          </div>
                        </form>
                      )
                  }

                  <div className="col-md-12">
                    <center hidden={this.state.isLoaded}>
                      <br />
                      <br />
                      <div className="lds-dual-ring" />
                      <br />
                      <br />
                      <br />
                    </center>
                    <div className="copyrights">
                      © 2018 Satukebaikan indonesia. All Rights Reserved.
                    </div>
                  </div>
                  <br />
                  {/*End*/}
                </div>
              </div>
            </div>
          </div>
          {/*End*/}
        </div>
      </div>
    );
  }
}

export default addCampaign;
