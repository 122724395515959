import React, { Component } from "react"
import izitoast from "izitoast"
import "izitoast/dist/css/iziToast.min.css"
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/database"

/**
 * query ke database
 * @param callback
 */
const readData = callback => {
  firebase
    .database()
    .ref()
    .child("/campaign")
    .on("value", snap => {

      const value = snap.exists() ? snap.val() : {};

      callback(value);
    });
};

class Sidebar extends Component {
  constructor() {
    super();
    this.state = {
      pendingTotal: 0
    };
  }

  /**
   * dieksekusi ketika component ditinggalkan
   */
  componentWillUnmount() {
    this.isCancelled = true;
    /**
     * remove script
     */
  }

  /**
   * fungsi ini di eksekusi sebelum component dirender
   */
  componentWillMount() {
    /**
     * fungsi filter
     * @param data
     */
    const filter = data => {
      let total = 0;
      /**
       * filtering untuk mendapatkan total dari kontribusi yang statusnya pending
       */
      Object.keys(data).filter(key => {
        if (data[key].contribute) {
          let status = Object.values(data[key].contribute).filter(
            contribute => {
              return contribute.status === "Pending";
            }
          );
          total += status.length;
          this.setState({ pendingTotal: total });
        }
        return 0;
      });
    };

    /**
     * query ke database
     */
    readData(data => {
      /**
       * menjalankan fungsi
       */
      return (!this.isCancelled && filter(data)) || {};
    });
  }

  /**
   * execute this function after component rendered
   */
  componentDidMount() {
    /**
     * get pathname from window
     */
    let pathName = window.location.href;
    /**
     * select dom by class
     * return value array
     */
    let dom = document.getElementsByClassName("side");
    /**
     * melakukan perulangan
     */
    for (let i = 0; i < dom.length; i++) {
      /**
       * melakukan perulangan
       */
      for (let index = 0; index < dom[i].children.length; index++) {
        /**
         * melakukan pengecekan apakah href sama dengan pathname
         */
        if (dom[i].children[index].firstElementChild.href === pathName) {
          /**
           * kalau sama maka buat attribut class dengan nilai active
           */
          dom[i].children[index].setAttribute("class", "active");
          /**
           * hentikan perulangan
           */
          break;
        } else {
          /**
           * melakukan perulangan
           */
          for (
            let index2 = 0;
            index2 < dom[i].children[index].children.length;
            index2++
          ) {
            for (var x in dom[i].children[index].children[index2].children) {
              if (
                dom[i].children[index].children[index2].children[x]
                  .firstChild &&
                dom[i].children[index].children[index2].children[x].firstChild
                  .href === pathName
              ) {
                /**
                 * kalau sama maka buat attribut class dengan nilai active
                 */
                dom[i].children[index].setAttribute("class", "active");
                /**
                 * hentikan perulangan
                 */
                break;
              }
            }
          }
        }
      }
    }
  }

  logout() {

    izitoast.info({
      timeout: 20000,
      close: true,
      overlay: true,
      imageWidth : 100,
      icon: "fa fa-question",
      title: "Halo baikers (orang baik)",
      message: "Keluar Sekarang?",
      position: "center",
      backgroundColor: "rgb(35, 203, 245)",
      titleColor: "#fff",
      color: "#fff",
      iconColor: "#fff",
      messageColor: "#fff",
      layout: 1,
      balloon: true,
      transitionIn: "bounceInLeft",
      buttons: [
        [
          "<button class='button'>ya, keluar sekarang!</button>",
          () => { firebase.auth().signOut() } , true
        ]
      ]
    });

  }

  render() {

    const { uid } = this.props

    return (
      <div id="wrapper-sidebar">
        <a href="#!" onClick={e => e.preventDefault()} className="dashboard-responsive-nav-trigger">
          <i className="fa fa-reorder" /> Dashboard
        </a>

        <div className="dashboard-nav">
          <div className="dashboard-nav-inner" style={{ maxHeight: "600px" }}>
            <ul data-submenu-title="Utama" className="side">
              <li>
                <a href="/dashboard/overview">
                  <i className="sl sl-icon-settings" /> Overview
                </a>
              </li>
              {uid === process.env.REACT_APP_SUPER_ADMIN_UID ? (
                <li>
                  <a href="/dashboard/donatur">
                    <i className="sl sl-icon-people" /> Donatur{" "}
                    {this.state.pendingTotal > 0 ? (
                      <span
                        style={{ background: "#61b2db" }}
                        className="nav-tag messages"
                      >
                        {this.state.pendingTotal}
                      </span>
                    ) : null}
                  </a>
                </li>
              ) : null}
            </ul>

            <ul data-submenu-title="Program" className="side">

              <li>
                <a href="/dashboard/donasiku">
                  <i className="sl sl-icon-emotsmile" /> Donasiku
                </a>
              </li>

              <li>
                <a href="#!">
                  <i className="sl sl-icon-heart" /> Cairkan Dana
                </a>
              </li>

              <li>
                <a href="/dashboard/galang-dana">
                  <i className="sl sl-icon-plus" /> Galang dana
                </a>
              </li>

              <li>
                <a href="#!" onClick={e => e.preventDefault()}>
                  <i className="sl sl-icon-layers" /> Kampanyeku
                </a>
                <ul>
                  <li><a href="/dashboard/kampanye-berjalan">Berjalan</a></li>
                  <li><a href="/dashboard/kampanye-selesai">Selesai</a></li>
                  {/* <li><a href="/dashboard/campaign-selesai">Draft</a></li> */}
                </ul>
              </li>

            </ul>

            {/* <ul data-submenu-title="Blog" className="side">
              <li>
                <a>
                  <i className="im im-icon-Blogger" /> Cerita Kebaikan
                </a>
                <ul>
                  <li>
                    <a href="/dashboard/blog/buat-postingan">Buat postingan</a>
                  </li>
                  <li>
                    <a>Postingan</a>
                  </li>
                  <li>
                    <a>Draft</a>
                  </li>
                </ul>
              </li>
            </ul> */}



            <ul data-submenu-title="Pengaturan" className="side">
              <li>
                <a href="#!" onClick={e => e.preventDefault()}>
                  <i className="sl sl-icon-user" /> Akun
                </a>
                <ul>

                  <li>
                    <a href="/dashboard/profil">
                      Edit Profil
                    </a>
                  </li>
                  {
                    uid !== process.env.REACT_APP_SUPER_ADMIN_UID
                      ? <li>
                        <a href="/dashboard/verifikasi-akun">
                          Verifikasi Akun
                    </a>
                      </li> : null
                  }

                  {/* <li>
                    <a href="/dashboard/rekening-saya">
                      Rekening Saya
                    </a>
                  </li> */}

                </ul>
              </li>

              {
                uid === process.env.REACT_APP_SUPER_ADMIN_UID ? (
                  <li>
                    <a href="/dashboard/pengaturan/kategori"> <i className="sl sl-icon-tag" /> Kategori</a>
                  </li>
                ) : null
              }

              {
                uid === process.env.REACT_APP_SUPER_ADMIN_UID ? (
                  <li>
                    <a href="/dashboard/pengaturan/pengguna"> <i className="sl sl-icon-user-follow" /> Pengguna</a>
                  </li>
                ) : null
              }

              <li>
                <a href="#!" onClick={this.logout.bind(this)}>
                  <i className="sl sl-icon-power" /> Logout
                </a>
              </li>
            </ul>

          </div>
        </div>
      </div>
    );
  }
}

export default Sidebar;
