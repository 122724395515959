export default () => [
    {
        category: "panduan umum",
        question: "Apa itu Satukebaikan?",
        answer: 'Anda bisa membaca profil kami <a href="/info/tentang-kami"><b>disini</b></a>'
    },
    {
        category: "panduan umum",
        question: 'Bagaimana cara Register & Login di Satukebaikan?',
        answer: 'Untuk Register, Anda bisa langsung klik Tombol Masuk yang ada di Menu Paling atas sebelah kanan. atau klik <a href="/dashboard/login"><b>disini</b></a> lalu klik masuk menggunakan Email ataupun Facebook.'
    },
    {
        category: "donatur",
        question: 'Bagaimana cara berdonasi di Satukebaikan?',
        answer: '1. Pilih kampanye atau program yang disukai <br />2. Klik tombol "Donasi Sekarang" <br />3. Masukkan nominal yang ingin didonasikan <br />4. Masukkan Nama, Email, No. HP (apabila anda sudah login sebelumnya maka form akan terisi secara otomatis) <br />5. Pilih metode pembayaran <br />6. Checkout dan teransfer donasi <br /><br />Ket : Pastikan Anda melakukan Transfer sesuai dengan Nominal yang tertera dan metode bayar yang sesuai dengan pilihan pada saat berdonasi agar donasi Anda terverifiaksi dengan cepat.'
    },
    {
        category: "panduan umum",
        question: ' Bagaimana cara edit profil?',
        answer: 'Edit profil bisa dilakukan di menu Dashboard pengguna.'
    },
    {
        category: "panduan umum",
        question: 'Bagaimana caranya agar akun saya terverifikasi?',
        answer: 'Verifikasi akun dapat dilakukan kepada pengguna yang telah terdaftar dan melengkapi form verifikasi yang telah tersedia di menu dashboard pengguna. Pengelola Satukebaikan akan melakukan validasi setelah form validasi dilakukan.'
    },
    {
        category: "donatur",
        question: 'Bagaimana jika pengguna lupa atau salah memasukan kode unik pada nominal transfer?',
        answer: 'Jika pengguna lupa atau salah memasukan kode unik saat transfer segera melakukan konfirmasi <a href="https://wa.me/6282290452354" target="_blank"> <b>disini</b> </a> atau email ke alamat baikers.squad@gmail.com'
    },
    {
        category: "donatur",
        question: 'Bagaimana jika pengguna sudah melakukan transfer namun tidak mendapat notifikasi via sms atau email?',
        answer: 'Jika dalam waktu 1x24 jam donatur belum mendapatkan notifikasi via email atau sms, segera lakukan konfirmasi <a href="https://wa.me/6282290452354" target="_blank"> <b>disini</b> </a> atau email ke alamat baikers.squad@gmail.com'
    },
    {
        category: "donatur",
        question: "Bagaimana cara donasi tanpa mencantumkan nama?",
        answer: "Ketika donatur melakukan Donasi, pada kolom nama donatur silakan centang <b>donasi sebagai anonim</b>"
    },
    {
        category: "kampanye",
        question: "Siapa saja yang bisa menggalang dana di Satukebaikan?",
        answer: "Setiap pengguna Satukebaikan bisa menggalang dana setelah melalui tahap verifikasi"
    }
]