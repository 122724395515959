export default () => [
    {
        nama: 'Syamril ST. MPD',
        jabatan: '',
        img: "https://aireworthvets.co.uk/wp-content/uploads/2018/07/team-placeholder.jpg"
    },
    {
        nama: 'Andi Alrizal Yudi Putranto',
        jabatan: '',
        img: "https://aireworthvets.co.uk/wp-content/uploads/2018/07/team-placeholder.jpg"
    }
]