import firebase from "firebase/app"
import "firebase/database"

class upload {

    constructor(loader) {

        // The file loader instance to use during the upload.
        this.loader = loader

    }

    async upload() {

        const file = await this.loader.file

        return new Promise((resolve, reject) => {

            this._initListeners(resolve, reject, file)
        })
    }


    _initListeners(resolve, reject, file) {

        const loader = this.loader

        const storageRef = firebase.storage().ref()

        const campaignImageRef = storageRef.child("images/" + file.lastModified + new Date() + ".jpg")

        const snap = campaignImageRef.put(file)

        snap.on("state_changed",

            snapshot => {

                loader.uploadTotal = snapshot.totalBytes

                loader.uploaded = snapshot.bytesTransferred //(snapshot.bytesTransferred / snapshot.totalBytes) * 100

            },

            err => {

                return reject(err)

            },
            async () => {

                const url = await snap.snapshot.ref.getDownloadURL()

                resolve({ default: url })
            })

    }

}

export default upload