import React, { Component } from "react"
import firebase from "firebase/app"
import "firebase/database"
import Edotensei from "edotensei"
import ReactGA from 'react-ga'

import CampaignAllitem from "./explorer-all-components/all-item"
import Regency from "./explorer-all-components/regency"
import Footer from "../includes/non-admin-components/footer"
import Navigation from "../includes/non-admin-components/navigation"

const { add } = Edotensei;

const Scripts = [{ src: "/scripts/jquery-2.2.0.min.js", rel: "preload" }, { src: "/scripts/mmenu.min.js", defer: true }, { src: "/scripts/chosen.min.js", defer: true }, { src: "/scripts/slick.min.js", defer: true }, { src: "/scripts/rangeslider.min.js", defer: true }, { src: "/scripts/magnific-popup.min.js", defer: true }, { src: "/scripts/waypoints.min.js", defer: true }, { src: "/scripts/counterup.min.js", defer: true }, { src: "/scripts/tooltips.min.js", defer: true }, { src: "/scripts/custom.js", defer: true }]

class donationProgram extends Component {
  constructor(prosp) {
    super(prosp);
    this.state = {
      isLoaded: false,
      listCategory: {},

      search: "",
      filter: { category: "", regency: "", sort: "" }
    };
  }

  componentWillMount() {

    let { search } = this.props.location

    search = search.replace("?keyword=", "")

    search = search.replace(/\+/g, " ")

    this.setState({ search })

  }

  async componentDidMount() {

    const snap = await firebase.database().ref("/tags").once("value")

    this.setState({ listCategory: snap.val(), isLoaded: true })

    add(Scripts)

    ReactGA.pageview("/explorer/all")
  }

  submitHandler(e) {

    e.preventDefault();

    const category = document.querySelector("#kategori").value;
    const regency = document.querySelector("#wilayah").value;
    const sort = document.querySelector("#urutkan").value;

    this.setState({
      filter: {
        category, regency, sort
      }
    });
  }

  render() {

    const { listCategory, isLoaded } = this.state;

    const { name, picture } = this.props.auth.data || { name: undefined, picture: undefined }

    return (
      <div>

        {/* Load Navigation html Syntax */}
        <Navigation auth={{ name, picture }} />
        {/*End*/}

        {/* Title bar */}
        <div id="filter-desktop" style={{ padding: "15px 0 0", borderBottom: "1px solid #eeeeee" }}>
          <div className="container">
            <div className="row">
              <form onSubmit={this.submitHandler.bind(this)}>

                <div className="with-forms">
                  <div className="col-md-3">
                    <label>Kategori</label>
                    <select
                      style={{ fontSize: "14px" }}
                      data-placeholder="Semua Kategori"
                      className="chosen-select"
                      id="kategori"
                    >
                      <option style={{ fontSize: "14px" }}>
                        Semua Kategori
                      </option>
                      {Object.values(listCategory).map((item, i) => (
                        <option style={{ fontSize: "14px" }} key={i}>
                          {item.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="with-forms">
                  <div className="col-md-3">
                    <label>Wilayah</label>
                    <select
                      style={{ fontSize: "14px" }}
                      data-placeholder="Semua"
                      className="chosen-select"
                      id="wilayah"
                    >
                      <option style={{ fontSize: "14px" }}>Semua</option>
                      {Regency().map((item, i) => (
                        <option style={{ fontSize: "14px" }} key={i}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="with-forms">
                  <div className="col-md-3">
                    <label>Urutkan</label>
                    <select
                      style={{ fontSize: "14px" }}
                      data-placeholder="Urut berdasarkan"
                      className="chosen-select"
                      id="urutkan"
                    >
                      <option style={{ fontSize: "14px" }} value="acak">Acak</option>
                      <option style={{ fontSize: "14px" }} value="terbaru">Terbaru</option>
                      <option style={{ fontSize: "14px" }} value="terlama">Terlama</option>
                    </select>
                  </div>
                </div>

                <div className="with-forms">
                  <div className="col-md-3">
                    <label>&nbsp;</label>
                    <button
                      disabled={!isLoaded}
                      type="submit"
                      className="button border fullwidth"
                      style={{ background: "#fff", border: "1px solid #23cbf5", color: "#23cbf5", borderRadius: "5px" }}
                    >
                      Terapkan filter
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <hr style={{ marginBottom: '0' }} />
        </div>
        {/* End Title bar */}

        {/** filter mobile */}

        <div id="small-dialog" className="zoom-anim-dialog mfp-hide">
          <div className="small-dialog-header">
            <h3>Send Message</h3>
          </div>
          <div className="message-reply margin-top-0">
            <textarea cols="40" rows="3" placeholder="Your message to Tom" />
            <button className="button">Send Message</button>
          </div>
        </div>

        {/*<div id="wrapper-filter-button">
          <a
            style={{
              marginTop: "0",
              width: "100%",
              textAlign: "center",
              borderRadius: "5px",
              fontSize: "20px",
              padding: "5px"
            }}
            href="#small-dialog"
            className="send-message-to-owner button popup-with-zoom-anim"
          >
            <i className="im im-icon-Filter-2" />
            filter
          </a>
          </div>*/}
        {/** end filter mobile */}

        {/* Content*/}
        <div style={{ paddingTop: isLoaded ? '40px' : '400px', position: "relative", transition: "padding-top 0.5s" }}>
          <div className="container">
            <CampaignAllitem search={this.state.search} filter={this.state.filter} />
          </div>
        </div>
        {/* End Content*/}

        {/* Load footer html Syntax */}
        <div hidden={!isLoaded}>
          <Footer />
        </div>
      </div>
    );
  }
}

export default donationProgram;
