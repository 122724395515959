import React, { Component } from "react";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/storage";
import "jodit";
import Dropzone from "react-dropzone";
import Edotensei from "edotensei";
import iziToast from "izitoast";

import Navbar from "../includes/admin-components/admin-navigations"
import Sidebar from "../includes/admin-components/admin-sidebar"

const { add } = Edotensei;

const Scripts = [{ src: "/scripts/jquery-2.2.0.min.js", rel: "preload" }, { src: "/scripts/mmenu.min.js", defer: true }, { src: "/scripts/chosen.min.js", defer: true }, { src: "/scripts/slick.min.js", defer: true }, { src: "/scripts/rangeslider.min.js", defer: true }, { src: "/scripts/magnific-popup.min.js", defer: true }, { src: "/scripts/waypoints.min.js", defer: true }, { src: "/scripts/counterup.min.js", defer: true }, { src: "/scripts/tooltips.min.js", defer: true }, { src: "/scripts/custom.js", defer: true }]

const createData = (uid, data) => {

    firebase.database().ref("users").child(uid).update({ verificationData: data })

    iziToast.info({
        title: "Hi orang baik!",
        message: "Berkas verifikasi akun telah diserahkan!",
        position: "center",
        close: false,
        overlay: true,
        drag: false,
        timeout: "2000",
        onClosing: () => {

            window.location.reload()
        }
    })

}

class Verification extends Component {

    constructor(props) {

        super(props)

        this.ktpPhoto = this.ktpPhoto.bind(this)

        this.verificationPhoto = this.verificationPhoto.bind(this)

        this.rekeningPhoto = this.rekeningPhoto.bind(this)

        this.state = {
            isLoaded: false,

            ktpUpload: false,
            progressKtp: 0,

            verifyUpload: false,
            progressVerify: 0,

            rekeningUpload: false,
            progressRekening: 0,

            ktp: "",
            verification: "",
            rekening: "",
            nomorRekening: "",

            verificationData: {}
        }

    }

    ktpPhoto(image) {

        const imageUpload = image[0]

        this.setState({ ktpUpload: true, ktp: "" })

        const storageRef = firebase.storage().ref("/file-verification").child(imageUpload.lastModified + new Date() + ".jpg")

        const metadata = { contentType: "image/jpeg" }

        const uploadTask = storageRef.put(imageUpload, metadata)

        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, snapshot => {

            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

            this.setState({ progressKtp: Math.ceil(progress) })

        }, error => {

            this.setState({ ktpUpload: false })

            alert(error.message)

        }, async () => {

            const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()

            this.setState({ ktp: downloadURL, ktpUpload: false })

        })

    }

    verificationPhoto(image) {

        const imageUpload = image[0]

        this.setState({ verifyUpload: true, verification: "" })

        const storageRef = firebase.storage().ref("/file-verification").child(imageUpload.lastModified + new Date() + ".jpg")

        const metadata = { contentType: "image/jpeg" }

        const uploadTask = storageRef.put(imageUpload, metadata)

        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, snapshot => {

            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

            this.setState({ progressVerify: Math.ceil(progress) })

        }, error => {

            this.setState({ verifyUpload: false })

            alert(error.message)

        }, async () => {

            const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()

            this.setState({ verification: downloadURL, verifyUpload: false })

        })

    }

    rekeningPhoto(image) {

        const imageUpload = image[0]

        this.setState({ rekeningUpload: true, rekening: "" })

        const storageRef = firebase.storage().ref("/file-verification").child(imageUpload.lastModified + new Date() + ".jpg")

        const metadata = { contentType: "image/jpeg" }

        const uploadTask = storageRef.put(imageUpload, metadata)

        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, snapshot => {

            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

            this.setState({ progressRekening: Math.ceil(progress) })

        }, error => {

            this.setState({ rekeningUpload: false })

            alert(error.message)

        }, async () => {

            const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()

            this.setState({ rekening: downloadURL, rekeningUpload: false })

        })

    }

    rekeningNumber(e) {

        this.setState({ nomorRekening: e.target.value })
    }

    submit(e) {

        e.preventDefault()

        const { ktp, verification, rekening, nomorRekening } = this.state

        const { uid } = this.props.auth.data

        if (ktp.length < 1 && verification.length < 1 && rekening < 1) {
            iziToast.error({
                title: "Informasi belum lengkap",
                message: "Silahkan lengkapi data sebelum menyerahkan berkas!",
                position: "topRight"
            })

            return
        }

        const data = {
            status: "pending",
            ktpPicture: ktp,
            verificationPicture: verification,
            rekeningPicture: rekening,
            rekeningNumber: nomorRekening
        }

        iziToast.info({
            timeout: 20000,
            close: true,
            overlay: true,
            zindex: 999,
            title: "Halo..",
            icon: "fa fa-question-circle",
            message: "Apakah informasi yang anda masukkan sudah benar?",
            position: "center",
            buttons: [
                [
                    "<button class='button'>Ya, sudah benar!</button>",
                    () => {
                        //hide
                        iziToast.destroy();

                        //confirm
                        createData(uid, data)
                    },
                    true
                ]
            ]
        })


    }


    async componentWillMount() {

        const { uid } = this.props.auth.data

        const snap = await firebase.database().ref("users").child(uid).child("verificationData").once("value")

        if (!snap.exists()) return this.setState({ verificationData: false, isLoaded: true })

        this.setState({ verificationData: snap.val(), isLoaded: true })
    }

    componentDidMount() {

        add(Scripts)
    }


    render() {

        const { picture, uid, name } = this.props.auth.data

        return (
            <div>
                {/* Load Navigation html Syntax */}
                <Navbar picture={picture} name={name} />
                {/*End*/}

                <div id="dashboard">
                    {/* Sidebar*/}
                    <Sidebar uid={uid} />
                    {/* End Sidebar*/}

                    {/*Content*/}
                    <div className="dashboard-content">
                        <div id="titlebar">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2>Verifikasi Akun</h2>
                                    <nav id="breadcrumbs">
                                        <ul>
                                            <li>
                                                <a href="/">Home</a>
                                            </li>
                                            <li>Verifikasi akun</li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div id="add-listing">

                                    {
                                        this.state.verificationData
                                            ? (
                                                <div className="add-listing-section clearfix" hidden={!this.state.isLoaded}>
                                                    <div className="add-listing-headline">
                                                    </div>

                                                    {
                                                        this.state.verificationData.status === "pending"
                                                            ? (
                                                                <div className="notification warning">
                                                                    <p>Data anda sedang dalam proses verifikasi</p>
                                                                </div>
                                                            )
                                                            : (
                                                                <div className="notification success">
                                                                    <p>Akun telah terverfikasi</p>
                                                                </div>
                                                            )
                                                    }

                                                    <div className="row with-forms">
                                                        <div className="col-md-12">
                                                            <h4>
                                                                <b>Foto atau scan KTP</b>
                                                            </h4>
                                                            <br />

                                                            <center>
                                                                <img width="250" src={this.state.verificationData.ktpPicture} alt="" />
                                                            </center>
                                                        </div>
                                                    </div>

                                                    <hr />

                                                    <div className="row with-forms">
                                                        <div className="col-md-12">
                                                            <h4>
                                                                <b>Foto diri anda</b>
                                                            </h4>
                                                            <br />

                                                            <center>
                                                                <img width="250" src={this.state.verificationData.verificationPicture} alt="" />
                                                            </center>
                                                        </div>
                                                    </div>

                                                    <hr />

                                                    <div className="row with-forms">
                                                        <div className="col-md-12">
                                                            <h4>
                                                                <b>Nomor dan foto rekening</b>
                                                            </h4>
                                                            <br />
                                                            <h5>
                                                                No-Rek : <b>{this.state.verificationData.rekeningNumber}</b>
                                                            </h5>
                                                            <br />
                                                            <center>
                                                                <img width="250" src={this.state.verificationData.rekeningPicture} alt="" />
                                                            </center>
                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                            : (
                                                <form onSubmit={this.submit.bind(this)} hidden={!this.state.isLoaded}>
                                                    <div className="add-listing-section clearfix">
                                                        <div className="add-listing-headline">

                                                        </div>

                                                        <div className="row with-forms">
                                                            <div className="col-md-12">
                                                                <h4>
                                                                    <b>Foto atau scan KTP</b>
                                                                </h4>
                                                                <h5 style={{ lineHeight: "30px" }}>
                                                                    Setiap campaigner wajib mengupload foto/scan KTP. Identitas, wajah, dan tulisan tangan pada foto/scan KTP harus jelas terlihat dan tidak terpotong.
                                                                </h5>

                                                                <div>
                                                                    <Dropzone
                                                                        className="dropzone"
                                                                        style={{ cursor: "pointer", height: "100%", overflow: "hidden" }}
                                                                        onDrop={this.ktpPhoto}
                                                                        accept="image/jpeg,image/jpg,image/tiff,image/png,image/gif"
                                                                        multiple={false}
                                                                        disabled={this.state.ktpUpload}
                                                                    >
                                                                        {
                                                                            this.state.ktp.length > 0
                                                                                ? (
                                                                                    <center>
                                                                                        <img width="200" src={this.state.ktp} alt="" />
                                                                                    </center>
                                                                                )
                                                                                : (
                                                                                    <div style={{ cursor: "pointer", textAlign: "center" }}>
                                                                                        <h2><b><i className="fa fa-upload" /></b></h2>
                                                                                        <h4>
                                                                                            {
                                                                                                this.state.ktpUpload
                                                                                                    ? <b>Sedang Mengupload gambar {this.state.progressKtp}%</b>
                                                                                                    : <b>Upload Foto atau Scan KTP</b>
                                                                                            }
                                                                                        </h4>
                                                                                    </div>
                                                                                )
                                                                        }
                                                                    </Dropzone>
                                                                </div>

                                                            </div>

                                                        </div>

                                                        <div className="row with-forms margin-top-30">
                                                            <div className="col-md-12">
                                                                <h4>
                                                                    <b>Foto Verifikasi</b>
                                                                </h4>
                                                                <h5 style={{ lineHeight: "30px" }}>
                                                                    Upload foto verifikasi diri dengan mengikuti langkah berikut :
                                                                </h5>

                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <ol>
                                                                            <li>Foto Potrait Anda</li>
                                                                            <li>
                                                                                Membawa Kertas putih bertuliskan
                                                                                <ul>
                                                                                    <li>Nama lengkap Anda sesuai KTP</li>
                                                                                    <li>Alamat email yang digunakan untuk login di satukebaikan</li>
                                                                                    <li>Ada tulisan "VERIFIED ACCOUNT SATUKEBAIKAN"</li>
                                                                                    <li>Tanda tangan Anda</li>
                                                                                    <li>Tanggal hari ini</li>
                                                                                    <li>Semua persyaratan diatas harus ditulis tangan</li>
                                                                                </ul>
                                                                            </li>
                                                                            <li>Seluruh tulisan</li>
                                                                        </ol>
                                                                    </div>

                                                                    {/* <div className="col-md-6" style={{ textAlign: "center" }}>
                                                                        <h5>Contoh foto yang benar</h5>
                                                                        <img width="200" src="https://sharinghappiness.org/assets/img/example-photo.jpg" alt="" />
                                                                        <br />
                                                                        <br />
                                                                    </div> */}
                                                                </div>

                                                                <div>
                                                                    <Dropzone
                                                                        className="dropzone"
                                                                        style={{ cursor: "pointer", height: "100%", overflow: "hidden" }}
                                                                        accept="image/jpeg,image/jpg,image/tiff,image/png,image/gif"
                                                                        multiple={false}
                                                                        onDrop={this.verificationPhoto}
                                                                        disabled={this.state.verifyUpload}
                                                                    >
                                                                        {
                                                                            this.state.verification.length > 0
                                                                                ? (
                                                                                    <center>
                                                                                        <img width="200" src={this.state.verification} alt="" />
                                                                                    </center>
                                                                                )
                                                                                : (
                                                                                    <div style={{ cursor: "pointer", textAlign: "center" }}>
                                                                                        <h2><b><i className="fa fa-upload" /></b></h2>
                                                                                        <h4>
                                                                                            {
                                                                                                this.state.verifyUpload
                                                                                                    ? <b>Sedang Mengupload gambar {this.state.progressVerify}%</b>
                                                                                                    : <b>Upload Foto Verifikasi</b>
                                                                                            }
                                                                                        </h4>
                                                                                    </div>
                                                                                )
                                                                        }
                                                                    </Dropzone>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div className="row with-forms margin-top-30">
                                                            <div className="col-md-12">
                                                                <h4>
                                                                    <b>Rekening</b>
                                                                </h4>

                                                                <h5 style={{ lineHeight: "30px" }}>
                                                                    Nomor Rekening
                                                                </h5>

                                                                <input
                                                                    style={{ fontFamily: "Open Sans" }}
                                                                    className="search-field"
                                                                    type="text"
                                                                    onChange={this.rekeningNumber.bind(this)}
                                                                    value={this.state.nomorRekening}
                                                                    name="nomorRekening"
                                                                    required
                                                                />

                                                                <h5 style={{ lineHeight: "30px" }}>
                                                                    Foto Scan : NPWP/ Buku Tabungan Halaman Awal / ID Card Perusahaan
                                                                </h5>

                                                                <div>
                                                                    <Dropzone
                                                                        className="dropzone"
                                                                        style={{ cursor: "pointer", height: "100%", overflow: "hidden" }}
                                                                        onDrop={this.rekeningPhoto}
                                                                        accept="image/jpeg,image/jpg,image/tiff,image/png,image/gif"
                                                                        multiple={false}
                                                                        disabled={this.state.rekeningUpload}
                                                                    >
                                                                        {
                                                                            this.state.rekening.length > 0
                                                                                ? (
                                                                                    <center>
                                                                                        <img width="200" src={this.state.rekening} alt="" />
                                                                                    </center>
                                                                                )
                                                                                : (
                                                                                    <div style={{ cursor: "pointer", textAlign: "center" }}>
                                                                                        <h2><b><i className="fa fa-upload" /></b></h2>
                                                                                        <h4>
                                                                                            {
                                                                                                this.state.rekeningUpload
                                                                                                    ? <b>Sedang Mengupload gambar {this.state.progressRekening}%</b>
                                                                                                    : <b>Upload Foto atau Scan Rekening</b>
                                                                                            }
                                                                                        </h4>
                                                                                    </div>
                                                                                )
                                                                        }
                                                                    </Dropzone>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <button
                                                            type="submit"
                                                            className="button preview right"
                                                            style={{ float: "right" }}
                                                        >
                                                            Serahkan
                                                        </button>

                                                    </div>
                                                </form>
                                            )
                                    }

                                    <center hidden={this.state.isLoaded}>
                                        <br />
                                        <br />
                                        <div className="lds-dual-ring" />
                                        <br />
                                        <br />
                                        <br />
                                    </center>

                                    <div className="copyrights">
                                        © 2018 Satukebaikan indonesia. All Rights Reserved.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*End*/}
                </div>
            </div >
        );
    }
}

export default Verification;
