import React, { Component } from "react"
import firebase from "firebase/app"
import "firebase/database"
import Edotensei from "edotensei"
import izitoast from "izitoast"
import "izitoast/dist/css/iziToast.min.css"
import iziToast from "izitoast"

import Navbar from "../includes/admin-components/admin-navigations"
import Sidebar from "../includes/admin-components/admin-sidebar"

const { add } = Edotensei;

const Scripts = [{ src: "/scripts/jquery-2.2.0.min.js", rel: "preload" }, { src: "/scripts/mmenu.min.js", defer: true }, { src: "/scripts/chosen.min.js", defer: true }, { src: "/scripts/slick.min.js", defer: true }, { src: "/scripts/rangeslider.min.js", defer: true }, { src: "/scripts/magnific-popup.min.js", defer: true }, { src: "/scripts/waypoints.min.js", defer: true }, { src: "/scripts/counterup.min.js", defer: true }, { src: "/scripts/tooltips.min.js", defer: true }, { src: "/scripts/custom.js", defer: true }]

/**
 * create
 * @param {object} data
 * @param {function} callback
 */
const createData = (data, callback) => {

  firebase
    .database()
    .ref("/tags/")
    .push(data)

  //alert
  iziToast.success({
    title: "Tambah Kategori",
    message: "berhasil menambahkan kategori",
    position: "topRight"
  })
}

/**
 * read
 * @param {function} callback
 */
const readData = callback => {
  firebase
    .database()
    .ref("/tags/")
    .on("value", snap => {

      const value = snap.exists() ? snap.val() : {};

      callback(value);
    });
};

const updateData = (key, data) => {
  firebase
    .database()
    .ref("/tags")
    .child(key)
    .update(data);

  //alert
  iziToast.success({
    title: "Memperbarui Kategori",
    message: "berhasil memperbarui kategori",
    position: "topRight"
  });
};

/**
 * delete
 * @param {string} key
 */
const deleteData = key => {
  firebase
    .database()
    .ref("/tags")
    .child(key)
    .remove();

  //alert
  iziToast.success({
    title: "Menghapus Kategori",
    message: "berhasil menghapus kategori",
    position: "topRight"
  });
};

class Kategori extends Component {
  constructor() {
    super();

    this.state = {
      judulKategori: "",
      potongan: 0,
      listKategori: {},
      isLoaded: false,
      isEdit: false
    };

    this.inputHandler = this.inputHandler.bind(this);
    this.potonganHandler = this.potonganHandler.bind(this);
  }

  componentDidMount() {

    add(Scripts);
  }

  async componentWillMount() {
    await readData(
      snap =>
        !this.isCancelled &&
        this.setState({ listKategori: snap, isLoaded: true })
    );
  }

  componentWillUnmount() {

    this.isCancelled = true;
  }

  inputHandler(e) {

    this.setState({
      judulKategori: e.target.value
    });
  }

  potonganHandler(e) {
    var result = parseInt(e.target.value, 0);

    if (isNaN(result)) {
      this.setState({ potongan: 0 });
      return;
    }

    if (result > 5) {
      izitoast.error({
        message: "potongan minimal 0 dan maximal 5",
        position: "topRight"
      });
      return;
    }

    this.setState({
      potongan: result
    });
  }

  confrim() {
    let data = {
      title: this.state.judulKategori,
      piece: this.state.potongan
    };

    createData(data);

    this.setState({ judulKategori: "", potongan: 0 });
  }

  updateConfirm(key) {
    let data = {
      title: this.state.judulKategori,
      piece: this.state.potongan
    };

    updateData(key, data);
  }

  submitHandler(e) {
    e.preventDefault();

    document.querySelector(".mfp-close").click();

    izitoast.info({
      timeout: 20000,
      close: true,
      overlay: true,
      zindex: 999,
      title: "Halo..",
      icon: "fa fa-question-circle",
      message: this.state.isEdit
        ? "Apakah anda ingin memperbarui kategori ini?"
        : "Apakah anda ingin menambahkan kategori ini?",
      position: "center",
      buttons: [
        [
          "<button class='button'>Ya!</button>",
          () => {
            //hide
            izitoast.destroy();
            //confirm
            this.state.isEdit
              ? this.updateConfirm(this.state.isEdit)
              : this.confrim(this);
          },
          true
        ]
      ]
    });
  }

  handleEdit(key, value, e) {
    e.preventDefault();

    document.getElementById("trigger-small-dialog").click();

    this.setState({
      isEdit: key,
      judulKategori: value.title,
      potongan: value.piece
    });
  }

  handleDelete(key, e) {
    e.preventDefault();

    izitoast.info({
      timeout: 20000,
      close: true,
      overlay: true,
      zindex: 999,
      title: "Halo..",
      icon: "fa fa-question-circle",
      message: "Apakah anda ingin menghapus kategori ini?",
      position: "center",
      buttons: [
        [
          "<button class='button'>Ya, hapus sekarang!</button>",
          () => {
            //hide
            izitoast.destroy();
            //delete data
            deleteData(key);
          },
          true
        ]
      ]
    });
  }

  render() {

    const { picture, uid, name } = this.props.auth.data

    if (uid !== process.env.REACT_APP_SUPER_ADMIN_UID) return (window.location.href = "/dashboard/login")

    return (
      <div>
        {/* Load Navigation html Syntax */}
        <Navbar picture={picture} name={name} />
        {/*End*/}

        {/* modal add and edit */}
        <div id="small-dialog" className="zoom-anim-dialog mfp-hide" style={{ margin: "40px auto" }}>
          <div className="small-dialog-header">
            <h3>Tambah Kategori</h3>
          </div>
          <div className="message-reply margin-top-0 clearfix">
            <form onSubmit={this.submitHandler.bind(this)}>
              <div className="row with-forms">
                <div className="col-md-12">
                  <h5>Judul Kategori</h5>
                  <input
                    className="search-field"
                    type="text"
                    onChange={this.inputHandler}
                    value={this.state.judulKategori}
                    required
                  />
                </div>

                <div className="col-md-12">
                  <h5>Potongan</h5>
                  <input
                    className="search-field"
                    type="text"
                    onChange={this.potonganHandler}
                    value={this.state.potongan}
                    required
                  />
                </div>
              </div>

              <button
                style={{ float: "right" }}
                type="submit"
                className="button"
              >
                {this.state.isEdit ? "Perbarui" : "Tambahkan"}
              </button>
            </form>
          </div>
        </div>
        {/*End*/}

        <div style={{ overflow: "auto" }} id="dashboard">
          {/* Sidebar*/}
          <Sidebar uid={uid} />
          {/* End Sidebar*/}

          {/*Content*/}
          <div className="dashboard-content">
            <div id="titlebar">
              <div className="row">
                <div className="col-md-12 col-s-12">
                  <h2>Setting Kategori</h2>
                  <nav id="breadcrumbs">
                    <ul>
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li>Setting Kategori</li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>

            <div className="row">
              <div
                hidden={!this.state.isLoaded}
                className="col-lg-12 col-md-12 col-s-12"
              >
                <div className="dashboard-list-box margin-top-0">
                  <div className="sort-by ">
                    <div className="sort-by-select ">
                      <a
                        id="trigger-small-dialog"
                        href="#small-dialog"
                        className="button popup-with-zoom-anim border"
                        onClick={() => {
                          this.setState({
                            isEdit: false,
                            judulKategori: "",
                            potongan: 0
                          });
                        }}
                      >
                        Tambah Kategori
                      </a>
                    </div>
                  </div>

                  <h4>
                    Kategori{" "}
                    <span style={{ float: "right", marginRight: "290px" }}>
                      Potongan
                    </span>
                  </h4>

                  <ul>
                    {Object.keys(this.state.listKategori)
                      .reverse()
                      .map((item, i) => (
                        <li className="clearfix" key={i}>
                          <div className="list-box-listing">
                            <div
                              style={{ paddingLeft: "0" }}
                              className="list-box-listing-content"
                            >
                              <div
                                style={{ position: "relative" }}
                                className="inner"
                              >
                                <p style={{ float: "left" }}>
                                  {this.state.listKategori[item].title}
                                </p>
                                <span
                                  style={{
                                    marginRight: "480px",
                                    float: "right"
                                  }}
                                >
                                  {this.state.listKategori[item].piece}%
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="buttons-to-right">
                            <a
                              href="#!"
                              className="button grey"
                              onClick={this.handleEdit.bind(this, item, this.state.listKategori[item])}
                            >
                              <i className="sl sl-icon-note" /> Edit
                            </a>
                            <a
                              href="#!"
                              onClick={this.handleDelete.bind(this, item)}
                              className="button gray"
                            >
                              <i className="sl sl-icon-close" /> Delete
                            </a>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>

              <center hidden={this.state.isLoaded}>
                <br />
                <br />
                <div className="lds-dual-ring" />
                <br />
                <br />
                <br />
              </center>

              <div className="col-md-12">
                <div className="copyrights">
                  © 2018 Satukebaikan indonesia. All Rights Reserved.
                </div>
              </div>
            </div>
          </div>
          {/*End*/}
        </div>
      </div>
    );
  }
}

export default Kategori;
