import firebase from "firebase/app"
import "firebase/messaging"
import "firebase/functions"

export const askPermission = async () => {

    const messaging = firebase.messaging()

    try {

        await messaging.requestPermission()

        const token = await messaging.getToken()

        const registrationTokens = firebase.functions().httpsCallable("registrationTokens")

        await registrationTokens({ token })

    } catch (error) {

        console.error(error)
    }

    messaging.onTokenRefresh(async () => {

        try {

            await messaging.requestPermission()

            const refreshedToken = await messaging.getToken()

            const registrationTokens = firebase.functions().httpsCallable("registrationTokens")

            const result = await registrationTokens({ token: refreshedToken })

            console.log(result.data.message)

        } catch (error) {

            console.error(error)
        }
    })

}