import React, { useEffect, useState } from "react"
import edotensei from "edotensei"
import iziToast from "izitoast"
import firebase from "firebase/app"
import "firebase/database"

import Navigation from "../includes/non-admin-components/navigation"
import Footer from "../includes/non-admin-components/footer"
import scripts from "../includes/external-scripts"

import "../admin-panel/css/datedropper.css"

const { add } = edotensei

var componentDidmount = false

const createData = (key, data) => {

    firebase
        .database()
        .ref("/campaign/" + key)
        .set(data, () => {

            iziToast.show({
                class: "izimodal",
                timeout: 3000,
                close: true,
                overlay: true,
                progressBar: false,
                title: "Selamat! kampanyemu sudah live dan siap menerima donasi",
                message: "ajak teman dan keluarga untuk berdonasi dengan membagikan link dibawah ini",
                messageLineHeight: "40px",
                position: "center",
                backgroundColor: "#25d366",
                messageColor: "#fff",
                titleColor: "#fff",
                iconColor: "#fff",
                layout: 1,
                balloon: true,
                icon: "fa fa-check",
                transitionIn: "bounceInLeft",
                onClosing: () => {

                    window.location.href = "/" + key
                }
            })
        })
}

export default props => {

    const [loaded, setLoaded] = useState(false)

    const [campaign, setCampaign] = useState({})

    const [values, setValues] = useState({
        title: "",
        target: 0,
        deadline: "",
        link: ""
    })

    const [validLink, setValidLink] = useState(false)

    const [checkLink, setCheckLink] = useState(false)

    const checkLinkHandler = async e => {

        let { value } = e.target

        value = value.replace(/ |\.|#|\$|\[|\]/gi, "");

        setValues({ ...values, link: value })

        if (value.length <= 0) return setValidLink(false)

        setCheckLink(true)

        const snap = await firebase.database().ref("campaign").child(value).once("value")

        setValidLink(!snap.exists())

        setCheckLink(false)

        iziToast.show({
            message: snap.exists() ? "Mohon maaf link kampanye yang anda masukkan telah digunakan, silahkan coba yang lain!" : "Yeah, link kampanye tersedia.",
            position: "topRight",
            icon: snap.exists() ? "fa fa-frown-o" : "fa  fa-smile-o",
            backgroundColor: snap.exists() ? "#f96161" : "#25d366",
            messageColor: "#fff",
            iconColor: "#fff",
            balloon: true,
            transitionIn: "bounceInLeft",
            displayMode: 1,
            layout: 1,
            maxWidth: '500px',
            progressBar: false
        })

    }

    const formateCurrancy = async e => {

        let { value } = e.target

        value = value.replace(new RegExp(",", "g"), "")
        value = value.replace(/\./g, "")

        value = parseInt(value, 0);

        if (isNaN(value)) return setValues({ ...values, target: 0 })

        const valueToLocale = value.toLocaleString();

        setValues({ ...values, target: valueToLocale.toString() })
    }

    const submitFundraiser = async e => {

        try {

            e.preventDefault()

            if (!validLink) return iziToast.show({
                message: "Mohon maaf link kampanye yang anda masukkan telah digunakan, silahkan coba yang lain!",
                position: "topRight",
                icon: "fa fa-frown-o",
                backgroundColor: "#f96161",
                messageColor: "#fff",
                iconColor: "#fff",
                balloon: true,
                transitionIn: "bounceInLeft",
                displayMode: 1,
                layout: 1,
                maxWidth: '500px',
                progressBar: false
            })

            const target = parseInt(values.target.replace(/,|\./gi, ""), 0)

            if (target < 1000000 || isNaN(target)) return iziToast.show({
                message: "Target donasi minimal Rp. 1.000.000",
                position: "topRight",
                icon: "fa fa-frown-o",
                backgroundColor: "#f96161",
                messageColor: "#fff",
                iconColor: "#fff",
                balloon: true,
                transitionIn: "bounceInLeft",
                displayMode: 2,
                layout: 1,
                maxWidth: '500px',
                progressBar: false
            })

            const { title, link } = values

            const { uid, name, picture, email } = props.auth.data

            const { id } = props.match.params

            // setFundraiser({ ...fundraiser, uid, target, title, ownerName: name, ownerPhoto: picture, owner: email, isFundraiser: true, contribute: {}, parentCampaign: id })

            const data = {
                isFundraiser: true,
                parentCampaign: id,
                title: title,
                target: target,
                piece: campaign.piece,
                category: campaign.category,
                deadline: campaign.deadline,
                location: campaign.location,
                image: campaign.image,
                shortDescription: campaign.shortDescription,
                description: campaign.description,
                date: firebase.database.ServerValue.TIMESTAMP,
                owner: email,
                ownerName: name,
                ownerPhoto: picture,
                uid: uid
            };

            iziToast.info({
                timeout: 20000,
                close: true,
                overlay: true,
                zindex: 999,
                title: `Hai ${name}`,
                icon: "fa fa-question-circle",
                message: "Apakah informasi yang anda masukkan sudah benar?",
                position: "center",
                backgroundColor: "rgb(35, 203, 245)",
                titleColor: "#fff",
                color: "#fff",
                iconColor: "#fff",
                messageColor: "#fff",
                layout: 1,
                balloon: true,
                transitionIn: "bounceInLeft",
                progressBar: false,
                buttons: [
                    [
                        "<button class='button' style='background: #fff; color :rgb(35, 203, 245), border-color: #fff '>Ya, sudah benar!</button>",
                        () => {
                            //hide
                            iziToast.destroy()

                            createData(link, data)
                        },
                        true
                    ]
                ]
            });

        } catch (error) {

            iziToast.show({
                message: "Target donasi minimal Rp. 1.000.000",
                position: "topRight",
                icon: "fa fa-frown-o",
                backgroundColor: "#f96161",
                messageColor: "#fff",
                iconColor: "#fff",
                balloon: true,
                transitionIn: "bounceInLeft",
                displayMode: 2,
                layout: 1,
                maxWidth: '500px',
                progressBar: false
            })
        }
    }

    useEffect(() => {

        (async () => {

            if (!componentDidmount) {

                try {

                    const { id } = props.match.params

                    const snap = await firebase.database().ref("campaign").child(id).once("value")

                    add(scripts())

                    setCampaign(snap.val())

                    setLoaded(true)

                } catch (error) {

                    add(scripts())
                }

                componentDidmount = true
            }
        })()
    })

    const { name, picture } = props.auth.data || { name: undefined, picture: undefined }

    const { id } = props.match.params

    return (

        !loaded
            ? (
                <center className="vertical-center">
                    <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <br />
                    <br />
                    <img style={{ width: "200px" }} src="/images/logo-a.png" alt="satukebaikan" />
                </center>
            )
            : (
                < div >

                    <Navigation auth={{ name, picture }} />

                    <div id="background-summary" className="container" style={{ maxWidth: "100%", background: "#fff" }}>
                        <br />
                        <div className="row">
                            <div className="col-md-12">
                                <div id="invoice" style={{ paddingTop: "0" }}>

                                    <center>
                                        <h2 style={{ fontFamily: "Open Sans" }}><b>Jadi penggalang dana</b></h2>
                                        <p>
                                            Anda akan menjadi penggalang dana dari kampanye <a href={`/${id}`} ><b>{campaign.title}</b></a>{" "}
                                            Semua donasi yang anda kumpulkan akan disalurkan ke <b>{campaign.ownerName}</b>
                                        </p>
                                    </center>
                                    <br />

                                    <form onSubmit={submitFundraiser}>

                                        <div className="row with-forms">
                                            <div className="col-md-8 col-sm-offset-2">
                                                <h5>
                                                    Judul Kampanye{" "}
                                                    <i className="tip" data-tip-content="Judul Kampanye yang ingin dibuat" />
                                                </h5>
                                                <input
                                                    style={{ fontFamily: "Open Sans" }}
                                                    className="search-field"
                                                    type="text"
                                                    name="title"
                                                    onChange={e => setValues({ ...values, title: e.target.value })}
                                                    value={values.title}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="row with-forms">
                                            <div className="col-md-8 col-sm-offset-2">
                                                <div className="input-with-icon medium-icons">
                                                    <h5>
                                                        Target donasi
                                                        <span className="margin-top-5" style={{ fontSize: "12px" }}>
                                                            {" "}(Minimal Rp. 1.000.000)
                                                        </span>
                                                    </h5>
                                                    <input
                                                        style={{ fontSize: "24px", fontFamily: "Open Sans" }}
                                                        className="search-field"
                                                        type="text"
                                                        onChange={formateCurrancy}
                                                        value={values.target}
                                                        name="donationTarget"
                                                        required
                                                    />
                                                    <i style={{ fontStyle: "normal" }}>
                                                        <b>Rp</b>
                                                    </i>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row with-forms">
                                            <div className="col-md-8 col-sm-offset-2">
                                                <h5>Link Kampanye</h5>

                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        padding: "13px 10px",
                                                        background: "#eee"
                                                    }}
                                                >
                                                    <b>Satukebaikan.com/</b>
                                                </div>
                                                <input
                                                    style={{ paddingLeft: "170px", marginBottom: "0", fontWeight: "bold" }}
                                                    placeholder=""
                                                    autoComplete="false"
                                                    className="search-field"
                                                    type="text"
                                                    name="link"
                                                    onChange={checkLinkHandler}
                                                    value={values.link}
                                                    required
                                                />

                                                <span className="margin-top-5" style={{ fontSize: "12px" }}>
                                                    Link harus dimulai dengan huruf, tanpa spasi.
                                                </span>
                                            </div>
                                        </div>

                                        <div className="row with-forms">
                                            <div className="col-md-8 col-sm-offset-2">
                                                <br />
                                                <button disabled={checkLink} style={{ borderRadius: "5px", color: !checkLink ? "#23cbf5" : "#ccc", border: !checkLink ? "1px solid #23cbf5" : "1px solid #ccc", background: "#fff" }} className="button fullwidth">
                                                    Jadi Penggalang Dana
                                        </button>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />

                </div >
            )
    )

}
