import React, { Component } from "react"
import firebase from "firebase/app"
import "firebase/functions"
import LazyLoad from "react-lazyload"
import Edotensei from "edotensei"

import Placeholder from "../../includes/non-admin-components/placeholder"

const { add } = Edotensei

const Scripts = [{ src: "/scripts/jquery-2.2.0.min.js", rel: "preload" }, { src: "/scripts/mmenu.min.js", defer: true }, { src: "/scripts/chosen.min.js", defer: true }, { src: "/scripts/slick.min.js", defer: true }, { src: "/scripts/rangeslider.min.js", defer: true }, { src: "/scripts/magnific-popup.min.js", defer: true }, { src: "/scripts/waypoints.min.js", defer: true }, { src: "/scripts/counterup.min.js", defer: true }, { src: "/scripts/tooltips.min.js", defer: true }, { src: "/scripts/custom.js", defer: true }]

const totalDonasi = arr => {

  let value = 0

  arr = arr.map(i => i.status.toLowerCase() === "approved" ? i : null)

  for (let i in arr) {

    if (arr[i]) {

      value += (arr[i].donation + arr[i].uniqueCode)

    }
  }

  return value
}

const randomArray = arr => {

  if (arr.length < 1) return []

  let ctr = arr.length
  let temp
  let index

  // While there are elements in the array
  while (ctr > 0) {
    // Pick a random index
    index = Math.floor(Math.random() * ctr)
    // Decrease ctr by 1
    ctr--
    // And swap the last element with it
    temp = arr[ctr]

    arr[ctr] = arr[index]

    arr[index] = temp
  }

  return arr;
}

class Item extends Component {

  constructor() {
    super()

    this.state = {
      campaign: {},
      isLoaded: false
    }
  }

  async componentWillMount() {

    const getAllCampaign = firebase.functions().httpsCallable("getAllCampaign")

    const value = await getAllCampaign()

    this.setState({ campaign: value.data, isLoaded: true })

    add(Scripts)
  }

  render() {

    const { campaign } = this.state

    return (
      <div className="container margin-top-10">
        <div className="row">
          <div id="headline" className="col-md-8 col-md-offset-2">
            <h2 className="headline centered" style={{ fontFamily: "Open Sans" }} >
              <b>Sedekah Pasti Berkah</b>
              <span style={{ fontSize: "18px", lineHeight: "25px" }}>
                Mari ikut berperan dengan menyalurkan kepedulian anda pada
                sesama Kepedulian anda adalah secercah kebahagiaan bagi mereka.
              </span>
            </h2>
          </div>

          <div className="col-md-12">
            <div className="dots-nav">
              {
                randomArray(Object.keys(campaign)).slice(0, 6).map((item, i) => (

                  <div key={i} className="carousel-item col-md-4" style={{ paddingLeft: "0" }}>

                    <a
                      href={"/" + item}
                      className="listing-item-container"
                      style={{
                        boxShadow: "0 0 5px 1px #ececec",
                        background: "white"
                      }}
                    >
                      {/**listing item */}
                      <div style={{ height: "230px" }} className="listing-item">
                        <LazyLoad placeholder={<Placeholder />}>
                          <img src={campaign[item].image} alt={campaign[item].title} />
                        </LazyLoad>
                      </div>

                      <div className="star-rating" style={{ padding: "0 16px" }} >
                        <div className="row" style={{ minHeight: "224.32px", position: "relative" }}>
                          <div className="col-md-12">
                            <h3 style={{ fontSize: "18px", lineHeight: "25px" }} >
                              {campaign[item].title}
                            </h3>
                            <br />
                          </div>

                          {/** card footer */}
                          <div style={{ marginBottom: "10px", position: "absolute", bottom: "0", width: "100%" }} className="col-md-12 clearfix">
                            <div className="clearfix">
                              <div style={{ width: "40px", height: "40px", borderRadius: "50%", backgroundImage: "url( " + campaign[item].ownerPhoto + " )", backgroundSize: "100% 100%", float: "left" }} className="tag" />
                              <span style={{ fontSize: "18px", marginLeft: "10px", color: "#ff5100", lineHeight: "20px", float: "left" }}>
                                <i style={{ display: "block" }} className="fa fa-check-circle" />
                                <span style={{ fontSize: "12px", color: "black" }}>
                                  {" " + campaign[item].ownerName}
                                </span>
                              </span>
                              <div />
                            </div>
                            <hr style={{ marginBottom: "10px" }} />
                            <div style={{ float: "left", lineHeight: "1.5", fontFamily: "Open Sans", fontSize: "14px", fontStyle: "normal", fontVariant: "normal", fontWeight: "0" }} className="left">
                              <span style={{ fontSize: "12px", display: "block" }} >
                                Terkumpul
                              </span>
                              Rp.{" "}
                              {
                                campaign[item].contribute
                                  ? (totalDonasi(Object.values(campaign[item].contribute)) + campaign[item].donationFromFundraiser).toLocaleString()
                                  : 0
                              }
                            </div>
                            <div style={{ float: "right", textAlign: "right", fontSize: "14px", fontFamily: "Open Sans", fontStyle: "none", lineHeight: "1.5" }} className="right">
                              <span style={{ fontSize: "12px", display: "block" }} >
                                Sisa hari
                              </span>
                              {Date.parse(new Date(campaign[item].deadline)) < Date.parse(new Date())
                                ? "Selesai"
                                : Math.floor((Date.parse(campaign[item].deadline) - Date.parse(new Date())) / (1000 * 60 * 60 * 24) + 1)}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="progress-bar"
                        style={{ overflow: "hidden" }}
                      >
                        <span
                          className="progress-bar-fill"
                          style={{
                            backgroundColor: "#ff5100",
                            width: Math.ceil((totalDonasi(Object.values(campaign[item].contribute || [])) + campaign[item].donationFromFundraiser) / parseInt(campaign[item].target, 0) * 100) + "%",
                          }}
                        />
                      </div>
                    </a>
                  </div>
                ))
              }
            </div>
            <div className="col-md-12 centered-content" hidden={Object.keys(campaign).length <= 0 ? true : false} >
              <center>
                <br />
                <a href="/explorer/all" className="button border" style={{ borderRadius: "5px", margin: "0" }}>Lihat semua</a>
                <br />
                <br />
              </center>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Item;
