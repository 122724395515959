import React, { useEffect, useState } from "react"
import edotensei from "edotensei"
// import axios from "axios"

import Navigation from "../includes/non-admin-components/navigation"
import Footer from "../includes/non-admin-components/footer"
import scripts from "../includes/external-scripts"
import team from "./our-team-components/team-data"
import advisor from "./our-team-components/advisor-data"

const { add } = edotensei

var componentDidmount = false

export default props => {

    const [values, setValues] = useState({ team: [], advisor: [] })

    useEffect(() => {

        (async () => {

            if (!componentDidmount) {

                try {

                    setValues({ ...values, team: team(), advisor: advisor() })

                    add(scripts())

                } catch (error) {

                    // add(scripts())
                }

                componentDidmount = true
            }
        })()
    })

    const { name, picture } = props.auth.data || { name: undefined, picture: undefined }

    return (

        <div style={{ background: "#23cbf5" }}>

            <Navigation auth={{ name, picture }} />

            <div id="titlebar" style={{ marginBottom: "0", background: "#23cbf5" }} className="gradient">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <center>
                                <h2 style={{ color: "#fff" }}><b>Tim Kebaikan</b></h2>
                                {/* <p style={{ color: "#fff" }}>Langkah mudah Berdonasi di Satukebaikan</p> */}
                            </center>
                        </div>
                    </div>
                </div>
            </div>

            <div id="background-summary" className="container" style={{ background: "#23cbf5" }}>
                <br />
                <div className="row">
                    <div style={{ maxWidth: "100%", paddingTop: "30px", paddingBottom: "30px", textAlign: "center" }}>

                        {
                            values.team.map((i, x) => (
                                <div key={x} className="col-md-4 col-xs-6">
                                    <br />
                                    <center>
                                        <div
                                            style={{
                                                width: "150px",
                                                height: "150px",
                                                borderRadius: "50%",
                                                objectFit: "cover",
                                                position: "relative"
                                            }}
                                        >
                                            <img
                                                style={{ borderRadius: "50%" }}
                                                src={i.img}
                                                alt=""
                                            />
                                        </div>
                                        <br />
                                        <p style={{ color: "#fff", fontSize: "1.1em", marginBottom: "3px" }}>
                                            <b>{i.nama}</b>
                                        </p>
                                        <p style={{ color: "#fff", fontSize: "1.1em" }}>
                                            {i.jabatan}
                                        </p>
                                        {/* <hr /> */}
                                    </center>
                                </div>
                            ))
                        }

                    </div>
                </div>
            </div>

            <div id="titlebar" style={{ marginBottom: "0", background: "#23cbf5" }} className="gradient">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <center>
                                <h2 style={{ color: "#fff" }}><b>Penasihat Kebaikan</b></h2>
                                {/* <p style={{ color: "#fff" }}>Langkah mudah Berdonasi di Satukebaikan</p> */}
                            </center>
                        </div>
                    </div>
                </div>
            </div>

            <div id="background-summary" className="container" style={{ background: "#23cbf5" }}>
                <br />
                <div className="row">
                    <div style={{ maxWidth: "100%", paddingTop: "30px", paddingBottom: "30px", textAlign: "center" }}>

                        {
                            values.advisor.map((i, x) => (
                                <div key={x} className="col-md-4 col-xs-6">
                                    <br />
                                    <center>
                                        <div
                                            style={{
                                                width: "150px",
                                                height: "150px",
                                                borderRadius: "50%",
                                                objectFit: "cover",
                                                position: "relative"
                                            }}
                                        >
                                            <img
                                                style={{ borderRadius: "50%" }}
                                                src={i.img}
                                                alt=""
                                            />
                                        </div>
                                        <br />
                                        <p style={{ color: "#fff", fontSize: "1.1em", marginBottom: "3px" }}>
                                            <b>{i.nama}</b>
                                        </p>
                                        <p style={{ color: "#fff", fontSize: "1.1em" }}>
                                            {i.jabatan}
                                        </p>
                                        {/* <hr /> */}
                                    </center>
                                </div>
                            ))
                        }

                    </div>
                </div>
            </div>

            <br />
            <br />
            <Footer />

        </div>
    )

}
