import React, { useEffect, useState } from "react"
import edotensei from "edotensei"
import reactHtmlParser from "react-html-parser"
// import axios from "axios"

import Navigation from "../includes/non-admin-components/navigation"
import Footer from "../includes/non-admin-components/footer"
import scripts from "../includes/external-scripts"
import faqData from "./faq-components/faq-data"

const { add } = edotensei

var componentDidmount = false

export default props => {

    const [values, setValues] = useState([])

    useEffect(() => {

        (async () => {

            if (!componentDidmount) {

                try {

                    setValues(faqData())

                    add(scripts())

                    // const result = await axios({
                    //     url: "http://api.kulimakan.com/satu_kebaikan/public/admin/faq/",
                    //     method: "GET",
                    //     headers: { Authorization: "bearer QzRsYUdXaE5hdGxYN1ZBYjJISnRpNE54eHpCODE3OWZnQWFidTFpWA==" }
                    // })

                } catch (error) {

                    // add(scripts())
                }

                componentDidmount = true
            }
        })()
    })

    const { name, picture } = props.auth.data || { name: undefined, picture: undefined }

    return (

        <div>

            <Navigation auth={{ name, picture }} />

            <div id="titlebar" style={{ marginBottom: "0", background: "#23cbf5", height: "400px" }} className="gradient">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <center>
                                <br/>
                                <h2 style={{ color: "#fff" }}><b>Frequently Asked Question</b></h2>
                                <br />
                                <br />
                                <h3>
                                    <p style={{ color: "#fff", fontSize: "1.2em" }}>cari tau lebih lengkap tentang satukebaikan disini</p>
                                </h3>
                            </center>
                        </div>
                    </div>
                </div>
            </div>


            <div id="background-summary" className="container" style={{ background: "#fff" }}>
                <br />
                <div className="row">
                    <div className="col-md-12">
                        <div id="invoice" style={{ maxWidth: "100%", paddingTop: "30px" }}>

                            {
                                values.map((item, i) => (
                                    <div className="toggle-wrap" key={i}>

                                        <span className="trigger ">
                                            <a href="#!" style={{ paddingRight: "70px" }}>
                                                {item.question}
                                                <i style={{ marginRight: "25px", color: "#fff" }} className="sl sl-icon-plus"></i>
                                            </a>
                                        </span>
                                        <div className="toggle-container">
                                            <p>
                                                {reactHtmlParser(item.answer)}
                                            </p>
                                        </div>

                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

        </div>
    )

}
