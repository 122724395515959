import React, { Component } from "react"
import firebase from "firebase/app"
import "firebase/database"
import "firebase/functions"
import Edotensei from "edotensei"
import iziToast from "izitoast"

import Navbar from "../includes/admin-components/admin-navigations"
import Sidebar from "../includes/admin-components/admin-sidebar"

const { add } = Edotensei;

const Scripts = [{ src: "/scripts/jquery-2.2.0.min.js", rel: "preload" }, { src: "/scripts/mmenu.min.js", defer: true }, { src: "/scripts/chosen.min.js", defer: true }, { src: "/scripts/slick.min.js", defer: true }, { src: "/scripts/rangeslider.min.js", defer: true }, { src: "/scripts/magnific-popup.min.js", defer: true }, { src: "/scripts/waypoints.min.js", defer: true }, { src: "/scripts/counterup.min.js", defer: true }, { src: "/scripts/tooltips.min.js", defer: true }, { src: "/scripts/custom.js", defer: true }]


class userDetail extends Component {

    constructor(props) {

        super(props)

        this.state = {
            isLoaded: false,

            verificationData: {}
        }

    }


    async componentWillMount() {

        const { uid } = this.props.match.params

        const snap = await firebase.database().ref("users").child(uid).child("verificationData").once("value")

        if (!snap.exists()) return this.setState({ verificationData: false, isLoaded: true })

        this.setState({ verificationData: snap.val(), isLoaded: true })
    }

    componentDidMount() {

        add(Scripts)
    }

    async verify() {

        const { uid } = this.props.match.params

        const confrimVerify = async uid => {

            try {

                const verifyUser = firebase.functions().httpsCallable("verifyUser")

                const response = await verifyUser({ uid })

                iziToast.info({
                    message: response.data.message,
                    position: "center",
                    close: false,
                    overlay: true,
                    drag: false,
                    timeout: "2000",
                    onClosing: () => {

                        window.location.href = "/dashboard/setting/pengguna"
                    }
                })

            } catch (error) {

                alert(error)
            }
        }

        iziToast.info({
            timeout: 20000,
            close: true,
            overlay: true,
            zindex: 999,
            title: "Halo..",
            icon: "fa fa-question-circle",
            message: "Verfikasi user ini?",
            position: "center",
            buttons: [
                [
                    "<button class='button'>Ya, verfikasi!</button>",
                    () => {
                        //hide
                        iziToast.destroy();

                        confrimVerify(uid)
                    },
                    true
                ]
            ]
        })
    }

    async unVerify() {

        const { uid } = this.props.match.params

        const confrimVerify = async uid => {

            try {

                const unVerifyUser = firebase.functions().httpsCallable("unVerifyUser")

                const response = await unVerifyUser({ uid })

                iziToast.info({
                    message: response.data.message,
                    position: "center",
                    close: false,
                    overlay: true,
                    drag: false,
                    timeout: "2000",
                    onClosing: () => {

                        window.location.href = "/dashboard/pengaturan/pengguna"
                    }
                })

            } catch (error) {

                alert(error)
            }
        }

        iziToast.info({
            timeout: 20000,
            close: true,
            overlay: true,
            zindex: 999,
            title: "Halo..",
            icon: "fa fa-question-circle",
            message: "Cabut verfikasi user ini?",
            position: "center",
            buttons: [
                [
                    "<button class='button'>Ya, cabut verfikasi!</button>",
                    () => {
                        //hide
                        iziToast.destroy();

                        confrimVerify(uid)
                    },
                    true
                ]
            ]
        })
    }

    render() {

        const { picture, uid, name } = this.props.auth.data

        if (uid !== process.env.REACT_APP_SUPER_ADMIN_UID) return (window.location.href = "/dashboard/login")

        return (
            <div>
                {/* Load Navigation html Syntax */}
                <Navbar picture={picture} name={name} />
                {/*End*/}

                <div id="dashboard">
                    {/* Sidebar*/}
                    <Sidebar uid={uid} />
                    {/* End Sidebar*/}

                    {/*Content*/}
                    <div className="dashboard-content">
                        <div id="titlebar">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2>Detail Akun</h2>
                                    <nav id="breadcrumbs">
                                        <ul>
                                            <li>
                                                <a href="/">Home</a>
                                            </li>
                                            <li>
                                                <a href="/dashboard/pengaturan/pengguna">Pengaturan Pengguna</a>
                                            </li>
                                            <li>Detail Akun</li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div id="add-listing">
                                    {
                                        this.state.verificationData
                                            ? (
                                                <div className="add-listing-section clearfix" hidden={!this.state.isLoaded}>
                                                    <div className="add-listing-headline clearfix">
                                                        {
                                                            this.state.verificationData.status === "pending"
                                                                ? (
                                                                    <div style={{ float: "right" }}>
                                                                        <button style={{ borderRadius: "5px", background: "rgb(255, 81, 0)" }} onClick={this.unVerify.bind(this)} className="button">
                                                                            Tolak
                                                                        </button>
                                                                        <button style={{ borderRadius: "5px" }} onClick={this.verify.bind(this)} className="button">
                                                                            Verifikasi akun
                                                                        </button>
                                                                    </div>
                                                                ) : (
                                                                    <div style={{ float: "right" }}>
                                                                        <button style={{ borderRadius: "5px" }} onClick={this.unVerify.bind(this)} className="button">Cabut verifikasi akun</button>
                                                                    </div>
                                                                )
                                                        }
                                                    </div>

                                                    {
                                                        this.state.verificationData.status === "pending"
                                                            ? (
                                                                <div className="notification warning">
                                                                    <p>Sedang dalam proses verifikasi</p>
                                                                </div>
                                                            )
                                                            : (
                                                                <div className="notification success">
                                                                    <p>Akun telah terverfikasi</p>
                                                                </div>
                                                            )
                                                    }

                                                    <div className="row with-forms">
                                                        <div className="col-md-12">
                                                            <h4>
                                                                <b>Foto atau scan KTP</b>
                                                            </h4>
                                                            <br />

                                                            <center>
                                                                <img width="250" src={this.state.verificationData.ktpPicture} alt="" />
                                                            </center>
                                                        </div>
                                                    </div>

                                                    <hr />

                                                    <div className="row with-forms">
                                                        <div className="col-md-12">
                                                            <h4>
                                                                <b>Foto diri anda</b>
                                                            </h4>
                                                            <br />

                                                            <center>
                                                                <img width="250" src={this.state.verificationData.verificationPicture} alt="" />
                                                            </center>
                                                        </div>
                                                    </div>

                                                    <hr />

                                                    <div className="row with-forms">
                                                        <div className="col-md-12">
                                                            <h4>
                                                                <b>Nomor dan foto rekening</b>
                                                            </h4>
                                                            <br />
                                                            <h5>
                                                                No-Rek : <b>{this.state.verificationData.rekeningNumber}</b>
                                                            </h5>
                                                            <br />
                                                            <center>
                                                                <img width="250" src={this.state.verificationData.rekeningPicture} alt="" />
                                                            </center>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            : (
                                                <div className="add-listing-section clearfix" hidden={!this.state.isLoaded}>
                                                    <br />
                                                    <center>
                                                        <img width="250" src="https://sharinghappiness.org/files/email-notverified.png" alt="" />

                                                        <h5>
                                                            <b>Berkas belum dikirim</b>
                                                        </h5>

                                                        <a className="button" href="/dashboard/pengaturan/pengguna/">Kembali</a>
                                                    </center>
                                                </div>
                                            )
                                    }


                                    <center hidden={this.state.isLoaded}>
                                        <br />
                                        <br />
                                        <div className="lds-dual-ring" />
                                        <br />
                                        <br />
                                        <br />
                                    </center>

                                    <div className="copyrights">
                                        © 2018 Satukebaikan indonesia. All Rights Reserved.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*End*/}
                </div>
            </div >
        );
    }
}

export default userDetail;
