import React, { Component } from "react"
import ReactGA from 'react-ga'
import firebase from "firebase/app"
import "firebase/database"

import Navigation from "../includes/non-admin-components/navigation"
import Item from "./home-components/item";
import Footer from "../includes/non-admin-components/footer"

const totalDonationCount = obj => {

  let total = 0

  let users = 0

  const campaign = obj;

  const contribute = Object.keys(campaign).filter(i => campaign[i].contribute)

  const result = contribute.map(i => Object.values(campaign[i].contribute).filter(item => item.status.toLowerCase() === "approved"))

  for (let i in result) {

    for (let x in result[i]) {

      if (result[i][x]) {

        total += result[i][x].donation + result[i][x].uniqueCode

        users++
      }
    }
  }

  return { total, users };

}

class Home extends Component {

  constructor() {
    super()

    this.state = {
      totalCampaign: 0,
      totalDonation: 0,
      totalUsers: 0,

      loading: true,
    }
  }

  async componentWillMount() {

    try {

      const snap = await firebase.database().ref("/campaign").once("value")

      const totalCampaign = Object.keys(snap.val()).length

      const totalDonation = totalDonationCount(snap.val()).total.toLocaleString()

      const totalUsers = totalDonationCount(snap.val()).users

      this.setState({ totalCampaign, totalDonation, totalUsers, loading: false })

    } catch (error) {

    }
  }

  componentDidMount() {

    ReactGA.pageview('/homepage')
  }

  render() {

    const { name, picture } = this.props.auth.data || { name: undefined, picture: undefined }

    return this.state.loading
      ?
      <center className="vertical-center">
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <br />
        <br />
        <img style={{ width: "200px" }} src="/images/logo-a.png" alt="satukebaikan" />
      </center>
      :
      <div>
        {/* Load Navigation html Syntax */}
        <Navigation auth={{ name, picture }} />
        {/*End*/}

        {/*<Banner/>*/}
        <div id="bg-parent" style={{ position: "relative", overflow: "hidden" }}>
          <div id="bg-image" />
          <div className="container">
            <div id="bg-text" className="row">
              <div className="col-md-12">
                <h2 style={{ color: "#fff" }}>
                  <b>Satukebaikan</b> = <b>Tanpa batas</b>
                </h2>
                <p style={{ color: "#fff" }}>
                  "Karena itu Allah memberikan kepada mereka pahala didunia dan
                  pahala yang baik diakhirat. <br /> Dan Allah menyukai orang-orang
                  yang berbuat kebaikan."
                  <br />
                  (Q.s Ali Imran : 148)
                </p>
                <a
                  style={{
                    backgroundColor: "rgb(255, 81, 0)",
                    color: "white"
                  }}
                  href="/explorer/all"
                  className="button margin-top-25"
                >
                  Lihat Program Donasi
                </a>
              </div>
            </div>
          </div>
        </div>
        {/*End Benner*/}


        <div className="container" style={{ position: "relative" }}>
          <div id="desktop-mode" className="benner-status">
            <div className="row clearfix">
              <div className="col-md-4">
                <h4>{this.state.totalCampaign}</h4>
                <p>kampanye</p>
              </div>

              <div className="col-md-4">
                <h4 className="count">Rp. {this.state.totalDonation}</h4>
                <p>Donasi Terkumpul</p>
              </div>

              <div className="col-md-4">
                <h4>{this.state.totalUsers}</h4>
                <p>#Baikers</p>
              </div>
            </div>
          </div>
        </div>

        {/*Item*/}
        <Item />
        {/*End Item*/}

        {/*Blog*/}
        {/*End Blog*/}
        <hr />

        <div className="container">
          <div className="row">
            <div id="headline" className="col-md-8 margin-top-20 col-md-offset-2 centered" style={{ marginBottom: "20px" }}>
              <h2 className="headline centered">
                <b>Berbagi kebaikan</b>
                <span style={{ fontSize: "18px", lineHeight: "25px" }}>
                  Buat kampanye untuk menggalang dana yang akan disalurkan pada orang yang lebih membutuhkan. Anda bisa menjangkau orang-orang yang membutuhkan bantuan dengan lebih mudah.
                </span>
              </h2>
              <center>
                <a href="/dashboard/galang-dana" style={{ borderRadius: "5px" }} className="button border">Mulai galang dana</a>
                <br />
              </center>
            </div>
          </div>
        </div>


        {/* Load footer html Syntax */}
        <Footer />
      </div>
  }
}

export default Home;
