import React, { useEffect } from "react"
import edotensei from "edotensei"
// import axios from "axios"

import Navigation from "../includes/non-admin-components/navigation"
import Footer from "../includes/non-admin-components/footer"
import scripts from "../includes/external-scripts"

const { add } = edotensei

var componentDidmount = false

export default props => {

    useEffect(() => {

        (async () => {

            if (!componentDidmount) {

                try {

                    add(scripts())

                } catch (error) {

                    // add(scripts())
                }

                componentDidmount = true
            }
        })()
    })

    const { name, picture } = props.auth.data || { name: undefined, picture: undefined }

    return (

        <div>

            <Navigation auth={{ name, picture }} />

            <div id="titlebar" style={{ marginBottom: "0", background: "#23cbf5" }} className="gradient">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <center>
                                <h2 style={{ color: "#fff" }}><b>Cara Donasi</b></h2>
                                <p style={{ color: "#fff" }}>Langkah mudah Berdonasi di Satukebaikan</p>
                                <br />
                                <br />
                            </center>
                        </div>
                    </div>
                </div>
            </div>


            <div id="background-summary" className="container" style={{ background: "#fff" }}>
                <br />
                <div className="row">
                    <div className="col-md-12">
                        <div id="invoice" style={{ maxWidth: "100%", paddingTop: "30px" }}>
                            <p>
                                Donasi melalui Satukebaikan dapat dilakukan dengan mudah dan cepat :
                            </p>
                            <ol>
                                <li>Pilih Program yang ingin didonasikan</li>
                                <li>Klik Tombol "Berbagi Sekarang"</li>
                                <li>Login jika sudah Registrasi sebelumnya, atau Berdonasi tanpa login terlebih dahulu (cukup isi form singkat yang disediakan)</li>
                                <li>Pilih Metode Pembayaran</li>
                                <li>Checkout dan Transfer Donasi</li>
                            </ol>
                            <p>
                                <i>Ketarangan :</i>
                            </p>
                            <i>Pastikan Anda melakukan Transfer sesuai dengan Nominal yang tertera dan metode bayar yang sesuai dengan pilihan pada saat berdonasi agar donasi Anda terverifiaksi dengan cepat.</i>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

        </div>
    )

}
