import React, { Component } from "react"
import firebase from "firebase/app"
import "firebase/database"
import "firebase/database"
import dateFormat from "dateformat"
import iziToast from "izitoast"

import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import uploder from "./adapter"

import Navbar from "../includes/admin-components/admin-navigations"
import Sidebar from "../includes/admin-components/admin-sidebar"

// const { add } = Edotensei;

// const Scripts = [{ src: "/scripts/jquery-2.2.0.min.js", rel: "preload" }, { src: "/scripts/mmenu.min.js", defer: true }, { src: "/scripts/chosen.min.js", defer: true }, { src: "/scripts/slick.min.js", defer: true }, { src: "/scripts/rangeslider.min.js", defer: true }, { src: "/scripts/magnific-popup.min.js", defer: true }, { src: "/scripts/waypoints.min.js", defer: true }, { src: "/scripts/counterup.min.js", defer: true }, { src: "/scripts/tooltips.min.js", defer: true }, { src: "/scripts/custom.js", defer: true }]

/**
 * query ke database
 * @param callback
 */
const readData = (key, callback) => {
  firebase
    .database()
    .ref()
    .child("/campaign/" + key + "/update")
    .on("value", snap => {
      /**
       * apakah valuenya ada ?
       */
      let value = snap.val() ? snap.val() : {};
      /**
       * mengembalikan nilai dari query
       */
      callback(value);
    });
};

/**
 *
 * @param {string} key
 * @param {object} data
 * @param {function} callback
 */
const createData = (key, data, callback) => {
  firebase
    .database()
    .ref()
    .child("/campaign/" + key + "/update")
    .push(data, err => callback(err));
};

/**
 *
 * @param {string} key
 * @param {string} id
 * @param {object} data
 * @param {function} callback
 */
const updateData = (key, id, data, callback) => {
  firebase
    .database()
    .ref()
    .child("/campaign/" + key + "/update/" + id)
    .update(data, err => callback(err));
};

/**
 *
 * @param {string} key
 * @param {string} id
 */
const deleteData = (key, id) => {
  firebase
    .database()
    .ref()
    .child("/campaign/" + key + "/update/" + id)
    .remove(() => {

      iziToast.success({
        timeout: 20000,
        position: "topRight",
        title: "Sukses",
        message: "Berhasil menghapus pembaruan"
      })

    });
};

class Update extends Component {
  constructor() {
    super();
    this.state = {
      listUpdate: {},
      title: "",
      update: "",
      edit: { status: false, key: "" },
      write: true,
      isLoaded: false
    };
  }

  componentDidMount() {
    // add(Scripts);
  }

  /**
   * dieksekusi ketika component ditinggalkan
   */
  componentWillUnmount() {
    this.isCanceled = true;
  }

  async componentWillMount() {

    const key = this.props.match.params.id

    readData(key, snap => !this.isCanceled && this.setState({ listUpdate: snap, isLoaded: true }))
  }

  /**
   * handle update
   * @param {html} value
   */
  updateHandler(event, editor) {

    const value = editor.getData()

    this.setState({ update: value })
  }

  /**
   * handle input title
   * @param {event} e
   */
  inputHandler(e) {

    this.setState({ title: e.target.value });
  }

  writeHandler(e) {

    e.preventDefault();

    this.setState({
      edit: {
        status: false
      },
      write: false,
      update: "",
      title: ""
    });
  }

  editHandler(key, item, e) {

    e.preventDefault();

    const { content, title } = item

    this.setState({
      update: content,
      title,
      edit: { status: true, key },
      write: false
    });
  }

  confirm() {

    let { update, title } = this.state

    let key = this.props.match.params.id

    if (this.state.edit.status) {

      let data = {
        title: title,
        content: update

      };

      updateData(key, this.state.edit.key, data, err => {

        if (err) return alert(err.message);

        this.setState({
          edit: { status: false, key: "" }
        });

        iziToast.success({
          timeout: 20000,
          title: "Sukses",
          position: "topRight",
          message: "Berhasil menyimpan pembaruan"
        })
      })

    } else {

      const { email, name, picture, uid } = this.props.auth.data

      const data = {
        title: title,
        content: update,
        date: firebase.database.ServerValue.TIMESTAMP,
        email,
        uid,
        name,
        photo: picture
      };

      createData(key, data, err => {

        if (err) return alert(err.message);

        this.setState({
          update: "",
          title: ""
        });

        iziToast.success({
          timeout: 20000,
          position: "topRight",
          title: "Sukses",
          message: "Berhasil menambahkan pembaruan"
        })
      })
    }
  }


  submitHandler(e) {

    e.preventDefault();

    iziToast.info({
      timeout: 20000,
      close: true,
      overlay: true,
      zindex: 999,
      title: "Halo..",
      icon: "fa fa-question-circle",
      message: !this.state.edit.status ? "Tambahkan pembaruan?" : "Simpan Pembaruan?",
      position: "center",
      buttons: [
        [
          "<button class='button'>Ya!</button>",
          () => {
            //hide
            iziToast.destroy();

            //confirm
            this.confirm(this)
          },
          true
        ]
      ]
    });
  }

  /**
   * delete handler
   * @param {event} e
   */
  handleDelete(e) {

    const { key } = this.state.edit;

    const { id } = this.props.match.params

    iziToast.info({
      timeout: 20000,
      close: true,
      overlay: true,
      zindex: 999,
      title: "Halo..",
      icon: "fa fa-question-circle",
      message: "Hapus pembaruan ini?",
      position: "center",
      buttons: [
        [
          "<button class='button'>Ya!</button>",
          () => {
            //hide
            iziToast.destroy();

            deleteData(id, key);

            this.setState({
              write: true,
              update: "",
              title: "",
              edit: { status: false, key: "" }
            })
          },
          true
        ]
      ]
    });
  }

  upload(editor) {

    const toolbar = document.querySelector('.ck-toolbar')

    toolbar.style = "background : white"

    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      // Configure the URL to the upload script in your back-end here!
      return new uploder(loader);
    }
  }

  render() {

    const { picture, uid, name } = this.props.auth.data

    return (
      <div>
        {/* Load Navigation html Syntax */}
        <Navbar picture={picture} name={name} />
        {/*End*/}

        <div id="dashboard">
          {/* Sidebar*/}
          <Sidebar uid={uid} />
          {/* End Sidebar*/}

          {/*Content*/}
          <div className="dashboard-content">
            <div id="titlebar">
              <div className="row">
                <div className="col-md-12">
                  <h2>Pembaharuan</h2>
                  <nav id="breadcrumbs">
                    <ul>
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li>
                        <a href="/dashboard/kampanye-selesai">Kampanyeku</a>
                      </li>
                      <li>Pembaharuan</li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div
                  hidden={!this.state.isLoaded}
                  className="messages-container margin-top-0"
                >
                  <div className="messages-headline clearfix">
                  </div>
                  <div className="messages-container-inner">
                    <div className="messages-inbox">
                      <div className="listing-share">
                        <ol
                          hidden={!this.state.write}
                          className="share-buttons margin-top-20 margin-bottom-10"
                          style={{ maxHeight: "100vh" }}
                        >
                          <li>
                            <a
                              style={{ borderRadius: "5px" }}
                              className="fb-share"
                              href="#!"
                              onClick={this.writeHandler.bind(this)}
                            >
                              <i className="fa fa-plus" /> Tulis Pembaharuan
                            </a>
                          </li>
                        </ol>
                      </div>
                      <ul>
                        {Object.keys(this.state.listUpdate)
                          .reverse()
                          .map((item, i) => (
                            <li key={i} className="active-message">
                              <a
                                onClick={this.editHandler.bind(
                                  this,
                                  item,
                                  this.state.listUpdate[item]
                                )}
                                href="#!"
                              >
                                <div
                                  className="message-by"
                                  style={{ marginLeft: "0" }}
                                >
                                  <div className="message-by-headline">
                                    <h5>{this.state.listUpdate[item].title}</h5>
                                    <span>
                                      {dateFormat(
                                        this.state.listUpdate[item].date,
                                        "dd/mm/yyyy"
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </a>
                            </li>
                          ))}
                      </ul>
                    </div>
                    <div className="message-content" hidden={this.state.write}>
                      <div className="message-bubble clearfix">
                        <div
                          hidden={!this.state.edit.status}
                          style={{
                            float: "left"
                          }}
                        >
                          <a
                            href="#!"
                            type="submit"
                            style={{ float: "right", padding: "3px 20px" }}
                            className="button border with-icon"
                            onClick={this.handleDelete.bind(this)}
                          >
                            Hapus Pembaharuan{" "}
                            <i className="im im-icon-Trash-withMen" />
                          </a>
                        </div>
                        <div
                          style={{
                            float: "right",
                            fontSize: "30px",
                            color: "#23cbf5",
                            cursor: "pointer"
                          }}
                          onClick={() => {
                            this.setState({ write: true });
                          }}
                        >
                          <i className="im im-icon-Close-Window" />
                        </div>
                        <br />
                        <br />
                        <form onSubmit={this.submitHandler.bind(this)}>
                          <div>
                            <h5>Judul Pembaharuan </h5>
                            <input
                              className="search-field"
                              type="text"
                              required
                              value={this.state.title}
                              onChange={this.inputHandler.bind(this)}
                            />
                          </div>

                          <CKEditor
                            data={this.state.update}
                            onInit={this.upload}
                            config={{
                              toolbar: ['imageUpload', 'link', '|', 'heading', '|', 'bold', 'italic', '|', 'undo', 'redo',]
                            }}
                            editor={ClassicEditor}
                            onChange={this.updateHandler.bind(this)}
                          />

                          <br />

                          <button
                            type="submit"
                            style={{ float: "right", padding: "3px 20px" }}
                            className="button border"
                          >
                            {this.state.edit.status ? "Simpan" : "Tambahkan"}
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              {/** Loader animation */}
              <center hidden={this.state.isLoaded}>
                <br />
                <br />
                <div className="lds-dual-ring" />
                <br />
                <br />
                <br />
              </center>
              <div className="copyrights">
                © 2018 Satukebaikan indonesia. All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
        {/*End*/}
      </div>
    );
  }
}

export default Update;
