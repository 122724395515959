import React, { useEffect } from "react"
import edotensei from "edotensei"

import Navigation from "../includes/non-admin-components/navigation"
import Footer from "../includes/non-admin-components/footer"
import scripts from "../includes/external-scripts"

const { add } = edotensei

var componentDidmount = false

export default props => {

    useEffect(() => {

        (async () => {

            if (!componentDidmount) {

                add(scripts())

                componentDidmount = true
            }
        })()
    })

    const { name, picture } = props.auth.data || { name: undefined, picture: undefined }

    return (

        <div>

            <Navigation auth={{ name, picture }} />

            <div id="background-summary" className="container" style={{ background: "#fff" }}>
                <br />
                <div className="row">
                    <div className="col-md-12">
                        <div id="invoice" style={{ maxWidth: "100%" }}>
                            <center>
                                <img src="/images/logo-a.png" alt="" />
                                <br />
                                <br />
                            </center>
                            <p>
                                Satukebaikan adalah platform dimana siapa saja bisa memulai kampanye, penggalangan dana untuk tujuan sosial
                                dan berdonasi untuk membantu bagi yang membutuhkan, baik itu pribadi, komunitas, perusahaan, usaha kecil/besar. instansi sekolah dan lain-lain.
                            </p>
                            <p>
                                Pengesahan Pendirian Badan Hukum Yayasan Satukebaikan <br /> <span style={{ color: "rgb(35, 203, 245)" }}> Nomor AHU-0000998.AH.01.04. Tahun 2019 </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

        </div>
    )

}
