import React, { useEffect } from "react"
import edotensei from "edotensei"
// import axios from "axios"

import Navigation from "../includes/non-admin-components/navigation"
import Footer from "../includes/non-admin-components/footer"
import scripts from "../includes/external-scripts"

const { add } = edotensei

var componentDidmount = false

export default props => {

    useEffect(() => {

        (async () => {

            if (!componentDidmount) {

                try {

                    add(scripts())

                } catch (error) {

                    // add(scripts())
                }

                componentDidmount = true
            }
        })()
    })

    const { name, picture } = props.auth.data || { name: undefined, picture: undefined }

    return (

        <div style={{ background: "#23cbf5" }}>

            <Navigation auth={{ name, picture }} />

            <div id="titlebar" style={{ marginBottom: "0", background: "#23cbf5" }} className="gradient">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <center>
                                <h2 style={{ color: "#fff" }}><b>Kebijakan Privasi</b></h2>
                                <p style={{ color: "#fff" }}>Kebijkan Privasi Satukebaikan</p>
                            </center>
                        </div>
                    </div>
                </div>
            </div>


            <div id="background-summary" className="container" style={{ background: "#fff" }}>
                <br />
                <div className="row">
                    <div className="col-md-12">
                        <div id="invoice" style={{ maxWidth: "100%", paddingTop: "30px" }}>
                            <p>
                                Ketentuan-ketentuan yang ada dalam halaman ini mengatur hal-hal menyangkut data-data para Pengguna Situs yang diberikan oleh para Pengguna Situs kepada Pengelola Situs dalam rangka pemanfaatan fasilitas, fitur, jasa, dan/atau layanan yang ditawarkan oleh Pengelola Situs melalui Situs satukebaikan.com. Ketentuan-ketentuan menyangkut data mengikat seluruh Pengguna Situs tanpa terkecuali untuk tunduk dan patuh atas ketentuan-ketentuan yang telah ditetapkan Pengelola Situs.
                            </p>
                            <p>
                                Berikut ini ketentuan-ketentuan menyangkut data tersebut:
                            </p>


                            <ol>
                                <li>
                                    Atas data-data yang diberikan oleh para Pengguna Situs kepada Pengelola Situs sebagai pemenuhan syarat atas pemanfaatan fasilitas, fitur, jasa, dan/atau layanan yang ditawarkan oleh Pengelola Situs, maka Pengelola Situs berkewajiban untuk:
                                    <ol type="a">
                                        <li>
                                            Menjaga kerahasiaan data-data para Pengguna yang tidak dapat ditampilkan dalam Situs dan/atau diberikan kepada pihak-pihak tertentu atas penggunaan jasa atau layanan Situs selama tidak ada perjanjian tertulis terlebih dahulu kepada Pengguna.
                                        </li>
                                        <li>
                                            Kerahasiaan data Pengguna yang wajib dijaga oleh Pengelola Situs menjadi tidak berlaku apabila Pengelola Situs dipaksa oleh ketentuan hukum yang berlaku, perintah pengadilan, dan/atau perintah dari aparat/instansi yang berwenang, untuk membuka data-data milik Pengguna tersebut.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    Pengelola Situs hanya bertanggung jawab atas data yang diberikan Pengguna Situs kepada Pengelola Situs sebagaimana yang telah ditentukan pada ketentuan sebelumnya.
                                </li>
                                <li>
                                    Pengelola Situs tidak bertanggung jawab atas pemberian atau pertukaran data yang dilakukan sendiri antar Pengguna Situs.
                                </li>
                                <li>
                                    Pengelola berhak untuk mengubah ketentuan menyangkut data-data para Pengguna Situs tanpa pemberitahuan terlebih dahulu dengan tanpa mengabaikan hak para Pengguna Situs untuk dijaga kerahasiaan datanya sebagaimana yang telah ditentukan dalam butir (1).
                                </li>
                            </ol>


                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />

            <Footer />

        </div>
    )

}
