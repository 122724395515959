import React, { Component } from "react";
import firebase from "firebase/app"
import "firebase/database"
import Edotensei from "edotensei"
import ReactGA from 'react-ga'
import LazyLoad from "react-lazyload"

import Placeholder from "../includes/non-admin-components/placeholder"
import Footer from "../includes/non-admin-components/footer"
import Navigation from "../includes/non-admin-components/navigation"

const { add } = Edotensei;

const Scripts = [{ src: "/scripts/jquery-2.2.0.min.js", rel: "preload" }, { src: "/scripts/mmenu.min.js", defer: true }, { src: "/scripts/chosen.min.js", defer: true }, { src: "/scripts/slick.min.js", defer: true }, { src: "/scripts/rangeslider.min.js", defer: true }, { src: "/scripts/magnific-popup.min.js", defer: true }, { src: "/scripts/waypoints.min.js", defer: true }, { src: "/scripts/counterup.min.js", defer: true }, { src: "/scripts/tooltips.min.js", defer: true }, { src: "/scripts/custom.js", defer: true }]

const totalDonasi = arr => {

    let value = 0

    arr = arr.map(i => i.status.toLowerCase() === "approved" ? i : null)

    for (let i in arr) {

        if (arr[i]) {

            value += (arr[i].donation + arr[i].uniqueCode)
        }
    }

    return value
}

const arrayToObject = (arr, item) => {

    var rv = {}

    for (var i = 0; i < arr.length; i++) {
        rv[arr[i]] = item[arr[i]];
    }

    return rv
}

const berdonasiPada = async phone => {

    const obj = await firebase.database().ref("campaign").once("value")

    let campaign = obj.val()

    const contribute = Object.keys(campaign).filter(i => campaign[i].contribute)

    let result = contribute.map(i => {

        const results = Object.keys(campaign[i].contribute).filter(item => {

            const end = campaign[i].contribute[item].phone.toString().length

            const calculate = (end - 4)

            const start = parseInt(calculate, 0) > 0 ? calculate : 0

            return campaign[i].contribute[item].phone.toString().substring(start, end) === phone
        })

        campaign[i].contribute = arrayToObject(results, campaign[i].contribute)

        return Object.keys(campaign[i].contribute).length > 0 ? i : undefined
    })

    result = result.filter(i => i)

    result = result.map(i => {

        campaign[i].contribute = obj.val()[i].contribute

        return i
    })

    return arrayToObject(result, campaign)
}

const galangDana = async uid => {

    const obj = await firebase.database().ref("campaign").orderByChild("uid").equalTo(uid).once("value")

    return obj.val() || {}
}

class Profile extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isLoaded: false,
            user: {},
            campaign: {},
            berdonasiPada: {}
        }
    }

    async componentWillMount() {

        const { uid } = this.props.match.params

        const user = await firebase.database().ref("users").child(uid).once("value")

        if (!user.exists()) return window.location.href = "/404"

        const campaign = await galangDana(uid)

        const end = user.val().phone ? user.val().phone.toString().length : ""

        const start = (end - 4) > 0 ? (end - 4) : 0

        const resultBerdonasiPada = await berdonasiPada(user.val().phone ? user.val().phone.toString().substring(start, end) : "")

        ReactGA.pageview("/orangbaik/" + uid)

        add(Scripts)

        this.setState({ isLoaded: true, user: user.val(), campaign: campaign, berdonasiPada: resultBerdonasiPada })
    }

    render() {

        const { isLoaded, user, campaign, berdonasiPada } = this.state

        const { name, picture } = this.props.auth.data || { name: undefined, picture: undefined }

        return isLoaded
            ? (
                <div >
                    <Navigation auth={{ name, picture }} />

                    <div id="titlebar" style={{ marginBottom: "0" }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">

                                    <div className="user-profile-titlebar">
                                        <div className="user-profile-avatar">
                                            <img src={user.photo || "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png"} alt={user.name} style={{ border: "3px solid #eee", borderRadius: "50%" }} />
                                        </div>
                                        <div className="user-profile-name">
                                            <h2><b>{user.name || "profil belum dilengkapi"}</b></h2>
                                        </div>
                                    </div>

                                    <div className="margin-top-80">
                                        <p>{user.address || "" + " " + (user.regency || "")}</p>
                                        <p>{user.description || ""}</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-md-12" >
                                <div className="style-1">

                                    <ul className="tabs-nav">
                                        <li className="active"><a href="#tab1ba">Berdonasi pada</a></li>
                                        <li><a href="#tab2b">Galang dana</a></li>
                                    </ul>

                                    <div className="tabs-container">
                                        <div className="tab-content" id="tab1ba">
                                            <div className="row">
                                                {
                                                    Object.keys(berdonasiPada)
                                                        .slice(0, 5)
                                                        .map((item, i) => (
                                                            <div key={i} className="carousel-item col-md-4" style={{ paddingLeft: "0" }}>
                                                                <a
                                                                    href={"/" + item}
                                                                    className="listing-item-container"
                                                                    style={{
                                                                        boxShadow: "0 0 5px 1px #ececec",
                                                                        background: "white"
                                                                    }}
                                                                >
                                                                    {/**listing item */}
                                                                    <div style={{ height: "230px" }} className="listing-item">
                                                                        <LazyLoad placeholder={<Placeholder />}>
                                                                            <img src={berdonasiPada[item].image} alt={berdonasiPada[item].title} />
                                                                        </LazyLoad>
                                                                    </div>

                                                                    <div className="star-rating" style={{ padding: "0 16px" }} >
                                                                        <div className="row" style={{ minHeight: "224.32px", position: "relative" }}>
                                                                            <div className="col-md-12">
                                                                                <h3 style={{ fontSize: "18px", lineHeight: "25px" }} >
                                                                                    {berdonasiPada[item].title}
                                                                                </h3>
                                                                                <br />
                                                                            </div>

                                                                            {/** card footer */}
                                                                            <div style={{ marginBottom: "10px", position: "absolute", bottom: "0", width: "100%" }} className="col-md-12 clearfix">
                                                                                <div className="clearfix">
                                                                                    <div style={{ width: "40px", height: "40px", borderRadius: "50%", backgroundImage: "url( " + berdonasiPada[item].ownerPhoto + " )", backgroundSize: "100% 100%", float: "left" }} className="tag" />
                                                                                    <span style={{ fontSize: "18px", marginLeft: "10px", color: "#ff5100", lineHeight: "20px", float: "left" }}>
                                                                                        <i style={{ display: "block" }} className="fa fa-check-circle" />
                                                                                        <span style={{ fontSize: "12px", color: "black" }}>
                                                                                            {" " + berdonasiPada[item].ownerName}
                                                                                        </span>
                                                                                    </span>
                                                                                    <div />
                                                                                </div>
                                                                                <hr style={{ marginBottom: "10px" }} />
                                                                                <div style={{ float: "left", lineHeight: "1.5", fontFamily: "Open Sans", fontSize: "14px", fontStyle: "normal", fontVariant: "normal", fontWeight: "0" }} className="left">
                                                                                    <span style={{ fontSize: "12px", display: "block" }} >
                                                                                        Terkumpul
                                                                                    </span>
                                                                                    Rp.{" "}
                                                                                    {
                                                                                        berdonasiPada[item].contribute
                                                                                            ? totalDonasi(Object.values(berdonasiPada[item].contribute)).toLocaleString()
                                                                                            : 0
                                                                                    }
                                                                                </div>
                                                                                <div style={{ float: "right", textAlign: "right", fontSize: "14px", fontFamily: "Open Sans", fontStyle: "none", lineHeight: "1.5" }} className="right">
                                                                                    <span style={{ fontSize: "12px", display: "block" }} >
                                                                                        Sisa hari
                                                                                    </span>
                                                                                    {Date.parse(new Date(berdonasiPada[item].deadline)) <
                                                                                        Date.parse(new Date())
                                                                                        ? "Selesai"
                                                                                        : Math.floor((Date.parse(berdonasiPada[item].deadline) - Date.parse(new Date())) / (1000 * 60 * 60 * 24) + 1)}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="progress-bar" style={{ overflow: "hidden" }}>
                                                                        <span
                                                                            className="progress-bar-fill"
                                                                            style={{
                                                                                backgroundColor: "#ff5100",
                                                                                width: Math.ceil(totalDonasi(Object.values(berdonasiPada[item].contribute || [])) / parseInt(berdonasiPada[item].target, 0) * 100) + "%",
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        ))}
                                            </div>
                                        </div>

                                        <div className="tab-content" id="tab2b">
                                            <div className="row">
                                                {
                                                    Object.keys(campaign)
                                                        .slice(0, 5)
                                                        .map((item, i) => (
                                                            <div key={i} className="carousel-item col-md-4" style={{ paddingLeft: "0" }}>
                                                                <a
                                                                    href={"/" + item}
                                                                    className="listing-item-container"
                                                                    style={{
                                                                        boxShadow: "0 0 5px 1px #ececec",
                                                                        background: "white"
                                                                    }}
                                                                >
                                                                    {/**listing item */}
                                                                    <div style={{ height: "230px" }} className="listing-item">
                                                                        <LazyLoad placeholder={<Placeholder />}>
                                                                            <img src={campaign[item].image} alt={campaign[item].title} />
                                                                        </LazyLoad>
                                                                    </div>

                                                                    <div className="star-rating" style={{ padding: "0 16px" }} >
                                                                        <div className="row" style={{ minHeight: "224.32px", position: "relative" }}>
                                                                            <div className="col-md-12">
                                                                                <h3 style={{ fontSize: "18px", lineHeight: "25px" }} >
                                                                                    {campaign[item].title}
                                                                                </h3>
                                                                                <br />
                                                                            </div>

                                                                            {/** card footer */}
                                                                            <div style={{ marginBottom: "10px", position: "absolute", bottom: "0", width: "100%" }} className="col-md-12 clearfix">
                                                                                <div className="clearfix">
                                                                                    <div style={{ width: "40px", height: "40px", borderRadius: "50%", backgroundImage: "url( " + campaign[item].ownerPhoto + " )", backgroundSize: "100% 100%", float: "left" }} className="tag" />
                                                                                    <span style={{ fontSize: "18px", marginLeft: "10px", color: "#ff5100", lineHeight: "20px", float: "left" }}>
                                                                                        <i style={{ display: "block" }} className="fa fa-check-circle" />
                                                                                        <span style={{ fontSize: "12px", color: "black" }}>
                                                                                            {" " + campaign[item].ownerName}
                                                                                        </span>
                                                                                    </span>
                                                                                    <div />
                                                                                </div>
                                                                                <hr style={{ marginBottom: "10px" }} />
                                                                                <div style={{ float: "left", lineHeight: "1.5", fontFamily: "Open Sans", fontSize: "14px", fontStyle: "normal", fontVariant: "normal", fontWeight: "0" }} className="left">
                                                                                    <span style={{ fontSize: "12px", display: "block" }} >
                                                                                        Terkumpul
                                                                                    </span>
                                                                                    Rp.{" "}
                                                                                    {
                                                                                        campaign[item].contribute
                                                                                            ? totalDonasi(Object.values(campaign[item].contribute)).toLocaleString()
                                                                                            : 0
                                                                                    }
                                                                                </div>
                                                                                <div style={{ float: "right", textAlign: "right", fontSize: "14px", fontFamily: "Open Sans", fontStyle: "none", lineHeight: "1.5" }} className="right">
                                                                                    <span style={{ fontSize: "12px", display: "block" }} >
                                                                                        Sisa hari
                                                                                    </span>
                                                                                    {Date.parse(new Date(campaign[item].deadline)) <
                                                                                        Date.parse(new Date())
                                                                                        ? "Selesai"
                                                                                        : Math.floor((Date.parse(campaign[item].deadline) - Date.parse(new Date())) / (1000 * 60 * 60 * 24) + 1)}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="progress-bar" style={{ overflow: "hidden" }}>
                                                                        <span
                                                                            className="progress-bar-fill"
                                                                            style={{
                                                                                backgroundColor: "#ff5100",
                                                                                width: Math.ceil(totalDonasi(Object.values(campaign[item].contribute || [])) / parseInt(campaign[item].target, 0) * 100) + "%",
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        ))}
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <br />

                    <Footer />
                </div>
            )
            : (
                <center className="vertical-center">
                    <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <br />
                    <br />
                    <img style={{ width: "200px" }} src="/images/logo-a.png" alt="satukebaikan" />
                </center>
            )
    }

}

export default Profile