import React, { Component } from "react"
import firebase from "firebase/app"
import "firebase/database"
import Edotensei from "edotensei"
import iziToast from "izitoast"

import Navbar from "../includes/admin-components/admin-navigations"
import Sidebar from "../includes/admin-components/admin-sidebar"

const { add } = Edotensei

const Scripts = [{ src: "/scripts/jquery-2.2.0.min.js", rel: "preload" }, { src: "/scripts/mmenu.min.js", defer: true }, { src: "/scripts/chosen.min.js", defer: true }, { src: "/scripts/slick.min.js", defer: true }, { src: "/scripts/rangeslider.min.js", defer: true }, { src: "/scripts/magnific-popup.min.js", defer: true }, { src: "/scripts/waypoints.min.js", defer: true }, { src: "/scripts/counterup.min.js", defer: true }, { src: "/scripts/tooltips.min.js", defer: true }, { src: "/scripts/custom.js", defer: true }]

const arrayToObject = (arr, item) => {

    let rv = {}

    for (var i = 0; i < arr.length; i++) {

        rv[arr[i]] = item[arr[i]];

    }

    return rv
}

const deleteData = key => {

    firebase.database().ref().child("/campaign/" + key).remove()

    iziToast.show({
        class: "izimodal",
        timeout: 2000,
        close: true,
        overlay: true,
        progressBar: false,
        message: "kampanye berhasil dihapus!",
        messageLineHeight: "40px",
        position: "center",
        backgroundColor: "#25d366",
        messageColor: "#fff",
        titleColor: "#fff",
        iconColor: "#fff",
        layout: 1,
        balloon: true,
        icon: "fa fa-check",
        transitionIn: "bounceInLeft",
        onClosed: () => window.location.reload()
    })
}

const totalDonasi = arr => {

    let value = 0

    arr = arr.map(i => i.status.toLowerCase() === "approved" ? i : null)

    for (let i in arr) {

        if (arr[i]) {

            value += (arr[i].donation + arr[i].uniqueCode)

        }
    }

    return value
}

class myCampaign extends Component {

    constructor() {

        super()

        this.state = { campaign: "", _campaign: "", isLoaded: false }
    }

    componentDidMount() {

        add(Scripts)
    }

    async componentWillMount() {

        const { uid } = this.props.auth.data

        const getAllCampaign = firebase.functions().httpsCallable("getAllCampaign")

        const value = await getAllCampaign()

        if (uid !== process.env.REACT_APP_SUPER_ADMIN_UID) {

            const filter = Object.keys(value.data).filter(i => value.data[i].uid === uid)

            this.setState({ campaign: arrayToObject(filter, value.data), _campaign: arrayToObject(filter, value.data), isLoaded: true })

        } else {

            this.setState({ campaign: value.data, _campaign: value.data, isLoaded: true })

        }
    }

    searchHandler(e) {

        const value = e.target.value

        const { _campaign } = this.state

        let campaign = _campaign

        const result = Object.keys(campaign).filter(i => campaign[i].title.toLowerCase().search(value.toLowerCase()) !== -1)

        campaign = arrayToObject(result, campaign)

        this.setState({ campaign })
    }

    deleteHandler(key, image, e) {

        e.preventDefault()

        const { name } = this.props.auth.data

        iziToast.show({
            timeout: 20000,
            close: true,
            overlay: true,
            zindex: 999,
            image: image,
            imageWidth: 150,
            title: `Hai ${name}`,
            // icon: "fa fa-question-circle",
            message: "Apakah ingin menghapus kampanye ini?",
            position: "center",
            backgroundColor: "rgb(35, 203, 245)",
            titleColor: "#fff",
            color: "#fff",
            iconColor: "#fff",
            messageColor: "#fff",
            layout: 1,
            balloon: true,
            transitionIn: "bounceInLeft",
            progressBar: false,
            buttons: [
                [
                    "<button class='button' style='background: #fff; color :rgb(35, 203, 245); border-color: #fff'>Ya, hapus sekarang!</button>",
                    () => {

                        iziToast.destroy()

                        deleteData(key)
                    }, true
                ]
            ]
        })
    }

    render() {

        const { campaign } = this.state;

        const { picture, uid, name } = this.props.auth.data

        return (
            <div>

                <Navbar picture={picture} name={name} />

                <div id="dashboard">

                    <Sidebar uid={uid} />

                    <div className="dashboard-content">
                        <div id="titlebar">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2>Kampanye yang berjalan</h2>
                                    <nav id="breadcrumbs">
                                        <ul>
                                            <li>
                                                <a href="/">Home</a>
                                            </li>
                                            <li>Kampanye yang berjalan</li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div hidden={!this.state.isLoaded} className="col-lg-12 col-md-12">

                                <div className="dashboard-list-box margin-top-0">
                                    <h4 className="clearfix">
                                        <div className="input-with-icon medium-icons" style={{ float: "right" }}>
                                            <input
                                                style={{ marginBottom: "0", boxShadow: "none", fontFamily: "Open Sans" }}
                                                id="autocomplete-input"
                                                type="text"
                                                onChange={this.searchHandler.bind(this)}
                                            />
                                            <i style={{ color: "#ff5100", top: "26px" }} className="fa fa-search" />
                                        </div>
                                    </h4>
                                    <ul>
                                        {
                                            Object.keys(campaign).reverse().map((key, i) => (
                                                Date.parse(new Date(campaign[key].deadline)) < Date.parse(new Date())
                                                    ? null
                                                    : (
                                                        <li key={i}>
                                                            <div className="list-box-listing">
                                                                <div className="list-box-listing-img">
                                                                    <a href={"/" + key}>
                                                                        <img src={campaign[key].image} alt="" />
                                                                    </a>
                                                                </div>
                                                                <div className="list-box-listing-content">
                                                                    <div className="inner">
                                                                        <h3>
                                                                            <a href={"/" + key}>{campaign[key].title}</a> {" "}
                                                                            {
                                                                                campaign[key].isFundraiser
                                                                                    ? <span style={{ fontSize: "12px", color: "#ff5100", fontWeight: "bolder" }}>Sebagai Penggalang dana</span>
                                                                                    : null
                                                                            }

                                                                        </h3>
                                                                        <strong>
                                                                            Rp.{" "}
                                                                            {
                                                                                campaign[key].contribute
                                                                                    ? (totalDonasi(Object.values(campaign[key].contribute)) + campaign[key].donationFromFundraiser).toLocaleString()
                                                                                    : 0
                                                                            }
                                                                        </strong>
                                                                        <span>&nbsp;Terkumpul dari </span>
                                                                        <strong>
                                                                            &nbsp; Rp.{" "} {parseInt(campaign[key].target, 0).toLocaleString()}
                                                                        </strong>
                                                                        <br />
                                                                        <span>{campaign[key].location}</span>
                                                                        <div className="star-rating">
                                                                            <div className="rating-counter" style={{ paddingLeft: "0" }}>
                                                                                <b>
                                                                                    {
                                                                                        Date.parse(new Date(campaign[key].deadline)) < Date.parse(new Date())
                                                                                            ? "Selesai"
                                                                                            : Math.floor((Date.parse(campaign[key].deadline) - Date.parse(new Date())) / (1000 * 60 * 60 * 24) + 1) + " Hari lagi"
                                                                                    }
                                                                                </b>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="buttons-to-right">
                                                                <a
                                                                    href={"/dashboard/kampanyeku/pembaruan/" + key}
                                                                    style={{ borderRadius: "5px", background: "#fff", color: "#23cbf5", border: "1px solid #23cbf5" }}
                                                                    className="button gray"
                                                                >
                                                                    <i className="sl sl-icon-chart" /> Info Terbaru
                                                                </a>
                                                                <a
                                                                    href={"/dashboard/kampanyeku/list-donasi/" + key}
                                                                    style={{ borderRadius: "5px", background: "#fff", color: "#23cbf5", border: "1px solid #23cbf5" }}
                                                                    className="button gray"
                                                                >
                                                                    <i className="sl sl-icon-people" /> Donatur
                                                                </a>
                                                                <a
                                                                    href={"/dashboard/kampanyeku/edit/" + key}
                                                                    style={{ borderRadius: "5px", background: "#fff", color: "#23cbf5", border: "1px solid #23cbf5" }}
                                                                    className="button gray"
                                                                >
                                                                    <i className="sl sl-icon-note" /> Edit
                                                                </a>
                                                                {
                                                                    uid === process.env.REACT_APP_SUPER_ADMIN_UID
                                                                        ? (
                                                                            <a
                                                                                href="#!"
                                                                                style={{ borderRadius: "5px", background: "#fff", color: "#f96161", border: "1px solid #f96161" }}
                                                                                onClick={this.deleteHandler.bind(this, key, campaign[key].image)}
                                                                                className="button gray"
                                                                            >
                                                                                <i className="sl sl-icon-close" />{" "} Hapus
                                                                            </a>
                                                                        ) : null
                                                                }
                                                            </div>
                                                        </li>
                                                    )
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>

                            <center hidden={this.state.isLoaded}>
                                <br />
                                <br />
                                <div className="lds-dual-ring" />
                                <br />
                                <br />
                                <br />
                            </center>

                            <div className="col-md-12">
                                <div className="copyrights">
                                    © 2018 Satukebaikan indonesia. All Rights Reserved.
                </div>
                            </div>
                        </div>
                    </div>
                    {/*End*/}
                </div>
            </div>
        );
    }
}

export default myCampaign;
