import React, { useEffect } from "react"
import edotensei from "edotensei"
// import axios from "axios"

import Navigation from "../includes/non-admin-components/navigation"
import Footer from "../includes/non-admin-components/footer"
import scripts from "../includes/external-scripts"

const { add } = edotensei

var componentDidmount = false

export default props => {

    useEffect(() => {

        (async () => {

            if (!componentDidmount) {

                try {

                    add(scripts())

                } catch (error) {

                    // add(scripts())
                }

                componentDidmount = true
            }
        })()
    })

    const { name, picture } = props.auth.data || { name: undefined, picture: undefined }

    return (

        <div >

            <Navigation auth={{ name, picture }} />

            <div style={{ height: "400px", background: "rgb(35, 203, 245)", textAlign: "center", paddingTop: "8%" }}>
                <h1 style={{ color: "#fff", fontSize: "40px" }}>
                    <b>Mohon Maaf</b>
                </h1>
                <p style={{ color: "#fff" }}>halaman ini belum kami tayangkan, <br />
                    sementara masih dalam tahap persiapan dan pengembangan.
                </p>
            </div>

            <Footer />

        </div>
    )

}
