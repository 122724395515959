import React, { Component } from "react"
import firebase from "firebase/app"
import "firebase/database"
import dateFormat from "dateformat"
import { FacebookShareButton, WhatsappShareButton } from "react-share"
import Edotensei from "edotensei"
import ReactGA from 'react-ga'
import izitoast from "izitoast"

import Navigation from "../includes/non-admin-components/navigation"

const { add } = Edotensei;

const Scripts = [{ src: "/scripts/jquery-2.2.0.min.js", rel: "preload" }, { src: "/scripts/mmenu.min.js", defer: true }, { src: "/scripts/chosen.min.js", defer: true }, { src: "/scripts/slick.min.js", defer: true }, { src: "/scripts/rangeslider.min.js", defer: true }, { src: "/scripts/magnific-popup.min.js", defer: true }, { src: "/scripts/waypoints.min.js", defer: true }, { src: "/scripts/counterup.min.js", defer: true }, { src: "/scripts/tooltips.min.js", defer: true }, { src: "/scripts/custom.js", defer: true }]

const searchData = (pathname, key, callback) => {

  firebase.database().ref().child(pathname).orderByChild("uid").equalTo(key).on("value", snap => {

    if (!snap.exists()) return (window.location.href = "/404");

    callback(snap.val());
  });
};

const readData = (pathname, callback) => {
  firebase
    .database()
    .ref()
    .child(pathname)
    .on("value", snap => {
      /**
       * redirect to 404 when data not found
       */
      if (!snap.val()) return (window.location.href = "/404");
      /**
       * return value
       */
      callback(snap.val());
    });
};

class Notfound extends Component {
  constructor() {
    super();
    this.state = {
      summery: {},
      campaign: {},
      isLoaded: false
    };
  }

  componentWillMount() {

    const { key, pushid } = this.props.match.params;

    const pathname = "/campaign/" + pushid + "/contribute";

    ReactGA.pageview(pathname)

    searchData(pathname, key, snap => {

      this.setState({ summery: snap, isLoaded: true });
    });

    readData("/campaign/" + pushid, snap => {

      this.setState({ campaign: snap, isLoaded: true });

      add(Scripts);
    });
  }

  copy() {

    try {

      // let { summery } = this.state

      // summery = Object.values(summery)

      const accountNumber = "3033303347" //summery[0].invoice.available_banks.bank_account_number || summery[0].invoice.available_banks.payment_code

      const el = document.createElement("textarea")

      el.value = accountNumber

      el.setAttribute('readonly', '')

      document.body.appendChild(el)

      el.select()

      document.execCommand("copy")

      document.body.removeChild(el)

      izitoast.show({
        timeout: 1000,
        message: "Copying text to clipboard",
        zindex: 9999999,
        position: "topRight",
        backgroundColor: "rgb(35, 203, 245)",
        balloon: true,
        titleColor: "#fff",
        color: "#fff",
        iconColor: "#fff",
        messageColor : "#fff",
        close : false
      });

    } catch (error) {

      console.log(error)
    }
  }

  render() {

    const { summery, isLoaded, campaign } = this.state;

    const ringkasan = Object.values(summery)

    var date = ringkasan[0] ? new Date(ringkasan[0].date) : false;

    if (date) { var tomorrow = date.setDate(date.getDate() + 1) }

    const { host } = window.location;

    const { name, picture } = this.props.auth.data || { name: undefined, picture: undefined }

    const instructions = ringkasan[0] ? ringkasan[0].invoice ? ringkasan[0].invoice.available_banks.data : {} : {}

    return (
      <div>
        {/* Load Navigation html Syntax */}
        <Navigation auth={{ name, picture }} />
        {/*End*/}

        <center hidden={isLoaded} className="vertical-center">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <br />
          <br />
          <img style={{ width: "200px" }} src="/images/logo-a.png" alt="satukebaikan" />
        </center>

        {/*Content 404 Not found*/}
        <div hidden={!isLoaded} id="background-summary">
          <br />
          <div id="invoice">
            <div className="row">
              <div className="col-md-6">
                <div>
                  <img width="150" src="/images/logo-a.png" alt="" />
                </div>
              </div>

              <div className="col-md-6">
                <p id="details">
                  <strong>ID Donasi :</strong> #
                  {ringkasan[0] ? ringkasan[0].uid.substr(0, 4) : ""} <br />
                  <strong>Tanggal :</strong>{" "}
                  {ringkasan[0]
                    ? dateFormat(ringkasan[0].date, "dd/mm/yyyy")
                    : ""}{" "}
                </p>
              </div>

              <div className="col-md-12">
                <strong>Nama : &nbsp;</strong>
                {ringkasan[0] ? ringkasan[0].name : null}
                <br />
                <strong>Email : &nbsp;</strong>
                {ringkasan[0] ? ringkasan[0].email : null}
                <br />
                <br />
                <p align="left" style={{ margin: "0" }}>
                  Anda akan berdonasi untuk campaign <b>{campaign.title}</b>{" "}
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <table id="table-summery" className="margin-top-20">
                  <thead>
                    <tr>
                      <th />
                      <th />
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Nominal Donasi</b>
                      </td>
                      <td>
                        <b>Rp. </b>
                      </td>
                      <td style={{ fontSize: "24px" }}>
                        <b>
                          {ringkasan[0]
                            ? ringkasan[0].donation.toLocaleString()
                            : ""}
                        </b>
                      </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Kode Unik (akan didonasikan)</b>
                      </td>
                      <td>
                        <b>Rp.</b>
                      </td>
                      <td style={{ fontSize: "24px" }}>
                        <b style={{ color: "black" }}>
                          {ringkasan[0] ? ringkasan[0].uniqueCode : ""}
                        </b>
                      </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td style={{ borderBottom: "none", fontSize: "24px" }}>
                        <b style={{ color: "black" }}>Total</b>
                      </td>
                      <td>
                        <b>Rp.</b>
                      </td>
                      <td style={{ borderBottom: "none", fontSize: "24px" }}>
                        <b style={{ color: "black" }}>
                          {ringkasan[0]
                            ? (
                              ringkasan[0].donation + ringkasan[0].uniqueCode
                            ).toLocaleString()
                            : ""}
                        </b>
                      </td>
                    </tr>
                    <tr style={{ borderBottom: "1px solid #ddd" }}>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/*<div className="col-md-4 col-md-offset-8">
                <table id="totals">
                  <tbody>
                    <tr>
                      <th style={{ borderBottom: "none", fontSize: "24px" }}>
                        Total
                      </th>
                      <th style={{ borderBottom: "none", fontSize: "24px" }}>
                        <span>
                          Rp.{" "}
                          {ringkasan[0]
                            ? (
                                ringkasan[0].donation + ringkasan[0].uniqueCode
                              ).toLocaleString()
                            : ""}
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div> */}

              {(ringkasan[0] && ringkasan[0].status === "Approved") ||
                (ringkasan[0] && ringkasan[0].status === "Canceled") ? null : (
                  <div className="col-md-12">
                    <div className="notification notice closeable">
                      <p>
                        <b>
                          Transfer tepat Rp.{" "}
                          {ringkasan[0]
                            ? (
                              ringkasan[0].donation + ringkasan[0].uniqueCode
                            ).toLocaleString()
                            : ""}
                        </b>{" "}
                        (tepat hingga 3 digit terakhir) agar donasi mudah di
                        verifikasi oleh tim kami, perbedaan nilai transfer akan
                        menghambat proses verifikasi donasi.
                    </p>
                    </div>
                  </div>
                )}
            </div>
            {ringkasan[0] && ringkasan[0].status === "Approved" ? (
              <div className="row">
                <div className="col-md-12">
                  <center>
                    <div className="notification success closeable">
                      <img style={{ borderRadius: "50%" }} src="https://invoice-staging.xendit.co/static/img/success.png" alt="" />
                      <h3 style={{ fontFamily: "Open Sans" }}>Terimakasih</h3>
                      <p>Pembayaran anda berhasil.</p>
                    </div>
                  </center>
                </div>
              </div>
            ) : ringkasan[0] && ringkasan[0].status === "Canceled" ? (
              <div className="row">
                <div className="col-md-12">
                  <center>
                    <div className="notification error  closeable">
                      <img style={{ borderRadius: "50%" }} src="https://invoice-staging.xendit.co/static/img/warning.png" alt="" />
                      <h3 style={{ fontFamily: "Open Sans" }}>Mohon maaf</h3>
                      <p>Inovice telah kedaluwarsa.</p>
                    </div>
                  </center>
                </div>
              </div>
            ) : (
                  <div>
                    <div className="row">
                      <div className="col-md-12">
                        <p>Silahkan baca petunjuk di bawah ini untuk menyelesaikan transaksi anda.</p>
                      </div>

                      <div className="col-md-12">
                        <center>

                          <img
                            width="250"
                            src={instructions.logo}
                            alt=""
                          />

                          <br />

                          <h4 style={{ fontFamily: "Open Sans" }}>
                            {instructions.number}
                            {" "}
                            {/* <b onClick={this.copy.bind(this)} style={{ fontSize: "1em", color: "#3184ae", cursor: "pointer" }}>
                              <i className="fa fa-copy" /> Salin
                            </b> */}
                          </h4>
                          <h4 style={{ fontFamily: "Open Sans" }}>{instructions.name}</h4>

                        </center>

                        <div className="style-2">

                          {
                            instructions.methods
                              ? instructions.methods.map((item, i) => (
                                <div className="toggle-wrap" key={i}>
                                  <span className="trigger ">
                                    <a href="#!">
                                      <b>{item.name}</b>
                                      <i className="sl sl-icon-plus"></i>
                                    </a></span>
                                  <div className="toggle-container clearfix">
                                    {
                                      item.instructions.map((ins, x) => (
                                        <div key={x}>
                                          <table width="100%" style={{ border: "none", margin: "0" }}>
                                            <thead>
                                              <tr>
                                                <td style={{ width: "20px" }}>
                                                  <div style={{ width: "22px", height: "22px", borderRadius: "50%", margin: "2px 0 0 2px", border: "1px solid #577aba", float: "left", marginRight: "20px" }}>
                                                    <div style={{ fontSize: "11px", color: '#577aba', textAlign: "center", lineHeight: "22px" }}>
                                                      {x + 1}
                                                    </div>
                                                  </div>
                                                </td>
                                                <td style={{ textAlign: "left" }}>
                                                  {ins}
                                                </td>
                                              </tr>
                                            </thead>
                                          </table>
                                          <br />
                                        </div>
                                      ))
                                    }
                                  </div>
                                </div>
                              ))
                              : null
                          }

                        </div>
                      </div>

                      <div className="col-md-4">
                        <img
                          width="250"
                          src="https://1.bp.blogspot.com/-whCKrPGUY80/YCK2KdGONrI/AAAAAAAAD34/FD0JXqUg5Fk8fAqgQcqLrTFwTRdSnmDUgCLcBGAsYHQ/s1600/Logo%2BBSI%2B%2528Bank%2BSyariah%2BIndonesia%2529.png"  //"https://upload.wikimedia.org/wikipedia/id/thumb/5/55/BNI_logo.svg/1024px-BNI_logo.svg.png"
                          alt=""
                        />
                      </div>

                      <div className="col-md-6">
                        <strong className="margin-bottom-5">BANK SYARIAH INDONESIA (BSI)</strong>
                        <p>
                          Kode Bank : <b style={{ fontSize: "18px" }}>451</b> <br />
                          No. Rekening :{" "}
                          <b style={{ fontSize: "18px" }}>3033 3033 47</b>
                          <b onClick={this.copy.bind(this)} style={{ fontSize: "1em", color: "#3184ae", cursor: "pointer", marginLeft: "5px" }}>
                            <i className="fa fa-copy" /> Salin
                            </b>
                          <br />
                          Atas nama : YAYASAN SATUKEBAIKAN
                          <br />
                        </p>
                      </div>

                    </div>
                    {/* <hr /> */}
                    <br />
                    <p>
                      Pastikan Anda transfer sebelum{" "}
                      <b>
                        {ringkasan[0] ? dateFormat(tomorrow, "dd mmm yyyy H:MM") : ""} {" "} WITA {" "}
                      </b>
                      atau donasi Anda otomatis dibatalkan oleh tim kami.
                    </p>
                  </div>
                )}
          </div>

          <div id="invoice">
            <div className="row">
              <div className="col-md-12">
                <center>
                  <h3>Happiness is only real when shared!</h3>
                  <span className="margin-top-20">
                    Bantu Campaign <b>{campaign.title}</b> mencapai target
                    donasinya.
                  </span>
                </center>

                <div className="listing-share no-border margin-top-20">
                  <ul className="share-buttons">
                    <li>
                      <FacebookShareButton
                        url={host + "/" + this.props.match.params.pushid}
                      >
                        <a className="fb-share" href="#!">
                          <i className="fa fa-facebook" /> Bagikan ke Facebook
                        </a>
                      </FacebookShareButton>
                    </li>
                    <li>
                      <WhatsappShareButton
                        title={campaign.title}
                        url={host + "/" + this.props.match.params.pushid}
                      >
                        <a
                          style={{ width: "100%", textAlign: "center" }}
                          className="twitter-share"
                          href="#!"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-whatsapp" /> Bagikan ke Whatsapp
                        </a>
                      </WhatsappShareButton>
                    </li>
                  </ul>

                  <div className="clearfix" />
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
        {/*End*/}
      </div>
    );
  }
}

export default Notfound;
