import React, { useState } from "react"
import dateFormat from "dateformat"

export default props => {

    const { data } = props

    const _arr = data.filter(i => i.status.toLowerCase() === "approved")

    const [values, setValues] = useState({ current: 1, todos: 10 })

    const indexOfLastTodo = values.current * values.todos

    const indexOfFirstTodo = indexOfLastTodo - values.todos

    const currentTodos = _arr.slice(indexOfFirstTodo, indexOfLastTodo)

    const increaseTodo = e => {

        setValues({ ...values, todos: values.todos + 10 })
    }

    const normalTodo = e => {

        setValues({ ...values, todos: 10 })

        window.scrollTo(0, document.querySelector("#tabs-reset").offsetTop)
    }

    return (
        <ul>
            {
                currentTodos.map((item, i) => (
                    <div key={i}>
                        {
                            <li key={i} style={{ paddingBottom: "0", borderBottom: "none" }} >
                                <div className="avatar">
                                    <img width="40" src="/images/donatur.png" alt="" />
                                </div>
                                <div className="comment-content" style={{ padding: "0 0 0 50px" }} >
                                    <div className="comment-by clearfix">
                                        <span className="date" style={{ fontSize: "20px", float: "right" }} >
                                            <b style={{ color: "#ff5100" }}>
                                                Rp. {" "} {(item.donation + item.uniqueCode).toLocaleString()}
                                            </b>
                                        </span>
                                        {item.isAnonim ? "Anonim" : item.name}
                                        <span className="date">
                                            {dateFormat(item.date, "dd mmmm yyyy")}
                                        </span>
                                    </div>
                                    <p>{item.description}</p>
                                </div>
                            </li>
                        }
                    </div>
                ))
            }
            <center>
                {
                    _arr.length > values.todos
                        ? (
                            <button onClick={increaseTodo} style={{ borderRadius: "5px" }} className="button border">
                                Tampilkan lebih banyak
                            </button>
                        ) : _arr.length > 10
                            ? (
                                <button onClick={normalTodo} style={{ borderRadius: "5px", background: "#FF5100" }} className="button border">
                                    Tampilkan lebih sedikit
                                </button>
                            ) : null
                }

            </center>
        </ul>
    )
}