import React, { Component } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import izitoast from "izitoast";
import "izitoast/dist/css/iziToast.min.css";

class Navigation extends Component {

  componentDidMount() {

    const pathName = window.location.pathname;

    const dom = document.getElementsByClassName("navigation");

    for (let i = 0; i < dom.length; i++) {

      if (dom[i].firstElementChild.getAttribute("href") === pathName) {

        dom[i].firstElementChild.setAttribute("class", "current")

        break
      }
    }

    const navbar = document.getElementById("header-container")

    window.onscroll = () => {

      const path = window.location.pathname === "/" ? true : false

      if (window.pageYOffset > 0) {

        navbar.classList.remove(path ? "not-fixed" : "relative")

        navbar.classList.add("fixed")
      } else {

        navbar.classList.remove("fixed")

        navbar.classList.add(path ? "not-fixed" : "relative")
      }
    }
  }

  logout(e) {
    e.preventDefault();

    const image = this.props.auth.picture

    izitoast.info({
      timeout: 20000,
      close: true,
      overlay: true,
      image : image,
      imageWidth : 100,
      icon: "fa fa-question",
      title: "Halo baikers (orang baik)",
      message: "Keluar Sekarang?",
      position: "center",
      backgroundColor: "rgb(35, 203, 245)",
      titleColor: "#fff",
      color: "#fff",
      iconColor: "#fff",
      messageColor: "#fff",
      layout: 1,
      balloon: true,
      transitionIn: "bounceInLeft",
      buttons: [
        [
          "<button class='button'>ya, keluar sekarang!</button>",
          () => { firebase.auth().signOut().then(() => { window.location.reload() }) } , true
        ]
      ]
    })
  }

  formSearchDispaly(e) {
    const el = document.querySelector('#form-search-bar')

    el.style.visibility = "visible";
    el.style.opacity = "1"
  }

  formSearchClose() {
    const el = document.querySelector('#form-search-bar')

    el.style.opacity = "0"
    el.style.visibility = "hidden"
  }

  render() {

    const { picture } = this.props.auth

    const { pathname } = window.location

    return (
      <div>

        <header id="header-container" className={pathname === "/" ? "not-fixed" : "relative"}>

          <div id="form-search-bar" className="search-form-display clearfix">
            <div className="row">
              <div className="container" style={{ display: "relative" }} >
                <div className="col-md-12">
                  <form method="get" action="/explorer/all/" className="clearfix" >
                    <div className="input-with-icon medium-icons">
                      <input
                        id="autocomplete-input"
                        type="text"
                        name="keyword"
                        placeholder="Cari campaign yang ingin dibantu"
                      />
                      <i id="cloud" className="im im-icon-Search-onCloud" />
                    </div>
                    <i onClick={this.formSearchClose} className="im im-icon-Close-Window close" />
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div id="header" className="not-sticky">
            <div className="container">
              <div className="left-side">
                <div id="logo">
                  <a href="/">
                    <img
                      style={{ width: "150px" }}
                      src="/images/logo-a.png"
                      alt="satukebaikan"
                    />
                  </a>
                </div>

                <div
                  style={{ background: "transparent" }}
                  className="mmenu-trigger"
                >
                  <button
                    className="hamburger hamburger--collapse"
                    type="button"
                  >
                    <span className="hamburger-box">
                      <span className="hamburger-inner" />
                    </span>
                  </button>
                </div>

                <nav id="navigation" className="style-1">
                  <ul id="responsive">
                    <li className="navigation">
                      <a href="/explorer/all">Donasi</a>
                    </li>
                    <li className="navigation">
                      <a href="/dashboard/galang-dana">Galang Dana</a>
                    </li>
                    <li className="navigation">
                      <a href="/satukebaikan">#Satukebaikan</a>
                    </li>
                  </ul>
                </nav>
                <div className="clearfix" />
              </div>

              <div className="right-side">
                <div className="header-widget">
                  {picture ? (
                    <div className="user-menu">
                      <div className="user-name">
                        <span style={{ width: "40px", height: "40px" }}>
                          <img
                            width="100%"
                            height="100%"
                            src={picture}
                            alt=""
                          />
                        </span>
                        {/* Hi,{" "}
                        {
                          JSON.parse(localStorage.getItem("accessToken"))
                            .displayName
                        } */}
                      </div>
                      <ul style={{ padding: "0" }}>

                        <li style={{ borderBottom: "1px solid #f7f7f7" }}>
                          <a href="/dashboard/overview" style={{ fontSize: "11px", fontFamily: "Open Sans", fontWeight: "700", color: "#bbb" }}>
                            OVERVIEW
                          </a>
                        </li>

                        <li style={{ borderBottom: "1px solid #f7f7f7" }}>
                          <a href="/dashboard/donasiku" style={{ fontSize: "11px", fontFamily: "Open Sans", fontWeight: "700", color: "#bbb" }}>
                            DONASIKU
                          </a>
                        </li>

                        <li style={{ borderBottom: "1px solid #f7f7f7" }}>
                          <a href="/dashboard/profil" style={{ fontSize: "11px", fontFamily: "Open Sans", fontWeight: "700", color: "#bbb" }}>
                            PROFIL
                          </a>
                        </li>

                        <li>
                          <a id="logout" href="#!" onClick={this.logout.bind(this)} style={{ fontSize: "11px", fontFamily: "Open Sans", fontWeight: "700", color: "#bbb" }}>
                            KELUAR
                          </a>
                        </li>

                      </ul>
                    </div>
                  ) : (
                      <a
                        href={"/dashboard/login?" + pathname}
                        className="sign-in"
                      >
                        <i className="sl sl-icon-login" /> Masuk
                    </a>
                    )}
                  <span className="search-bar" onClick={this.formSearchDispaly.bind(this)}><i className="im im-icon-Search-onCloud" /> <span style={{ fontSize: "16px", verticalAlign: "top" }}>Cari</span></span>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="clearfix" />
      </div>
    );
  }
}

export default Navigation;
