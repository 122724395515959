import React, { Component } from "react"
import firebase from "firebase/app"
import "firebase/database"
import "firebase/storage"

import Dropzone from "react-dropzone"
import iziToast from "izitoast"
import Edotensei from "edotensei"

import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import uploder from "./adapter"

import Navbar from "../includes/admin-components/admin-navigations"
import Sidebar from "../includes/admin-components/admin-sidebar"
import Regency from "../non-admin/explorer-all-components/regency"

import "./css/datedropper.css"
// import "jodit/build/jodit.min.css"

const { add } = Edotensei;

const Scripts = [{ src: "/scripts/jquery-2.2.0.min.js", rel: "preload" }, { src: "/scripts/mmenu.min.js", defer: true }, { src: "/scripts/chosen.min.js", defer: true }, { src: "/scripts/slick.min.js", defer: true }, { src: "/scripts/rangeslider.min.js", defer: true }, { src: "/scripts/magnific-popup.min.js", defer: true }, { src: "/scripts/waypoints.min.js", defer: true }, { src: "/scripts/counterup.min.js", defer: true }, { src: "/scripts/tooltips.min.js", defer: true }, { src: "/scripts/custom.js", defer: true }]

/**
 * query database
 */
const readData = (pathname, callback) => {
  firebase
    .database()
    .ref()
    .child(pathname)
    .on("value", snap => {
      callback(snap.val());
    });
};

/**
 * update data
 */
const updateData = (data, key) => {
  firebase
    .database()
    .ref("/campaign/" + key)
    .update(data, err => {

      if (err) return alert(err.message);

      iziToast.success({
        title: "Hi Baikers (orang baik)",
        message: "Campaign berhasil diperbarui!",
        position: "center",
        overlay: true,
        drag: false,
        timeout: "2000",
        onClosing: () => {

          window.location.href = "/dashboard/kampanye-berjalan";
        }
      })
    });
};

class campaignEditor extends Component {
  /**
   * constructor
   */
  constructor(props) {
    super(props);

    /**
     * set default state
     */
    this.state = {
      /**
       * json function
       */
      category: [{ title: "Loading ..." }],
      subCategory: [],
      isUploading: false,
      progress: 0,
      isLoading: false,
      isLoaded: false,
      /**
       * json for add to database
       */
      title: "Loading ...",
      donationTarget: "",
      deadline: "",
      tag: "",
      location: "Loading ...",
      image: undefined,
      shortDescription: "Loading ...",
      content: "Loading ..." //description
    };

    /**
     * bind this input handler
     */
    this.inputHandler = this.inputHandler.bind(this);
  }

  /**
   * di eksekusi ketika component belum dirender
   */
  componentWillMount() {
    this.setState({ isLoading: true });

    const key = this.props.match.params.id;

    readData("/campaign/" + key, snap => {
      /**
       * update state data
       */
      this.setState({
        isLoaded: true,
        isLoading: false,
        title: snap.title,
        donationTarget: parseInt(snap.target, 0).toLocaleString(),
        tag: snap.category,
        deadline: snap.deadline,
        location: snap.location,
        image: { preview: snap.image },
        shortDescription: snap.shortDescription,
        content: snap.description
      });


      this.forceUpdate();
    });

    this.loaded = true

    readData("/tags", value => {

      !this.isCancelled && this.setState({ category: value });

      this.loaded = false

      setTimeout(() => {
        add(Scripts);
      }, 1000)

    });
  }

  componentDidMount() {

    add([{ src: "/scripts/jquery-2.2.0.min.js", rel: "preload" }, { src: "/scripts/datedropper.js", defer: true }, { src: "/scripts/datedropperEx.js", defer: true }]);
  }

  /**
   * image handler
   * @param image
   */
  imageHandler(image) {
    var fileUpload = image[0];

    /**
     * update state
     * image preview loading
     */
    this.setState({ isUploading: true, image: undefined });
    /**
     * fungsi untuk mengupdate state
     * @param percent
     */
    const setProgress = percent => {
      this.setState({ progress: percent });
    };
    /**
     * fungsi untuk mengupdate state
     * @param url
     */
    const setImageURl = url => {
      this.setState({ image: { preview: url }, isUploading: false });
    };

    /**
     * Create a root reference
     * @type {firebase.storage.Reference}
     */
    let storageRef = firebase.storage().ref();
    /**
     *  Create a reference to 'campaign/image_name'
     * @type {firebase.storage.Reference}
     */
    let campaignImageRef = storageRef.child(
      "campaign/" + fileUpload.lastModified + new Date() + ".jpg"
    );
    /**
     * Create the file metadata
     * @type {{contentType: string}}
     */
    let metadata = {
      contentType: "image/jpeg"
    };
    /**
     * Upload file and metadata to the object 'campaign/image_name'
     * @type {firebase.storage.UploadTask}
     */
    let uploadTask = campaignImageRef.put(fileUpload, metadata);
    /**
     * Listen for state changes, errors, and completion of the upload.
     * or 'state_changed'
     */
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      snapshot => {
        /**
         * Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
         */
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        /**
         * call set progress function
         */
        setProgress(Math.ceil(progress));
      },
      /**
       * error handler
       * @param error
       */
      error => {
        /**
         * A full list of error codes is available at
         * https://firebase.google.com/docs/storage/web/handle-errors
         */
        alert(error.code);
      },
      () => {
        /**
         * Upload completed successfully, now we can get the download URL
         */
        uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          /**
           * set image url
           */
          setImageURl(downloadURL);
        });
      }
    );
  }

  updateContent(event, editor) {

    const value = editor.getData()

    this.setState({ content: value })
  }

  /**
   * input handler
   * @param e
   */
  inputHandler(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  /**
   * format currancy
   * @param {event} e
   */
  formateCurrancy(e) {
    let { value } = e.target;
    /**
     * delete char , or .
     */
    value = value.replace(new RegExp(",", "g"), "");
    value = value.replace(/\./g, "");
    /**
     * parse to string
     * @type {string}
     */
    value = parseInt(value, 0);
    /**
     * apakah valuenya NAN?
     */
    if (isNaN(value)) {
      /**
       * update state
       */
      this.setState({ donationTarget: "" });
      return;
    }

    let valueToLocale = value.toLocaleString();
    // console.log(valueToLocale);
    /**
     * update state
     */
    this.setState({
      donationTarget: valueToLocale.toString()
    });
  }

  submitHandler(e) {

    e.preventDefault();

    if (!this.state.image) return iziToast.error({ title: "Informasi belum lengkap", message: "Gambar utama tidak ditemukan, silahkan menggungah gambar utama anda!", position: "topRight" })

    if (!this.state.content) return iziToast.error({ title: "Informasi belum lengkap", message: "Deskripsi tidak ditemukan, silahkan menggungah gambar utama anda!", position: "topRight" })

    const target = parseInt(this.state.donationTarget.toString().replace(/,/gi, ""), 0)

    if (target < 1000000 || isNaN(target)) return iziToast.error({ title: "Informasi belum lengkap", message: "Target donasi minal Rp1.000.000", position: "topRight" })

    // const { email, name, picture, uid } = this.props.auth.data

    const data = {
      title: this.state.title,
      target: target,
      category: document.querySelector("#tags").value,
      deadline: document.querySelector("#deadline").value,
      location: document.querySelector("#location").value,
      image: this.state.image.preview,
      shortDescription: this.state.shortDescription,
      description: this.state.content,
      date: firebase.database.ServerValue.TIMESTAMP
      // owner: email,
      // ownerName: name,
      // ownerPhoto: picture,
      // uid: uid
    }

    iziToast.info({
      timeout: 20000,
      close: true,
      overlay: true,
      zindex: 999,
      title: "Halo..",
      icon: "fa fa-question-circle",
      message: "Apakah informasi yang anda masukkan sudah benar?",
      position: "center",
      buttons: [
        [
          "<button class='button'>Ya, sudah benar!</button>",
          () => {

            iziToast.destroy();

            updateData(data, this.props.match.params.id);
          },
          true
        ]
      ]
    });


  }

  upload(editor) {

    const toolbar = document.querySelector('.ck-toolbar')

    toolbar.style = "background : white"

    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      // Configure the URL to the upload script in your back-end here!
      return new uploder(loader);
    }
  }

  render() {

    const {
      title,
      donationTarget,
      category,
      deadline,
      location,
      progress,
      isUploading
    } = this.state

    const { picture, uid, name } = this.props.auth.data

    return (
      <div>
        {/* Load Navigation html Syntax */}
        <Navbar picture={picture} name={name} />
        {/*End*/}

        <div id="dashboard">
          {/* Sidebar*/}
          <Sidebar uid={uid} />
          {/* End Sidebar*/}

          {/*Content*/}
          <div className="dashboard-content">
            <div id="titlebar">
              <div className="row">
                <div className="col-md-12">
                  <h2>Edit kampanye</h2>
                  <nav id="breadcrumbs">
                    <ul>
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li>
                        <a href="/dashboard/kampanye-berjalan">Kampanyeku</a>
                      </li>
                      <li>Edit kampanye</li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div id="add-listing">
                  <form
                    hidden={!this.state.isLoaded}
                    onSubmit={this.submitHandler.bind(this)}
                  >
                    <div className="add-listing-section">
                      <div className="add-listing-headline">
                        <h3>
                          <i className="sl sl-icon-doc" /> Informasi Kampanye
                        </h3>
                      </div>

                      <div className="row with-forms">
                        <div className="col-md-6">
                          <h5>
                            Judul Kampanye{" "}
                            <i
                              className="tip"
                              data-tip-content="Judul Kampanye yang ingin dibuat"
                            />
                          </h5>
                          <input
                            className="search-field"
                            type="text"
                            onChange={this.inputHandler}
                            name="title"
                            value={title}
                            required
                          />
                        </div>

                        <div className="col-md-6">
                          <div className="input-with-icon medium-icons">
                            <h5>Target donasi </h5>
                            <input
                              style={{ fontSize: "24px" }}
                              className="search-field"
                              type="text"
                              onChange={this.formateCurrancy.bind(this)}
                              value={donationTarget}
                              name="donationTarget"
                              required
                            />
                            <i style={{ fontStyle: "normal" }}>
                              <b>Rp</b>
                            </i>
                          </div>
                        </div>
                      </div>

                      <div className="row with-forms">
                        <div className="col-md-6">
                          <h5>Kategori</h5>
                          <select
                            value={this.state.tag}
                            onChange={e => this.setState({ tag: e.target.value })}
                            className="chosen-select"
                            id="tags"
                            required
                          >
                            {Object.values(category).map((item, i) => (

                              <option key={i}>
                                {item.title}
                              </option>

                            ))}
                          </select>
                        </div>
                        <div className="col-md-6">
                          <h5>Deadline Kampanye </h5>
                          <input
                            type="text"
                            id="deadline"
                            data-lang="en"
                            data-large-mode="true"
                            data-large-default="true"
                            data-min-year="2017"
                            data-max-year="2040"
                            data-format="d F Y"
                            data-default-date={deadline}
                            value={deadline}
                            onChange={e => { }}
                            required
                          />
                        </div>
                      </div>

                      <div className="row with-forms">
                        <div className="col-md-6">
                          <h5>Lokasi penerima dana</h5>
                          <select
                            value={location}
                            onChange={e => { }}
                            className="chosen-select"
                            id="location"
                            required
                          >
                            {Regency().map((item, i) => (
                              <option key={i}>{item}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* content */}
                    <div className="row margin-top-30">
                      <div className="col-lg-12">
                        <div id="add-listing">
                          <div className="add-listing-section">
                            <div className="add-listing-headline">
                              <h3>
                                <i className="sl sl-icon-docs" /> Cerita
                                Kampanye
                              </h3>
                            </div>

                            <div className="row with-forms">
                              <div className="col-md-12">
                                <h5>
                                  Gambar Utama{" "}
                                  <i
                                    className="tip"
                                    data-tip-content="Rekomendasi Ukuran: 664px X 357px. Format : PNG/ JPG/ JPEG"
                                  />
                                </h5>
                                <Dropzone
                                  className="dropzone"
                                  style={{ cursor: "pointer" }}
                                  onDrop={this.imageHandler.bind(this)}
                                  accept="image/jpeg,image/jpg,image/tiff,image/png,image/gif"
                                  multiple={false}
                                  disabled={isUploading}
                                >
                                  <div
                                    style={{
                                      cursor: "pointer",
                                      textAlign: "center"
                                    }}
                                  >

                                    <div
                                      hidden={!isUploading}
                                      className="progress-bar"
                                      style={{ overflow: "hidden", position: "absolute", left: "0", bottom: "0" }}
                                    >
                                      <span
                                        className="progress-bar-fill"
                                        style={{ width: `${progress}%` }}
                                      />
                                    </div>

                                    <img
                                      width="50%"
                                      src={
                                        this.state.image
                                          ? this.state.image.preview
                                          : null
                                      }
                                      alt=""
                                    />
                                  </div>
                                </Dropzone>
                              </div>

                              <div className="col-md-12">
                                <h5 style={{ paddingBottom: "7px" }}>
                                  Deskripsi Singkat
                                </h5>
                                <textarea
                                  value={this.state.shortDescription}
                                  name="shortDescription"
                                  onChange={this.inputHandler}
                                />
                              </div>

                              <div className="col-md-12">
                                <h5 style={{ paddingBottom: "7px" }}>
                                  Deskripsi
                                </h5>

                                <CKEditor
                                  data={this.state.content}
                                  onInit={this.upload}
                                  config={{
                                    toolbar: ['imageUpload', 'link', '|', 'heading', '|', 'bold', 'italic', '|', 'undo', 'redo',]
                                  }}
                                  editor={ClassicEditor}
                                  onChange={this.updateContent.bind(this)}
                                />

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="button preview right"
                      style={{ float: "right" }}
                      disabled={this.state.isLoading}
                    >
                      Perbarui Kampanye <i className="fa fa-arrow-circle-up" />
                    </button>
                  </form>
                  <div className="col-md-12">
                    {/** Loader animation */}
                    <center hidden={this.state.isLoaded}>
                      <br />
                      <br />
                      <div className="lds-dual-ring" />
                      <br />
                      <br />
                      <br />
                    </center>
                    <div className="copyrights">
                      © 2018 Satukebaikan indonesia. All Rights Reserved.
                    </div>
                  </div>

                  {/*End*/}
                </div>
              </div>
            </div>
          </div>
          {/*End*/}
        </div>
      </div>
    );
  }
}

export default campaignEditor;
