import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <div>
        <div id="footer" className="sticky-footer">
          <div className="container">
            <div className="row">
              <div className="col-md-5 col-sm-12">
                <img className="footer-logo" src="/images/logo-a.png" alt="" />
                <br />
                <br />
                {/* <p>
                  Satukebaikan.com adalah wadah Orang Baik (BAIKers) untuk menggalang dana dan berdonasi secara online dan transparan.
                </p> */}

                <div className="text-widget">
                  <span>
                    Blok F 8, Jl. Pengayoman No.8, Bua Kana, Rappocini, Kota
                    Makassar, Sulawesi Selatan
                  </span>{" "}
                  <br />
                  E-Mail:
                  <span>
                    {" "}
                    <a href="#!">baikers.squad@gmail.com</a>{" "}
                  </span>
                  <br />
                </div>
                <br/>
                <p>
                  Pengesahan Pendirian Badan Hukum Yayasan Satukebaikan <br /> <span style={{ color: "rgb(35, 203, 245)" }}> Nomor AHU-0000998.AH.01.04. Tahun 2019 </span>
                </p>
              </div>
              <div className="col-md-2">
                <h4><b>Program</b></h4>
                <ul
                  style={{
                    width: "100%",
                    listStyle: "none",
                    paddingLeft: "0",
                    lineHeight: "30px"
                  }}
                >
                  <li>
                    <a style={{ color: "#909090", fontFamily: "Open Sans" }} href="/explorer/all">Donasi</a>
                  </li>
                  <li>
                    <a style={{ color: "#909090", fontFamily: "Open Sans" }} href="/info/syarat-dan-ketentuan">Infaq</a>
                  </li>
                  <li>
                    <a style={{ color: "#909090", fontFamily: "Open Sans" }} href="/info/syarat-dan-ketentuan">Program Kreatif</a>
                  </li>
                  <li>
                    <a style={{ color: "#909090", fontFamily: "Open Sans" }} href="/info/syarat-dan-ketentuan">Cerita Kebaikan</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-2">
                <h4><b>Satukebaikan</b></h4>
                <ul style={{
                  width: "100%",
                  listStyle: "none",
                  paddingLeft: "0",
                  lineHeight: "30px"
                }}
                >
                  <li>
                    <a style={{ color: "#909090", fontFamily: "Open Sans" }} href="/info/tim-kami">Tim Kami</a>
                  </li>
                  <li>
                    <a style={{ color: "#909090", fontFamily: "Open Sans" }} href="/info/sahabat">Sahabat</a>
                  </li>
                  <li>
                    <a href="#!" style={{ color: "#909090", fontFamily: "Open Sans" }}
                      onClick={e => {

                        e.preventDefault()

                        window.open("https://docs.google.com/forms/d/e/1FAIpQLSfwOOCAYMRTDWimVQ3gAOT0kDBCo1QS6lZZDW3v2EpobRCd0Q/viewform")
                      }}
                    >Ikut gabung</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-3">
                <h4><b>Info kebaikan</b></h4>
                <ul
                  style={{
                    width: "100%",
                    listStyle: "none",
                    paddingLeft: "0",
                    lineHeight: "30px"
                  }}
                >
                  <li>
                    <a style={{ color: "#909090", fontFamily: "Open Sans" }} href="/info/tentang-kami">Satukebaikan?</a>
                  </li>
                  <li>
                    <a style={{ color: "#909090", fontFamily: "Open Sans" }} href="/info/cara-donasi">Cara Donasi</a>
                  </li>
                  <li>
                    <a style={{ color: "#909090", fontFamily: "Open Sans" }} href="/info/faq">FAQ</a>
                  </li>
                  <li>
                    <a style={{ color: "#909090", fontFamily: "Open Sans" }} href="/info/syarat-dan-ketentuan">Syarat & Ketentuan</a>
                  </li>
                  <li>
                    <a style={{ color: "#909090", fontFamily: "Open Sans" }} href="/info/kebijakan-privasi">Kebijakan privasi</a>
                  </li>
                </ul>
              </div>{" "}
            </div>

            <div>
              <ul className="share-buttons margin-top-40 margin-bottom-0">
                <li>
                  <span>
                    Tamukan kami di: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  </span>
                </li>
                <li>
                  <a
                    style={{ color: "#3b5998", background: "#fff" }}
                    id="fb-share"
                    className="fb-share"
                    href="https://www.facebook.com/SatuKebaikan.id/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i style={{ fontSize: "2em" }} className="fa fa-facebook" />
                  </a>
                </li>
                <li>
                  <a
                    style={{ background: "#fff", color: "#25d366" }}
                    className="twitter-share"
                    href="https://wa.me/6282290452354"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i style={{ fontSize: "2em" }} className="fa fa-whatsapp" />
                  </a>
                </li>
                <li>
                  <a
                    style={{ background: "#fff", color: "#e1306c", borderColor: "#fff" }}
                    className="gplus-share"
                    href="https://www.instagram.com/satukebaikan.id/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i style={{ fontSize: "2em" }} className="fa fa-instagram" />
                  </a>
                </li>
              </ul>
              <div className="clearfix" />
            </div>

            <div className="row">
              <div className="col-md-12">
                <div style={{ marginTop: "10px" }} className="copyrights">
                  © 2018 Satukebaikan Indonesia. All Rights Reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
