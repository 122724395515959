import React, { Component } from "react";

class Placeholder extends Component {
  render() {
    return (
      <center>
        <div>
          <br />
          <br />
          <br />
          <div className="lds-facebook">
            <div />
            <div />
            <div />
          </div>
        </div>
      </center>
    );
  }
}

export default Placeholder;
