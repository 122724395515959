import React, { Component } from "react"
import firebase from "firebase/app"
import "firebase/database"
import "firebase/functions"
import dateFormat from "dateformat"
import ReactHtmlParser from "react-html-parser"
import ReactGA from 'react-ga'

import Donor from "./single-page-components/donor-list"
import Fundraiser from "./single-page-components/fundraiser-list"

// import Footer from "../includes/non-admin-components/footer"
import Navigation from "../includes/non-admin-components/navigation"

// import Placeholder from "./Components/placeholder";
// import MetaTags from "react-meta-tags";
import {
  FacebookShareCount,
  FacebookShareButton,
  WhatsappShareButton
} from "react-share"

import Edotensei from "edotensei";

const { add } = Edotensei;

const Scripts = [{ src: "/scripts/jquery-2.2.0.min.js", rel: "preload" }, { src: "/scripts/mmenu.min.js", defer: true }, { src: "/scripts/chosen.min.js", defer: true }, { src: "/scripts/slick.min.js", defer: true }, { src: "/scripts/rangeslider.min.js", defer: true }, { src: "/scripts/magnific-popup.min.js", defer: true }, { src: "/scripts/waypoints.min.js", defer: true }, { src: "/scripts/counterup.min.js", defer: true }, { src: "/scripts/tooltips.min.js", defer: true }, { src: "/scripts/custom.js", defer: true }]


const totalDonasi = arr => {

  let value = 0

  arr = arr.map(i => i.status.toLowerCase() === "approved" ? i : null)

  for (let i in arr) {

    if (arr[i]) {

      value += (arr[i].donation + arr[i].uniqueCode)
    }
  }

  return value
}

const totalDonatur = arr => {

  let value = 0

  arr = arr.map(i => i.status.toLowerCase() === "approved" ? i : null)

  for (let i in arr) {

    if (arr[i]) {

      value++
    }
  }

  return value
}

const parseFirebaseData = obj => {

  Object.keys(obj).map(i => obj[i].key = i)

  const result = Object.values(obj).filter(i => {

    let donation = i.contribute ? Object.values(i.contribute).map(con => con.status.toLowerCase() === "approved" ? con.donation + con.uniqueCode : null) : []

    donation = donation.filter(donate => donate)

    i.totalContribute = donation.length

    i.totalDonation = donation.reduce((a, b) => a + b, 0)

    return i
  })

  obj = result

  return obj
}

const fundraiser = obj => {

  const value = parseFirebaseData(obj)

  return { total: value.length, data: value }
}

const totalDonationOfFundraiser = arr => {

  arr = arr.map(i => i.totalDonation)

  return arr.reduce((a, b) => a + b, 0)
}

const shareUrl = window.location.href;

class SinglePage extends Component {
  constructor() {
    super();
    /**
     * set state
     */
    this.state = {
      campaign: {},
      loadMore: 20,
      isLoaded: false,
      widthScreen: 0,
      fundraiser: {}
    };
  }

  async componentDidMount() {

    const { id } = this.props.match.params

    this.shareCountUrl = `https://satukebaikan.com/${id}`

    const path = `/campaign/${id}`

    try {

      const snap = await firebase.functions().httpsCallable("getCampaign")({ path })

      const snapFundraiser = await firebase.database().ref("campaign").orderByChild("parentCampaign").equalTo(id).once("value")

      this.setState({ campaign: snap.data, fundraiser: snapFundraiser.exists() ? snapFundraiser.val() : {}, isLoaded: true })

    } catch (error) {

      window.location.href = "/not-found/404"
    }

    add(Scripts)

    // scroll up function
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0

    //detect resolution
    this.setState({ widthScreen: window.screen.availWidth })

    try {

      const tabsnav = document.getElementById("tabs-nav");

      const button = document.getElementById("button-wrapper")

      //trigger when window scroll
      window.addEventListener("scroll", () => {

        this.setState({ widthScreen: window.screen.availWidth })

        if (tabsnav) {

          if (window.pageYOffset >= tabsnav.offsetTop) {

            if (button) button.classList.add("wrapper-button-donation")

          } else {

            if (button) button.classList.remove("wrapper-button-donation")
          }
        }

      })

    } catch (error) {

    }

    ReactGA.pageview(id)
  }

  loadMoreHandler(x) {

    this.setState({
      loadMore: x + 10
    });
  }

  render() {

    const { campaign, isLoaded } = this.state

    const { id } = this.props.match.params

    const { name, picture } = this.props.auth.data || { name: undefined, picture: undefined }

    return (
      <div>

        <Navigation auth={{ name, picture }} />

        {
          !isLoaded
            ?
            (
              <center className="vertical-center">
                <div className="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <br />
                <br />
                <img style={{ width: "200px" }} src="/images/logo-a.png" alt="satukebaikan" />
              </center>
            )
            :
            (
              <div hidden={!isLoaded}>
                {/* Content */}
                <div className="container">
                  <div
                    className="row sticky-wrapper"
                    style={{ position: "relative" }}
                  >
                    {/* col-lg-8 col-md-8 padding-right-30 */}
                    <div className="col-lg-8 col-md-8 padding-right-30 margin-top-40">
                      <img
                        id="main-image"
                        src={campaign.image}
                        alt={campaign.title}
                      />

                      {/* Title Bar */}
                      <div
                        id="titlebar"
                        className="listing-titlebar"
                        style={{ padding: "10px 0" }}
                      >
                        <div className="listing-titlebar-title">
                          <h3 style={{ fontFamily: "Open Sans", fontSize: "18px" }}>{campaign.title || ""} </h3>

                          <span
                            style={{
                              marginLeft: "0",
                              borderColor: "#ff5100",
                              color: "#ff5100"
                            }}
                            className="listing-tag"
                          >
                            <b>
                              {campaign.category || ""}
                            </b>
                          </span>
                          <br />
                          <span style={{ color: "rgb(35, 203, 245)", fontSize: "15px" }}>
                            <i className="fa fa-map-marker" />
                            &nbsp; {campaign.location || ""}
                          </span>
                          <p style={{ fontSize: "16px" }} >
                            {campaign.shortDescription || ""}
                          </p>
                        </div>
                      </div>
                      {/* End title bar */}

                      {/* sidebar mobile */}
                      {this.state.widthScreen <= 991 ? (
                        <div
                          className="mobile-sticky col-sm-12 margin-top-10 margin-bottom-10 sticky"
                        >
                          {/* Donasi */}
                          <div
                            className="boxed-widget booking-widget"
                            style={{ paddingTop: "5px" }}
                          >
                            <br />
                            <div className="clearfix">
                              <a href={`/orangbaik/${campaign.uid}`} >
                                <table width="100%" style={{ marginBottom: "0", border: "none" }} >
                                  <thead>
                                    <tr>
                                      <td style={{ width: "20%" }}>
                                        <img width="60" height="60" style={{ borderRadius: "50%", border: "1px solid #bbb", padding: "5px" }} src={campaign.ownerPhoto} alt={campaign.ownerPhoto} />
                                      </td>
                                      <td style={{ textAlign: "left", paddingLeft: "10px", lineHeight: "20px" }}>
                                        <b style={{ fontSize: "13px" }}>{campaign.ownerName}</b>
                                      </td>
                                    </tr>
                                  </thead>
                                </table>
                              </a>
                            </div>

                            <hr />

                            <h2
                              style={{ fontSize: "25px", fontFamily: "Opens Sans" }}
                            >
                              <span style={{ color: "#23cbf5", fontFamily: "Open Sans" }}>
                                Rp.{" "}
                                {campaign.contribute
                                  ? (totalDonasi(Object.values(campaign.contribute || {})) + totalDonationOfFundraiser(fundraiser(this.state.fundraiser).data)).toLocaleString()
                                  : 0}
                              </span>
                            </h2>

                            <div
                              className="progress-bar"
                              style={{ overflow: "hidden" }}
                            >
                              <span
                                className="progress-bar-fill"
                                style={{
                                  width: campaign.target
                                    ? Math.ceil(((totalDonasi(Object.values(campaign.contribute || {})) + totalDonationOfFundraiser(fundraiser(this.state.fundraiser).data)) / parseInt(campaign.target, 0)) * 100) + "%"
                                    : 0 + "%"
                                }}
                              />
                            </div>

                            <h5 style={{ fontSize: "12px", float: "left", fontFamily: "Open Sans" }}>
                              {isNaN(parseInt(campaign.target, 0))
                                ? ""
                                : Math.ceil(((totalDonasi(Object.values(campaign.contribute || {})) + totalDonationOfFundraiser(fundraiser(this.state.fundraiser).data)) / parseInt(campaign.target, 0)) * 100)}
                              % Terkumpul
                        </h5>
                            <h5 style={{ fontSize: "12px", float: "right", fontFamily: "Open Sans" }}>
                              {Date.parse(new Date(campaign.deadline)) < Date.parse(new Date())
                                ? "Selesai"
                                : Math.floor((Date.parse(campaign.deadline) - Date.parse(new Date())) / (1000 * 60 * 60 * 24) + 1) + " Hari lagi"}{" "}
                            </h5>
                            <br />
                            <h4 style={{ fontSize: "12px" }}>
                              Dari total target{" "}
                              <span style={{ color: "#23cbf5", fontFamily: "Open Sans" }}>
                                {" "}
                                Rp.{" "}
                                {isNaN(parseInt(campaign.target, 0))
                                  ? ""
                                  : parseInt(campaign.target, 0).toLocaleString()}
                              </span>
                            </h4>
                            <hr />
                            <div>
                              {
                                Date.parse(new Date(campaign.deadline)) < Date.parse(new Date())
                                  ? (
                                    <button
                                      disabled
                                      className="button border fullwidth margin-top-5"
                                      style={{ cursor: "default", borderRadius: "5px", background: "#fff", borderColor: "#ff5100", color: "#ff5100" }}>
                                      Kampanye Telah berakhir
                                    </button>
                                  ) : (
                                    <a
                                      href="#!"
                                      className="button border fullwidth margin-top-5"
                                      style={{ borderRadius: "5px", background: "#ff5100", borderColor: "#ff5100", color: "#fff" }}
                                      onClick={e => {

                                        e.preventDefault()

                                        ReactGA.event({
                                          category: "donasi",
                                          action: "berdonasi"
                                        })

                                        window.location.href = "/kontribusi/" + id

                                      }}
                                    >
                                      Donasi Sekarang
                                    </a>
                                  )
                              }
                            </div>
                            {/* Share */}
                            <br />
                            <h3
                              style={{
                                fontSize: "15px",
                                padding: "20px 0 5px",
                                borderTop: "1px solid #e8e8e8",
                                borderBottom: "0",
                                marginBottom: "5px"
                              }}
                            >
                              Bantu sebarkan kebaikan
                          </h3>
                            <ul
                              style={{ width: "100%" }}
                              className="share-buttons margin-top-10 margin-bottom-0"
                            >
                              <li style={{ width: "47%" }}>
                                <FacebookShareButton
                                  url={shareUrl}
                                  quote={`${campaign.shortDescription} mari bantu kampanye ini dengan melanjutkan atau membagikan pada status dan group whatsapp, halaman facebook anda, semoga mendapat amal jariyah.`}
                                  hashtag="#baikers"
                                >
                                  <a
                                    style={{ width: "100%", background: "#fff", color: "#3b5998", borderRadius: "5px", borderColor: "#3b5998", textAlign: "center" }}
                                    className="fullwidth fb-share"
                                    href="#!"
                                    onClick={e => {

                                      e.preventDefault()

                                      ReactGA.event({
                                        category: "bagikan",
                                        action: "bagikan ke facebook"
                                      })
                                    }}
                                  >
                                    <FacebookShareCount align="center" url={this.shareCountUrl}>
                                      {shareCount => (
                                        <span>
                                          <i className="fa fa-facebook" />
                                          {shareCount} {" "} Bagikan
                                  </span>
                                      )}
                                    </FacebookShareCount>
                                  </a>
                                </FacebookShareButton>
                              </li>
                              <li style={{ width: "47%" }}>
                                <WhatsappShareButton
                                  title={`${campaign.shortDescription} mari bantu kampanye ini dengan melanjutkan atau membagikan pada status dan group whatsapp, halaman facebook anda, semoga mendapat amal jariyah.`}
                                  url={shareUrl}
                                >
                                  <a
                                    style={{ width: "100%", background: "#fff", color: "#25d366", borderRadius: "5px", borderColor: "#25d366", textAlign: "center" }}
                                    className="twitter-share"
                                    href="#!"
                                    onClick={e => {

                                      e.preventDefault()

                                      ReactGA.event({
                                        category: "bagikan",
                                        action: "bagikan ke whatsapp"
                                      })

                                    }}
                                  >
                                    <i className="fa fa-whatsapp" /> WA
                                  </a>
                                </WhatsappShareButton>
                              </li>
                            </ul>

                            {
                              Date.parse(new Date(campaign.deadline)) < Date.parse(new Date())
                                ? null
                                : (
                                  <div>
                                    <p style={{ fontSize: "12px", margin: "0", lineHeight: "15px", marginBottom: "10px", marginTop: "5px" }}>Bantu kampanye ini dengan menjadi penggalang dana</p>
                                    <a
                                      style={{ width: "100%", background: "#fff", color: "rgb(35, 203, 245)", borderRadius: "5px", borderColor: "rgb(35, 203, 245)", textAlign: "center" }}
                                      className="fullwidth button border"
                                      href="#!"
                                      onClick={e => { e.preventDefault(); ReactGA.event({ category: "penggalang dana", action: "jadi penggalang dana " + id }); window.location.href = campaign.isFundraiser ? `/penggalang-dana/${campaign.parentCampaign}` : `/penggalang-dana/${id}` }}
                                    >
                                      <i className="sl sl-icon-like" /> Jadi penggalang dana
                                    </a>
                                  </div>
                                )
                            }

                            <div className="clearfix" />
                            {/* End Share */}
                          </div>
                          {/* End Donasi */}

                          {/* Contact */}

                          {/* End Contact */}
                        </div>
                      ) : null}

                      <div id="tabs-reset"></div>

                      <div id="parent-tabs-nav" className="style-1">
                        {/* Listing nav */}
                        <ul id="tabs-nav" className="tabs-nav">
                          <div id="under-tabs-nav" />
                          <li className="active">
                            <a href="#tab1b"
                              onClick={e => {

                                e.preventDefault()

                                const tabsNav = document.querySelector("#tabs-reset")

                                window.scrollTo(0, tabsNav.offsetTop)
                              }}
                            >
                              <b>Detail</b>
                            </a>
                          </li>
                          <li>
                            <a href="#tab2b"

                              onClick={e => {

                                e.preventDefault()

                                const tabsNav = document.querySelector("#tabs-reset")

                                window.scrollTo(0, tabsNav.offsetTop)
                              }}
                            >
                              <b>Pembaruan</b>
                            </a>
                          </li>
                          <li>
                            <a href="#tab3b"
                              onClick={e => {

                                e.preventDefault()

                                const tabsNav = document.querySelector("#tabs-reset")

                                window.scrollTo(0, tabsNav.offsetTop)
                              }}
                            >
                              <b>Donatur</b>{" "}
                              <span
                                style={{ color: "#FF5100", fontWeight: "bolder" }}
                                className="nav-tag messages"
                              >
                                {campaign.contribute ? totalDonatur(Object.values(campaign.contribute)) : 0}
                              </span>
                            </a>
                          </li>
                          {
                            !campaign.isFundraiser
                              ? (
                                <li>
                                  <a href="#tab4b"
                                    onClick={e => {

                                      e.preventDefault()

                                      const tabsNav = document.querySelector("#tabs-reset")

                                      window.scrollTo(0, tabsNav.offsetTop)
                                    }}
                                  >
                                    <b>Penggalang dana</b>{" "}
                                    <span
                                      style={{ color: "#FF5100", fontWeight: "bolder" }}
                                      className="nav-tag messages"
                                    >
                                      {fundraiser(this.state.fundraiser).total}
                                    </span>
                                  </a>
                                </li>
                              )
                              : null
                          }
                        </ul>
                        {/*End Listing nav */}

                        <div className="tabs-container">
                          {/*Detail */}
                          <div className="tab-content" id="tab1b">
                            {ReactHtmlParser(campaign.description)}

                            <div style={{ padding: "20px", background: "#f8f8f8", margin: "10px 0 20px" }}>
                              <p style={{ fontSize: "13px" }}>Disclaimer : Informasi dan opini yang tertulis di halaman penggalangan ini adalah milik penggalang dana dan tidak mewakili Satu Kebaikan.</p>
                            </div>

                            <div>
                              {
                                Date.parse(new Date(campaign.deadline)) < Date.parse(new Date())
                                  ? null
                                  : this.state.widthScreen <= 991 ?
                                    (
                                      <ul id="button-wrapper" className="share-buttons margin-bottom-0">
                                        <li style={{ width: "38%" }}>
                                          <WhatsappShareButton
                                            title={`${campaign.shortDescription} mari bantu kampanye ini dengan melanjutkan atau membagikan pada status dan group whatsapp, halaman facebook anda, semoga mendapat amal jariyah.`}
                                            url={shareUrl}
                                          >
                                            <a
                                              style={{ padding: "12px", fontWeight: "bolder", width: "100%", background: "#25d366", color: "#fff", borderRadius: "5px", borderColor: "#25d366", textAlign: "center" }}
                                              className="fullwidth twitter-share"
                                              href="#!"
                                              onClick={e => {
                                                e.preventDefault()

                                                ReactGA.event({
                                                  category: "bagikan",
                                                  action: "bagikan ke whatsapp"
                                                })
                                              }}
                                            >
                                              <i className="fa fa-whatsapp" /> Bagikan
                                          </a>
                                          </WhatsappShareButton>
                                        </li>
                                        <li style={{ width: "58%" }}>
                                          <a
                                            href={"/kontribusi/" + id}
                                            style={{ padding: "12px", fontWeight: "bolder", backgroundColor: "#ff5100", border: "1px solid #ff5100", textAlign: "center", borderRadius: "5px", color: "#fff", width: "100%" }}
                                            onClick={e => {

                                              e.preventDefault()

                                              ReactGA.event({
                                                category: "donasi",
                                                action: "berdonasi"
                                              })

                                              window.location.href = "/kontribusi/" + id

                                            }}
                                          >
                                            Donasi Sekarang
                                        </a>
                                        </li>
                                      </ul>
                                    ) : null
                              }
                            </div>
                          </div>
                          {/* End detail */}

                          {/* Pembaruan*/}
                          <div className="tab-content" id="tab2b">
                            {!campaign.update
                              ? (
                                <div className="notification notice closeable">
                                  <center>
                                    <img width="200" src="https://sharinghappiness.org/files/icon/arrow-refresh-4-icon.png" alt="" />
                                    <p>Kampanye ini belum memiliki info terbaru</p>
                                  </center>
                                </div>
                              ) : (
                                <div id="listing-reviews" className="listing-section">
                                  <section className="comments listing-reviews">
                                    <ul>
                                      {Object.keys(campaign.update)
                                        .reverse()
                                        .map((item, i) => (
                                          <li style={{ border: "1px solid #e8e8e8", padding: "30px 30px 10px", marginTop: "10px" }} key={i}>
                                            <div
                                              style={{ padding: "0" }}
                                              className="comments listing-reviews"
                                            >
                                              <div className="arrow-comment clearfix" />
                                              <div className="comment-by">
                                                <img width="60" style={{ borderRadius: "50%", border: "1px solid #bbb", padding: "5px", float: "left", marginRight: "10px" }} src={campaign.update[item].photo} alt={campaign.update[item].name} />
                                                <span style={{ paddingBottom: "5px", float: "left", marginTop: "5px" }} className="date" >
                                                  <b>{campaign.update[item].name}</b>
                                                  <br />
                                                  {dateFormat(campaign.update[item].date, "dd mmmm yyyy")}
                                                </span>
                                              </div>
                                              <br />
                                              <br />
                                              <hr />
                                              <div>
                                                <h4>{campaign.update[item].title}</h4>
                                                {ReactHtmlParser(
                                                  campaign.update[item].content
                                                )}
                                              </div>
                                            </div>
                                          </li>
                                        ))}
                                    </ul>
                                  </section>
                                </div>
                              )}
                          </div>
                          {/*End Pembaruan */}

                          {/* Donatur */}
                          <div className="tab-content" id="tab3b" >
                            <div id="listing-reviews" style={{ marginTop: "-30px" }} className="listing-section">
                              <section className="comments listing-reviews">

                                {campaign.contribute ? <Donor data={Object.values(campaign.contribute).reverse()} /> : null}

                              </section>
                            </div>
                          </div>
                          {/* End Donatur*/}

                          {/* penggalang dana */}
                          <div className="tab-content" id="tab4b" >

                            <Fundraiser data={fundraiser(this.state.fundraiser).data} widthScreen={this.state.widthScreen} />

                          </div>
                          {/* end penggalang dana */}

                        </div>
                      </div>
                    </div>
                    {/* End col-lg-8 col-md-8 padding-right-30 */}

                    {/* Sidebar */}
                    <div
                      id="sticky"
                      className="desktop-sticky col-md-4 margin-top-40 sticky fixed"
                    >
                      {/* Donasi */}
                      <div
                        className="boxed-widget booking-widget"
                        style={{ paddingTop: "5px", paddingBottom: "5px" }}
                      >
                        <br />

                        <div className="clearfix">
                          <a href={`/orangbaik/${campaign.uid}`} >
                            <table width="100%" style={{ marginBottom: "0", border: "none" }} >
                              <thead>
                                <tr>
                                  <td width="60">
                                    <img width="60" height="60" style={{ borderRadius: "50%", border: "1px solid #bbb", padding: "5px" }} src={campaign.ownerPhoto} alt={campaign.ownerPhoto} />
                                  </td>
                                  <td style={{ textAlign: "left", paddingLeft: "10px", lineHeight: "20px" }}>
                                    <b style={{ fontSize: "13px" }}>{campaign.ownerName}</b>
                                  </td>
                                </tr>
                              </thead>
                            </table>
                          </a>
                        </div>

                        <hr />

                        <h2 style={{ fontSize: "25px", fontFamily: "Open Sans", fontWeight: "999" }}>
                          <span style={{ color: "#23cbf5" }}>
                            Rp.{" "}
                            {campaign.contribute
                              ? (totalDonasi(Object.values(campaign.contribute || {})) + totalDonationOfFundraiser(fundraiser(this.state.fundraiser).data)).toLocaleString()
                              : 0}
                          </span>
                        </h2>
                        <div className="progress-bar" style={{ overflow: "hidden" }}>
                          <span
                            className="progress-bar-fill"
                            style={{
                              width: campaign.target
                                ? Math.ceil(((totalDonasi(Object.values(campaign.contribute || {})) + totalDonationOfFundraiser(fundraiser(this.state.fundraiser).data)) / parseInt(campaign.target, 0)) * 100) + "%"
                                : 0 + "%"
                            }}
                          />
                        </div>

                        <h5 style={{ fontSize: "12px", float: "left", fontFamily: "Open Sans" }}>
                          {
                            isNaN(parseInt(campaign.target, 0))
                              ? ""
                              : Math.ceil(((totalDonasi(Object.values(campaign.contribute || {})) + totalDonationOfFundraiser(fundraiser(this.state.fundraiser).data)) / parseInt(campaign.target, 0)) * 100)
                          }
                          % Terkumpul
                      </h5>
                        <h5 style={{ fontSize: "12px", float: "right", fontFamily: "Open Sans" }}>
                          {Date.parse(new Date(campaign.deadline)) < Date.parse(new Date())
                            ? "Selesai"
                            : Math.floor((Date.parse(campaign.deadline) - Date.parse(new Date())) / (1000 * 60 * 60 * 24) + 1) + " Hari lagi"}{" "}
                        </h5>
                        <br />
                        <h4 style={{ fontSize: "12px" }}>
                          Dari total target{" "}
                          <span style={{ color: "#23cbf5", fontFamily: "Open Sans" }}>
                            {" "}
                            Rp.{" "}
                            {isNaN(parseInt(campaign.target, 0))
                              ? ""
                              : parseInt(campaign.target, 0).toLocaleString()}
                          </span>
                        </h4>
                        <hr />
                        <div>
                          {
                            Date.parse(new Date(campaign.deadline)) < Date.parse(new Date())
                              ? (
                                <button
                                  disabled
                                  className="button border fullwidth margin-top-5"
                                  style={{ cursor: "default", borderRadius: "5px", background: "#fff", borderColor: "#ff5100", color: "#ff5100", marginBottom: "20px" }}
                                >
                                  Kampanye Telah berakhir
                                </button>
                              ) : (
                                <a
                                  href="#!"
                                  className="button fullwidth border"
                                  style={{ borderRadius: "5px", background: "#ff5100", borderColor: "#ff5100", color: "#fff" }}
                                  onClick={e => {

                                    e.preventDefault()

                                    ReactGA.event({
                                      category: "donasi",
                                      action: "berdonasi"
                                    })

                                    window.location.href = "/kontribusi/" + id

                                  }}
                                >
                                  <b>Donasi Sekarang</b>
                                </a>
                              )}
                        </div>
                      </div>
                      {/* End Donasi */}

                      {/* share */}
                      <div
                        className="boxed-widget booking-widget margin-top-10"
                        style={{ padding: "20px 32px 20px" }}
                      >
                        <h3
                          style={{
                            fontSize: "15px",
                            padding: "0 0 5px",
                            // borderTop: "1px solid #e8e8e8",
                            borderBottom: "0",
                            marginBottom: "5px"
                          }}
                        >
                          Bantu sebarkan kebaikan
                      </h3>
                        <ul
                          style={{ width: "100%" }}
                          className="share-buttons margin-bottom-0"
                        >

                          <li style={{ width: "47%" }}>
                            <FacebookShareButton
                              url={shareUrl}
                              quote={`${campaign.shortDescription} mari bantu kampanye ini dengan melanjutkan atau membagikan pada status dan group whatsapp, halaman facebook anda, semoga mendapat amal jariyah.`}
                              hashtag="#baikers"
                            >
                              <a
                                style={{ width: "100%", background: "#fff", color: "#3b5998", borderRadius: "5px", borderColor: "#3b5998", textAlign: "center" }}
                                className="fullwidth fb-share border"
                                href="#!"
                                onClick={e => {

                                  e.preventDefault()

                                  ReactGA.event({
                                    category: "bagikan",
                                    action: "bagikan ke facebook"
                                  })
                                }}
                              >
                                <FacebookShareCount align="center" url={this.shareCountUrl} >
                                  {shareCount => (
                                    <span>
                                      <i className="fa fa-facebook" />
                                      <b>
                                        {shareCount} {" "}Bagikan
                                    </b>
                                    </span>
                                  )}
                                </FacebookShareCount>
                              </a>
                            </FacebookShareButton>
                          </li>

                          <li style={{ width: "48%" }}>
                            <WhatsappShareButton
                              title={`${campaign.shortDescription} mari bantu kampanye ini dengan melanjutkan atau membagikan pada status dan group whatsapp, halaman facebook anda, semoga mendapat amal jariyah.`}
                              url={shareUrl}
                            >
                              <a
                                style={{ width: "100%", background: "#fff", color: "#25d366", borderRadius: "5px", borderColor: "#25d366", textAlign: "center" }}
                                className="fullwidth twitter-share"
                                href="#!"
                                onClick={e => {
                                  e.preventDefault()

                                  ReactGA.event({
                                    category: "bagikan",
                                    action: "bagikan ke whatsapp"
                                  })
                                }}
                              >
                                <i className="fa fa-whatsapp" /> Whatsapp
                              </a>
                            </WhatsappShareButton>
                          </li>
                        </ul>

                        {
                          Date.parse(new Date(campaign.deadline)) < Date.parse(new Date())
                            ? null
                            : (
                              <div>
                                <p style={{ fontSize: "12px", margin: "0", lineHeight: "15px", marginBottom: "10px", marginTop: "5px" }}>Bantu kampanye ini dengan menjadi penggalang dana</p>
                                <a
                                  style={{ width: "100%", background: "#fff", color: "rgb(35, 203, 245)", borderRadius: "5px", borderColor: "rgb(35, 203, 245)", textAlign: "center" }}
                                  className="fullwidth button border"
                                  href="#!"
                                  onClick={e => { e.preventDefault(); ReactGA.event({ category: "penggalang dana", action: "jadi penggalang dana " + id }); window.location.href = campaign.isFundraiser ? `/penggalang-dana/${campaign.parentCampaign}` : `/penggalang-dana/${id}` }}
                                >
                                  <i className="sl sl-icon-like" /> Jadi penggalang dana
                              </a>
                              </div>
                            )
                        }
                        <div className="clearfix" />
                      </div>
                      {/* End sahre */}
                    </div>
                    {/* End Sidebar */}
                  </div>
                </div>
                {/*End Content*/}

                {/* Load footer html Syntax */}
                <br />
                {/* <Footer /> */}
                {/* End footer */}
              </div>
            )
        }
      </div>
    );
  }
}

export default SinglePage;
