import React, { Component } from "react"
import firebase from "firebase/app"
import "firebase/database"
import dateFormat from "dateformat"
import Edotensei from "edotensei"

import Navbar from "../includes/admin-components/admin-navigations"
import Sidebar from "../includes/admin-components/admin-sidebar"

const { add } = Edotensei;

const Scripts = [{ src: "/scripts/jquery-2.2.0.min.js", rel: "preload" }, { src: "/scripts/mmenu.min.js", defer: true }, { src: "/scripts/chosen.min.js", defer: true }, { src: "/scripts/slick.min.js", defer: true }, { src: "/scripts/rangeslider.min.js", defer: true }, { src: "/scripts/magnific-popup.min.js", defer: true }, { src: "/scripts/waypoints.min.js", defer: true }, { src: "/scripts/counterup.min.js", defer: true }, { src: "/scripts/tooltips.min.js", defer: true }, { src: "/scripts/custom.js", defer: true }]

const arrayToObject = (arr, item) => {

    var rv = {}

    for (var i = 0; i < arr.length; i++) {
        rv[arr[i]] = item[arr[i]];
    }

    return rv
};

const readData = callback => {

    firebase.database().ref().child("/campaign").on("value", snap => {

        const value = snap.exists() ? snap.val() : {};

        callback(value);
    })
}

class donasiku extends Component {

    constructor() {

        super()

        this.state = {
            campaign: {},
            _campaign: {},
            status: "pending",
            isLoaded: false
        }

    }

    componentDidMount() {

        add(Scripts);
    }

    componentWillUnmount() {

        this.isCancelled = true
    }

    componentWillMount() {

        const { email } = this.props.auth.data

        readData(snap => {

            !this.isCancelled && this.setState({ _campaign: snap, isLoaded: true });

            let campaign = snap

            const contribute = Object.keys(campaign).filter(i => campaign[i].contribute)

            let result = contribute.map(i => {

                let results = Object.keys(campaign[i].contribute).filter(item => campaign[i].contribute[item].email.toLowerCase().search(email) !== -1)

                campaign[i].contribute = arrayToObject(results, campaign[i].contribute)

                return Object.keys(campaign[i].contribute).length > 0 ? i : undefined
            })

            result = result.filter(i => i).reverse()

            campaign = arrayToObject(result, campaign)

            this.setState({ campaign })
        })
    }


    render() {

        const { campaign } = this.state

        const { picture, uid, name } = this.props.auth.data

        return (
            <div>
                {/* Load Navigation html Syntax */}
                <Navbar picture={picture} name={name} />
                {/*End*/}

                <div id="dashboard">
                    {/* Sidebar*/}
                    <Sidebar uid={uid} />
                    {/* End Sidebar*/}

                    {/*Content*/}
                    <div className="dashboard-content">
                        {/* title bar */}
                        <div id="titlebar">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2>Donasiku</h2>

                                    <nav id="breadcrumbs">
                                        <ul>
                                            <li>
                                                <a href="/">Home</a>
                                            </li>
                                            <li>Donatur</li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        {/* end title bar */}

                        <div className="row">
                            <div
                                hidden={!this.state.isLoaded}
                                className="col-lg-12 col-md-12"
                            >
                                <div className="dashboard-list-box margin-top-0">
                                    {/* short by */}
                                    <div className="sort-by">
                                        <div className="sort-by-select" />
                                    </div>

                                    <h4 className="clearfix">
                                        List Donasiku
                                    </h4>

                                    {Object.keys(campaign)
                                        .reverse()
                                        .map((item, i) => (
                                            <ul className="list-donatur" key={i}>
                                                {!campaign[item].contribute ? (
                                                    null
                                                ) : (
                                                        Object.keys(campaign[item].contribute)
                                                            .reverse()
                                                            .map((key, i) => (
                                                                <li
                                                                    key={i}
                                                                    className={
                                                                        campaign[item].contribute[
                                                                            key
                                                                        ].status.toLocaleLowerCase() +
                                                                        "-booking items"
                                                                    }
                                                                    name={campaign[item].contribute[
                                                                        key
                                                                    ].status.toLocaleLowerCase()}
                                                                >
                                                                    <div className="list-box-listing bookings">
                                                                        <div className="list-box-listing-img">
                                                                            <img src={picture} alt="" />
                                                                        </div>
                                                                        <div className="list-box-listing-content">
                                                                            <div className="inner">
                                                                                <h3>
                                                                                    {campaign[item].title}{" "}
                                                                                    <span className="booking-status">
                                                                                        {
                                                                                            campaign[item].contribute[key]
                                                                                                .status
                                                                                        }
                                                                                    </span>
                                                                                </h3>

                                                                                <div className="inner-booking-list">
                                                                                    <h5>Tanggal Donasi: &nbsp;</h5>
                                                                                    <ul className="booking-list">
                                                                                        <li className="highlighted">
                                                                                            {campaign[item].contribute[key].date
                                                                                                ? dateFormat(
                                                                                                    campaign[item].contribute[key]
                                                                                                        .date,
                                                                                                    "dd mmm yyyy H:MM"
                                                                                                )
                                                                                                : ""}
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>

                                                                                <div className="inner-booking-list">
                                                                                    <h5>Jumlah donasi: &nbsp;</h5>
                                                                                    <ul className="booking-list">
                                                                                        <li className="highlighted">
                                                                                            Rp.
                                                                                                {(parseInt(campaign[item].contribute[key].donation, 0) +
                                                                                                parseInt(campaign[item].contribute[key].uniqueCode, 0)).toLocaleString()}
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>

                                                                                <div className="inner-booking-list">
                                                                                    <h5>Nama: &nbsp;</h5>
                                                                                    <ul className="booking-list">
                                                                                        <li>
                                                                                            {campaign[item].contribute[key].name}
                                                                                        </li>
                                                                                        <li>
                                                                                            {campaign[item].contribute[key].email}
                                                                                        </li>
                                                                                        <li>
                                                                                            {campaign[item].contribute[key].phone}
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            ))
                                                    )}
                                            </ul>
                                        ))}
                                </div>
                            </div>

                            <center hidden={this.state.isLoaded}>
                                <br />
                                <br />
                                <div className="lds-dual-ring" />
                                <br />
                                <br />
                                <br />
                            </center>

                            <div className="col-md-12">
                                <div className="copyrights">
                                    © 2018 Satukebaikan indonesia. All Rights Reserved.
                </div>
                            </div>
                        </div>

                        {/*End*/}
                    </div>
                </div>
            </div>
        );
    }
}

export default donasiku;
