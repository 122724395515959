import React, { Component } from "react"

class Loader extends Component {

    render() {
        return (
            <center className="vertical-center">
                <div className="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <br />
                <br />
                <img style={{ width: "200px" }} src="/images/logo-a.png" alt="satukebaikan" />
            </center>
        )
    }

}

export default Loader