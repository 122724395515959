import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import { BrowserRouter, Route, Switch } from "react-router-dom"
import firebase from "firebase/app"
import "firebase/auth"

import * as pushNotification from "./pushNotification"
import * as serviceWorker from './serviceWorker'

// includes
import Loader from "./layout/includes/loader"
import Redirect from "./layout/includes/redirect"

// non admin
import Home from "./layout/non-admin/home"
import ExplorerAll from "./layout/non-admin/explorer-all"
import Single from "./layout/non-admin/single-page"
import Contribute from "./layout/non-admin/contribute"
import Fundraiser from "./layout/non-admin/fundraiser"
import Summary from "./layout/non-admin/summary"
import Profile from "./layout/non-admin/profile"
import Update from "./layout/admin-panel/update-campaign"
import Notfound from "./layout/non-admin/404"
import AboutUs from "./layout/non-admin/about-us"
import Faq from "./layout/non-admin/faq"
import Privacy from "./layout/non-admin/privacy-policy"
import HowtoDonate from "./layout/non-admin/how-to-donate"
import OurTeam from "./layout/non-admin/our-team"
import Terms from "./layout/non-admin/terms"
import Bestfriend from "./layout/non-admin/bestfriend"

// admin
import Login from "./layout/admin-panel/login"
import Dashboard from "./layout/admin-panel/dashboard"
import MyDonation from "./layout/admin-panel/my-donation"
import Donatur from "./layout/admin-panel/donatur"
import AddCampaign from "./layout/admin-panel/campaign-add"
import CampaignFinished from "./layout/admin-panel/campaign-finished"
import CampaignRunning from "./layout/admin-panel/campaign-running"
import EditCampaign from "./layout/admin-panel/campaign-edit"
import DonationList from "./layout/admin-panel/donation-list"
import Category from "./layout/admin-panel/category"
import Users from "./layout/admin-panel/users"
import UserDetail from "./layout/admin-panel/user-detail"
import EditProfile from "./layout/admin-panel/profile"
import Verification from "./layout/admin-panel/verification"

// Initialize Firebase
const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_ID
}

firebase.initializeApp(config)

ReactGA.initialize("UA-129664241-1")

const Credentials = ({ component: Component, props, auth }) => auth ? <Component {...props} auth={auth} /> : <Redirect url="/dashboard/login" />

const render = info => {

    ReactDOM.render(
        <BrowserRouter>
            <Switch>

                <Route exact path="/" render={props => <Home {...props} auth={info} />} />

                <Route exact path="/ikutgabung" render={props => window.location.href = "https://docs.google.com/forms/d/e/1FAIpQLSfwOOCAYMRTDWimVQ3gAOT0kDBCo1QS6lZZDW3v2EpobRCd0Q/viewform"} />

                <Route exact path="/explorer/all" render={props => <ExplorerAll {...props} auth={info} />} />

                <Route exact path="/:id" render={props => <Single {...props} auth={info} />} />

                <Route exact path="/kontribusi/:id" render={props => (<Contribute {...props} auth={info} />)} />

                <Route exact path="/ringkasan/:key/:pushid" render={props => <Summary {...props} auth={info} />} />

                <Route exact path="/orangbaik/:uid" render={props => <Profile {...props} auth={info} />} />

                <Route
                    exact
                    path="/penggalang-dana/:id"
                    render={props => <Credentials component={Fundraiser} props={props} auth={info} />}
                />

                <Route
                    exact
                    path="/dashboard/login"
                    render={props => !info ? <Login {...props} /> : <Redirect url="/dashboard/overview" />}
                />

                <Route
                    exact
                    path="/dashboard/overview"
                    render={props => <Credentials component={Dashboard} props={props} auth={info} />}
                />

                <Route
                    exact
                    path="/dashboard/donasiku"
                    render={props => <Credentials component={MyDonation} props={props} auth={info} />}
                />

                <Route
                    exact
                    path="/dashboard/Donatur"
                    render={props => <Credentials component={Donatur} props={props} auth={info} />}
                />

                <Route
                    exact
                    path="/dashboard/galang-dana"
                    render={props => <Credentials component={AddCampaign} props={props} auth={info} />}
                />

                <Route
                    exact
                    path="/dashboard/kampanye-selesai"
                    render={props => <Credentials component={CampaignFinished} props={props} auth={info} />}
                />

                <Route
                    exact
                    path="/dashboard/kampanye-berjalan"
                    render={props => <Credentials component={CampaignRunning} props={props} auth={info} />}
                />

                <Route
                    exact
                    path="/dashboard/kampanyeku/edit/:id"
                    render={props => <Credentials component={EditCampaign} props={props} auth={info} />}
                />

                <Route
                    exact
                    path="/dashboard/kampanyeku/list-donasi/:id"
                    render={props => <Credentials component={DonationList} props={props} auth={info} />}
                />

                <Route
                    exact
                    path="/dashboard/kampanyeku/pembaruan/:id"
                    render={props => <Credentials component={Update} props={props} auth={info} />}
                />

                <Route
                    exact
                    path="/dashboard/pengaturan/kategori"
                    render={props => <Credentials component={Category} props={props} auth={info} />}
                />

                <Route
                    exact
                    path="/dashboard/pengaturan/pengguna"
                    render={props => <Credentials component={Users} props={props} auth={info} />}
                />

                <Route
                    exact
                    path="/dashboard/pengaturan/pengguna/detail/:uid"
                    render={props => <Credentials component={UserDetail} props={props} auth={info} />}
                />

                <Route
                    exact
                    path="/dashboard/profil"
                    render={props => <Credentials component={EditProfile} props={props} auth={info} />}
                />

                <Route
                    exact
                    path="/dashboard/verifikasi-akun"
                    render={props => <Credentials component={Verification} props={props} auth={info} />}
                />

                <Route exact path="/info/tentang-kami" render={props => <AboutUs {...props} auth={info} />} />

                <Route exact path="/info/faq" render={props => <Faq {...props} auth={info} />} />

                <Route exact path="/info/kebijakan-privasi" render={props => <Privacy {...props} auth={info} />} />

                <Route exact path="/info/cara-donasi" render={props => <HowtoDonate {...props} auth={info} />} />

                <Route exact path="/info/tim-kami" render={props => <OurTeam {...props} auth={info} />} />

                <Route exact path="/info/syarat-dan-ketentuan" render={props => <Terms {...props} auth={info} />} />

                <Route exact path="/info/sahabat" render={props => <Bestfriend {...props} auth={info} />} />

                <Route exact path="*" render={props => <Notfound {...props} auth={info} />} />

            </Switch>
        </BrowserRouter>,
        document.querySelector("#wrapper")
    )
}

try {

    ReactDOM.render(<Loader />, document.querySelector("#wrapper"))

    firebase.auth().onAuthStateChanged(async user => {

        let auth

        if (user) {

            const idToken = await user.getIdToken()

            const verifyIdToken = firebase.functions().httpsCallable("verifyIdToken")

            const result = await verifyIdToken({ token: idToken })

            auth = result

        } else {

            auth = false
        }

        render(auth)

    })
} catch (error) {

    render(false)
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister()

pushNotification.askPermission()