import React, { Component } from "react"
import firebase from "firebase/app"
import "firebase/database"
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import Edotensei from "edotensei"

import Navbar from "../includes/admin-components/admin-navigations"
import Sidebar from "../includes/admin-components/admin-sidebar"

const { add } = Edotensei

const Scripts = [{ src: "/scripts/jquery-2.2.0.min.js", rel: "preload" }, { src: "/scripts/mmenu.min.js", defer: true }, { src: "/scripts/chosen.min.js", defer: true }, { src: "/scripts/slick.min.js", defer: true }, { src: "/scripts/rangeslider.min.js", defer: true }, { src: "/scripts/magnific-popup.min.js", defer: true }, { src: "/scripts/waypoints.min.js", defer: true }, { src: "/scripts/counterup.min.js", defer: true }, { src: "/scripts/tooltips.min.js", defer: true }, { src: "/scripts/custom.js", defer: true }]

const readData = (key, callback) => {

  firebase.database().ref().child("/campaign/" + key).on("value", snap => {

    const value = snap.exists() ? snap.val() : {}

    callback(value)
  })
}

const totalBiayaAdministrasi = (arr, fundraise, piece) => {

  let value = 0

  piece = piece / 100

  arr = arr.map(i => i.status.toLowerCase() === "approved" ? i : null)

  for (let i in arr) {

    if (arr[i]) {

      value += (arr[i].donation + arr[i].uniqueCode)
    }
  }

  return (value + fundraise) * piece
}

const totalDonasiBersih = (arr, fundraise, piece) => {

  const biayaAdministrasi = totalBiayaAdministrasi(arr, fundraise, piece)

  let value = 0

  piece = piece / 100

  arr = arr.map(i => i.status.toLowerCase() === "approved" ? i : null)

  for (let i in arr) {

    if (arr[i]) {

      value += (arr[i].donation + arr[i].uniqueCode)
    }
  }

  return value + fundraise - biayaAdministrasi
}

const totalDonasi = arr => {

  let value = 0

  arr = arr.map(i => i.status.toLowerCase() === "approved" ? i : null)

  for (let i in arr) {

    if (arr[i]) {

      value += (arr[i].donation + arr[i].uniqueCode)

    }
  }

  return value
}

const parseFirebaseData = obj => {

  Object.keys(obj).map(i => obj[i].key = i)

  const result = Object.values(obj).filter(i => {

    let donation = i.contribute ? Object.values(i.contribute).map(con => con.status.toLowerCase() === "approved" ? con.donation + con.uniqueCode : null) : []

    donation = donation.filter(donate => donate)

    i.totalContribute = donation.length

    i.totalDonation = donation.reduce((a, b) => a + b, 0)

    return i
  })

  obj = result

  return obj
}

const fundraiser = obj => {

  const value = parseFirebaseData(obj)

  return { total: value.length, data: value }
}

const totalDonationOfFundraiser = arr => {

  arr = arr.map(i => i.totalDonation)

  return arr.reduce((a, b) => a + b, 0)
}

class Donationlist extends Component {
  constructor() {
    super()
    this.state = {
      isLoaded: false,
      campaign: {
        contribute: {}
      },
      fundraise: {},
      currentPage: 1,
      todosPerPage: 10
    }
  }

  componentDidMount() {

    add(Scripts)
  }

  componentWillMount() {

    // mengambil nilai dari paramater id
    const { id } = this.props.match.params

    readData(id, async snap => {

      const snapshot = await firebase.database().ref("/tags").orderByChild("title").equalTo(snap.category).once("value")

      const piece = snapshot.exists() ? Object.values(snapshot.val())[0].piece : 5

      snap.piece = snap.isFundraiser ? 0 : piece

      const snapFundraiser = await firebase.database().ref("campaign").orderByChild("parentCampaign").equalTo(id).once("value")

      this.setState({ campaign: snap, fundraise: snapFundraiser.exists() ? snapFundraiser.val() : {}, isLoaded: true })
    })
  }

  nextPage() {

    const destination = document.getElementById("reset-table")

    window.scrollTo(0, (destination.offsetTop + 500))

    this.setState({ currentPage: this.state.currentPage + 1 })
  }

  previousPage() {

    const destination = document.getElementById("reset-table")

    window.scrollTo(0, (destination.offsetTop + 500))

    this.setState({ currentPage: this.state.currentPage - 1 })
  }

  render() {

    const { campaign, currentPage, todosPerPage, fundraise } = this.state

    const { picture, uid, name } = this.props.auth.data

    const indexOfLastTodo = currentPage * todosPerPage

    const indexOfFirstTodo = indexOfLastTodo - todosPerPage

    const currentTodos = campaign.contribute ? Object.values(campaign.contribute).filter(i => i.status.toLowerCase() === "approved").slice(indexOfFirstTodo, indexOfLastTodo) : []

    const pageNumbers = []

    if (currentTodos.length > 1) {

      for (let i = 1; i <= Math.ceil(Object.values(campaign.contribute).filter(i => i.status.toLowerCase() === "approved").length / todosPerPage); i++) {

        pageNumbers.push(i)
      }
    }

    const totalFundraise = totalDonationOfFundraiser(fundraiser(fundraise).data)

    return (

      <div>
        {/* Load Navigation html Syntax */}
        <Navbar picture={picture} name={name} />
        {/*End*/}

        <div id="dashboard">
          {/* Sidebar*/}
          <Sidebar uid={uid} />
          {/* End Sidebar*/}

          {/*Content*/}
          <div className="dashboard-content">
            <div id="titlebar">
              <div className="row">
                <div className="col-md-12">
                  <h2>Daftar Donasi</h2>
                  <p>{campaign.title} {campaign.isFundraiser ? <b style={{ color: "#ff5100", fontSize: "12px" }}>Sebagai Penggalang Dana</b> : null} </p>
                  <nav id="breadcrumbs">
                    <ul>
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li>
                        <a href="/dashboard/campaign-berjalan">Kampanyeku</a>
                      </li>
                      <li>Daftar donasi</li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div hidden={!this.state.isLoaded} id="add-listing">
                  <div className="add-listing-section clearfix">

                    <div style={{ textAlign: "center" }} className="add-listing-headline" >
                      <h3>Rincian Donasi</h3>
                    </div>

                    <table style={{ border: "none" }}>
                      <thead>
                        <tr>
                          <td>
                            <h4 style={{ marginBottom: "30px" }}>Total Donasi Terkumpul</h4>
                          </td>
                          <td>
                            <h4>Rp.</h4>
                          </td>
                          <td>
                            <h4 style={{ fontFamily: "Open Sans" }}>
                              {
                                campaign.contribute
                                  ? totalDonasi(Object.values(campaign.contribute)).toLocaleString()
                                  : 0
                              }
                            </h4>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <h4 style={{ marginBottom: "30px" }}>Total Donasi Dari Penggalang Dana (fundraiser)</h4>
                          </td>
                          <td>
                            <h4>Rp.</h4>
                          </td>
                          <td>
                            <h4 style={{ fontFamily: "Open Sans" }}>
                              {
                                campaign.contribute
                                  ? totalFundraise.toLocaleString()
                                  : 0
                              }
                            </h4>
                          </td>
                        </tr>

                        <tr style={{ borderBottom: "1px solid #bbb" }}>
                          <td style={{ marginBottom: "30px" }}>
                            <h4 style={{ marginBottom: "0" }}>Biaya Operasional Satukebaikan</h4>
                            Biaya administrasi  {campaign.piece}% dari total donasi online

                            <br />
                            <br />

                          </td>
                          <td>
                            <h4>Rp.</h4>
                          </td>
                          <td>
                            <h4 style={{ fontFamily: "Open Sans" }}>
                              {
                                campaign.contribute
                                  ? totalBiayaAdministrasi(Object.values(campaign.contribute), totalFundraise, campaign.piece).toLocaleString()
                                  : 0
                              }
                            </h4>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <h3>
                              <b>Total Donasi Bersih</b>
                            </h3>
                          </td>
                          <td>
                            <h3>Rp.</h3>
                          </td>
                          <td>
                            <h3 style={{ fontFamily: "Open Sans" }}>
                              {
                                campaign.contribute
                                  ? totalDonasiBersih(Object.values(campaign.contribute), totalFundraise, campaign.piece).toLocaleString()
                                  : 0
                              }
                            </h3>
                          </td>
                        </tr>
                      </thead>
                    </table>

                  </div>
                </div>
              </div>
            </div>

            <br />

            <div className="row">
              <div className="col-lg-12">
                <div hidden={!this.state.isLoaded} id="add-listing">
                  <div className="add-listing-section clearfix">
                    <div style={{ textAlign: "right" }} className="add-listing-headline" >

                      <ReactHTMLTableToExcel
                        className="button download-xls"
                        table="datatable"
                        filename="list donasi"
                        sheet="list donasi"
                        buttonText="Download as XLS"
                      />

                    </div>

                    {
                      campaign.contribute && Object.keys(campaign.contribute).length !== 0 ?

                        <div id="reset-table" className="col-md-12">

                          <table style={{ borderBottom: "0" }} id="datatable" className="basic-table">
                            <thead>
                              <tr>
                                <th style={{ background: "#2a2a2a", color: "#fff", verticalAlign: "center" }}>Nama Donatur</th>
                                <th style={{ background: "#2a2a2a", color: "#fff" }}>Nominal Donasi</th>
                                <th style={{ background: "#2a2a2a", color: "#fff" }}>Biaya Administrasi ({campaign.piece}%)</th>
                                <th style={{ background: "#2a2a2a", color: "#fff" }}>Donasi Bersih</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                currentTodos && currentTodos.map((item, i) =>

                                  <tr key={i}>
                                    <td>
                                      {item.isAnonim ? "Anonim" : item.name}
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      Rp. {" "}
                                      {
                                        (item.donation + item.uniqueCode).toLocaleString()
                                      }
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                      Rp. {" "}
                                      {
                                        ((item.donation + item.uniqueCode) * (campaign.piece / 100)).toLocaleString()
                                      }
                                    </td>

                                    <td style={{ textAlign: "left" }}>
                                      Rp. {" "}
                                      {
                                        ((item.donation + item.uniqueCode) - ((item.donation + item.uniqueCode) * (campaign.piece / 100))).toLocaleString()
                                      }
                                    </td>
                                  </tr>
                                )
                              }
                            </tbody>
                          </table>

                          {
                            currentTodos.length > 1
                              ? (
                                <ul className="margin-top-10" style={{ padding: "10px", paddingRight: "0", float: "right" }}>

                                  {
                                    currentPage !== 1
                                      ? (
                                        <li onClick={this.previousPage.bind(this)} style={{ cursor: "pointer", display: "inline", padding: "10px 15px", border: "1px solid #ccc", borderRight: "none", background: "#fff" }}>
                                          <b>Sembelumnya</b>
                                        </li>
                                      )
                                      : null
                                  }

                                  <li style={{ display: "inline", padding: "10px 20px", border: "1px solid #ccc", background: "#fff" }}>
                                    {currentPage}
                                  </li>

                                  {
                                    currentPage !== pageNumbers.length
                                      ? (
                                        <li onClick={this.nextPage.bind(this)} style={{ cursor: "pointer", display: "inline", padding: "10px 15px", border: "1px solid #ccc", background: "#fff" }}>
                                          <b>Selanjutnya</b>
                                        </li>
                                      )
                                      : null
                                  }
                                </ul>
                              )
                              : null
                          }

                        </div>
                        : <center>Belum ada donatur</center>
                    }
                  </div>
                </div>

                <div className="col-md-12">
                  {/** Loader animation */}
                  <center hidden={this.state.isLoaded}>
                    <br />
                    <br />
                    <div className="lds-dual-ring" />
                    <br />
                    <br />
                    <br />
                  </center>
                  <div className="copyrights">
                    © 2018 Satukebaikan indonesia. All Rights Reserved.
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*End*/}
        </div>
      </div>
    )
  }
}

export default Donationlist
