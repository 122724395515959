import React, { Component } from "react"
import firebase from "firebase/app"
import "firebase/auth"
import izitoast from "izitoast"
import "izitoast/dist/css/iziToast.min.css"

class Login extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      isSubmit: false
    }

    this.handleInput = this.handleInput.bind(this);
  }

  handleInput(e) {

    this.setState({
      [e.target.name]: e.target.value
    });
  }

  async handleSubmit(e) {

    e.preventDefault();

    this.setState({ isSubmit: true })

    try {

      await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)

      await firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password)

    } catch (error) {

      izitoast.error({
        message: error.message,
        position: "topRight",
        icon: "fa fa-frown-o",
        backgroundColor: "#f96161",
        messageColor: "#fff",
        iconColor: "#fff",
        balloon: true,
        transitionIn: "bounceInLeft",
        displayMode: 2,
        layout: 2,
        maxWidth : '500px'
      })

      this.setState({ password: "", isSubmit: false })
    }
  }

  async signInWithGoogle() {

    this.setState({ isSubmit: true })

    const provider = new firebase.auth.GoogleAuthProvider()

    try {

      await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)

      await firebase.auth().signInWithPopup(provider)

    } catch (error) {

      izitoast.error({
        message: error.message,
        position: "topRight",
        icon: "fa fa-frown-o",
        backgroundColor: "	#f96161",
        messageColor: "#fff",
        iconColor: "#fff",
        balloon: true,
        transitionIn: "bounceInLeft",
        displayMode: 2,
        layout: 2,
        maxWidth : '500px'
      })

      this.setState({ isSubmit: false })
    }
  }

  async signInWithFacebook() {

    this.setState({ isSubmit: true })

    const provider = new firebase.auth.FacebookAuthProvider()

    try {

      await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)

      await firebase.auth().signInWithPopup(provider)

    } catch (error) {

      izitoast.error({
        message: error.message,
        position: "topRight",
        icon: "fa fa-frown-o",
        backgroundColor: "	#f96161",
        messageColor: "#fff",
        iconColor: "#fff",
        balloon: true,
        transitionIn: "bounceInLeft",
        displayMode: 2,
        layout: 2,
        maxWidth : '500px'
      })

      this.setState({ isSubmit: false })
    }
  }

  render() {

    return (
      <div style={{ height: "fit-content" }} >

        <div
          id="sign-in-dialog"
          className="vertical-center login-form"
          style={{ maxWidth: "450px" }}
        >
          <br />
          <div className="sign-in-form">
            <center>
              <a href="/" style={{ paddingLeft: "0" }}>
                <img width="150" src="/images/logo-a.png" alt="" />{" "}
              </a>
            </center>

            <hr />

            <div className="tabs-container alt">
              <div className="tab-content" id="tab1">
                <form
                  onSubmit={this.handleSubmit.bind(this)}
                  className="login"
                >
                  <p className="form-row form-row-wide">
                    <label htmlFor="username">
                      Email:
                        <i className="im im-icon-Email" />
                      <input
                        type="email"
                        className="input-text"
                        name="email"
                        id="username"
                        onChange={this.handleInput}
                        value={this.state.email}
                        disabled={this.state.isSubmit}
                        required
                      />
                    </label>
                  </p>

                  <p className="form-row form-row-wide">
                    <label htmlFor="password">
                      Password:
                        <i className="im im-icon-Lock-2" />
                      <input
                        className="input-text"
                        type="password"
                        name="password"
                        id="password"
                        onChange={this.handleInput}
                        value={this.state.password}
                        disabled={this.state.isSubmit}
                        required
                      />
                    </label>
                  </p>

                  <div className="form-row">
                    <button
                      type="submit"
                      style={{ width: "100%", borderRadius: "5px" }}
                      className="button border margin-top-5"
                      name="login"
                      disabled={this.state.isSubmit}
                    >
                      {
                        this.state.isSubmit
                          ? <img width="20" src="/images/loading-2.gif" alt="" />
                          : "Login"
                      }
                    </button>
                  </div>
                </form>

                <hr />

                <center style={{ margin: "20px 0px" }}>
                  <span
                    className="lost_password"
                    style={{ color: "#666", fontSize: "12px" }}
                  >
                    Belum punya akun satukebaikan? Masuk dengan :
                </span>
                  <br />
                </center>

                <button
                  style={{
                    background: "#d34836",
                    borderRadius: "5px",
                    color: "#fff"
                  }}
                  type="button"
                  className="button fullwidth"
                  onClick={this.signInWithGoogle.bind(this)}
                  disabled={this.state.isSubmit}
                >
                  <i className="fa fa-google" /> Masuk Dengan Google
                </button>

                <button
                  style={{
                    background: "#3b5998",
                    borderRadius: "5px",
                    color: "#fff"
                  }}
                  type="button"
                  className="button fullwidth margin-top-10"
                  onClick={this.signInWithFacebook.bind(this)}
                  disabled={this.state.isSubmit}
                >
                  <i className="fa fa-facebook" /> Masuk Dengan Facebook
                </button>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
