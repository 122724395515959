import React, { Component } from "react"
import firebase from "firebase/app"
import "firebase/database"
import dateFormat from "dateformat"
import md5 from "md5"
import axios from "axios"
import Edotensei from "edotensei"
import izitoast from "izitoast"

import Navbar from "../includes/admin-components/admin-navigations"
import Sidebar from "../includes/admin-components/admin-sidebar"

const { add } = Edotensei;

const Scripts = [{ src: "/scripts/jquery-2.2.0.min.js", rel: "preload" }, { src: "/scripts/mmenu.min.js", defer: true }, { src: "/scripts/chosen.min.js", defer: true }, { src: "/scripts/slick.min.js", defer: true }, { src: "/scripts/rangeslider.min.js", defer: true }, { src: "/scripts/magnific-popup.min.js", defer: true }, { src: "/scripts/waypoints.min.js", defer: true }, { src: "/scripts/counterup.min.js", defer: true }, { src: "/scripts/tooltips.min.js", defer: true }, { src: "/scripts/custom.js", defer: true }]

const arrayToObject = (arr, item) => {

  var rv = {}

  for (var i = 0; i < arr.length; i++) {
    rv[arr[i]] = item[arr[i]];
  }

  return rv
};

const readData = callback => {
  firebase
    .database()
    .ref()
    .child("/campaign")
    .on("value", snap => {

      const value = snap.val() ? snap.val() : {};

      callback(value);
    })
};

class Kontributor extends Component {

  constructor() {

    super()

    this.state = {
      campaign: {},
      _campaign: {},
      status: "pending",
      isLoaded: false
    }

  }

  componentDidMount() {

    add(Scripts);
  }

  componentWillUnmount() {

    this.isCancelled = true
  }

  componentWillMount() {

    readData(snap => {

      !this.isCancelled && this.setState({ _campaign: snap, isLoaded: true });

      let campaign = snap

      const contribute = Object.keys(campaign).filter(i => campaign[i].contribute)

      let result = contribute.map(i => {

        let results = Object.keys(campaign[i].contribute).filter(item => campaign[i].contribute[item].status.toLowerCase().search("pending") !== -1)

        campaign[i].contribute = arrayToObject(results, campaign[i].contribute)

        return Object.keys(campaign[i].contribute).length > 0 ? i : undefined
      })

      result = result.filter(i => i)

      campaign = arrayToObject(result, campaign)

      this.setState({ campaign })
    })
  }

  async filterStatus(e) {

    try {

      const value = e.target.value

      const snap = await firebase.database().ref("/campaign").once("value")

      let campaign = snap.val()

      if (value.toLocaleLowerCase() === "semua status") return this.setState({ campaign, status: value.toLocaleLowerCase() })

      const contribute = Object.keys(campaign).filter(i => campaign[i].contribute)

      let result = contribute.map(i => {

        let results = Object.keys(campaign[i].contribute).filter(item => campaign[i].contribute[item].status.toLowerCase().search(value.toLocaleLowerCase()) !== -1)

        campaign[i].contribute = arrayToObject(results, campaign[i].contribute)

        return Object.keys(campaign[i].contribute).length > 0 ? i : undefined
      })

      result = result.filter(i => i)

      campaign = arrayToObject(result, campaign)

      this.setState({ campaign, status: value.toLocaleLowerCase() })

    } catch (error) {

      console.log(error)

      izitoast.error({
        message: error,
        position: "topRight"
      })
    }

  }

  async searchDonatur(e) {

    try {

      const value = e.target.value

      const snap = await firebase.database().ref("/campaign").once("value")

      let campaign = snap.val()

      const contribute = Object.keys(campaign).filter(i => campaign[i].contribute)

      let result = contribute.map(i => {

        let results = Object.keys(campaign[i].contribute).filter(item => campaign[i].contribute[item].email.toLowerCase().search(value.toLocaleLowerCase()) !== -1)

        campaign[i].contribute = arrayToObject(results, campaign[i].contribute)

        return Object.keys(campaign[i].contribute).length > 0 ? i : undefined
      })

      result = result.filter(i => i)

      campaign = arrayToObject(result, campaign)

      this.setState({ campaign, status: "semua status" })

    } catch (error) {

      console.log(error)

      izitoast.error({
        message: error,
        position: "topRight"
      })
    }
  }

  /**
   * mengupdate status kontribusi
   * @param path
   * @param key
   * @param e
   */
  updateStatusHandler(pathname, key, email, donasi, judul, bagikan, nama, e) {

    if (!e.target.name) {

      izitoast.info({
        message: "Ada kesalah teknis. Silahkan coba lagi!",
        position: "topRight",
        icon: "fa fa-frown-o",
        backgroundColor: "	#f96161",
        messageColor: "#fff",
        iconColor: "#fff",
        balloon: true,
        transitionIn: "bounceInLeft",
        displayMode: 2,
        layout: 2,
        maxWidth: '500px'
      })

      return
    }

    /**
     * set variable selector dengan nilai default 'cancel'
     * @type {string}
     */
    let selector = "Canceled";
    /**
     * melakukan validasi
     */
    switch (e.target.name.toLowerCase()) {
      case "approve":
        selector = "Approved";
        break;
      case "cancel":
        selector = "Canceled";
        break;
      default:
        selector = "Canceled";
        break;
    }

    izitoast.show({
      timeout: 20000,
      close: true,
      overlay: true,
      icon: "fa fa-question",
      titleLineHeight: "40px",
      title: "Hi Admin Satukebaikan",
      message: "Perbarui status kontribusi ke " + selector + "?",
      position: "center",
      backgroundColor: "rgb(35, 203, 245)",
      titleColor: "#fff",
      color: "#fff",
      iconColor: "#fff",
      messageColor: "#fff",
      layout: 1,
      balloon: true,
      transitionIn: "bounceInLeft",
      progressBar: false,
      buttons: [
        [
          "<button class='button' style='background: #fff; border-color:rgb(35, 203, 245); color:rgb(35, 203, 245); float : right'>Ya, Perbarui sekarang!</button>",
          () => {

            firebase
              .database()
              .ref()
              .child("/campaign/" + pathname + "/contribute/" + key)
              .update({ status: selector });

            izitoast.destroy()

            izitoast.success({
              timeout: 3000,
              close: false,
              overlay: true,
              message: "Berhasil memperbarui status donasi",
              position: "center",
              backgroundColor: "#25d366",
              messageColor: "#fff",
              color: "#fff",
              iconColor: "#fff",
              layout: 1,
              balloon: true,
              icon: "fa fa-check",
              transitionIn: "bounceInLeft"
            })

            if (selector === "Approved") {

              axios.post("https://satukebaikan.com/sendEmail2", {
                email: email,
                donasi: donasi,
                judul: judul,
                bagikan: "https://www.facebook.com/sharer/sharer.php?u=http://satukebaikan.co.id/" + bagikan,
                nama: nama
              })
            }

          },
          true
        ]
      ]
    })

  }

  render() {

    const { campaign, status } = this.state

    const { picture, uid, name } = this.props.auth.data

    return (
      <div>
        {/* Load Navigation html Syntax */}
        <Navbar picture={picture} name={name} />
        {/*End*/}

        <div id="dashboard">
          {/* Sidebar*/}
          <Sidebar uid={uid} />
          {/* End Sidebar*/}

          {/*Content*/}
          <div className="dashboard-content">
            {/* title bar */}
            <div id="titlebar">
              <div className="row">
                <div className="col-md-12">
                  <h2>Donatur</h2>

                  <nav id="breadcrumbs">
                    <ul>
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li>Donatur</li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            {/* end title bar */}

            <div className="row">
              <div
                hidden={!this.state.isLoaded}
                className="col-lg-12 col-md-12"
              >
                <div className="dashboard-list-box margin-top-0">
                  {/* short by */}
                  <div className="sort-by">
                    <div className="sort-by-select" />
                  </div>

                  <h4 className="clearfix">
                    <div className="panel-dropdown wide float-right">
                      <a href="#!">Filters</a>
                      <div className="panel-dropdown-content checkboxes clearfix">
                        <div className="row">

                          <div className="col-md-12" style={{ padding: "0" }}>

                            <div className="input-with-icon medium-icons">
                              <input
                                id="autocomplete-input"
                                type="text"
                                placeholder="Cari berdasarkan email"
                                onChange={this.searchDonatur.bind(this)}
                              />
                              <i className="im im-icon-Mail-Search" />
                            </div>

                            <div className="input-with-icon medium-icons">
                              <select value={status} onChange={this.filterStatus.bind(this)} style={{ padding: "0 20px 0 45px" }}>
                                <option value="semua status">Semua status</option>
                                <option value="pending">Pending</option>
                                <option value="approved">Approved</option>
                                <option value="canceled">Canceled</option>
                              </select>
                              <i className="im im-icon-Box-withFolders" />
                            </div>

                          </div>

                          <div className="panel-buttons">
                            <button style={{border : "1px solid #23cbf5", borderRadius: "5px", background : "#fff", color : "#23cbf5"}} className="panel-cancel button">Keluar</button>
                          </div>

                        </div>
                      </div>
                    </div>

                  </h4>

                  {Object.keys(campaign)
                    .reverse()
                    .map((item, i) => (
                      <ul className="list-donatur" key={i}>
                        {!campaign[item].contribute ? (
                          null
                        ) : (
                            Object.keys(campaign[item].contribute)
                              .reverse()
                              .map((key, i) => (
                                <li
                                  key={i}
                                  className={
                                    campaign[item].contribute[
                                      key
                                    ].status.toLocaleLowerCase() +
                                    "-booking items"
                                  }
                                  name={campaign[item].contribute[
                                    key
                                  ].status.toLocaleLowerCase()}
                                >
                                  <div className="list-box-listing bookings">
                                    <div className="list-box-listing-img">
                                      <img
                                        src={
                                          "https://www.gravatar.com/avatar/" +
                                          md5(
                                            campaign[item].contribute[key].email
                                          ) +
                                          "?d=wavatar"
                                        }
                                        alt=""
                                      />
                                    </div>
                                    <div className="list-box-listing-content">
                                      <div className="inner">
                                        <h3>
                                          {campaign[item].title}{" "}
                                          <span className="booking-status">
                                            {
                                              campaign[item].contribute[key]
                                                .status
                                            }
                                          </span>
                                        </h3>

                                        <div className="inner-booking-list">
                                          <h5>Tanggal Donasi: &nbsp;</h5>
                                          <ul className="booking-list">
                                            <li className="highlighted">
                                              {campaign[item].contribute[key].date
                                                ? dateFormat(
                                                  campaign[item].contribute[key]
                                                    .date,
                                                  "dd mmm yyyy H:MM"
                                                )
                                                : ""}
                                            </li>
                                          </ul>
                                        </div>

                                        <div className="inner-booking-list">
                                          <h5>Jumlah donasi: &nbsp;</h5>
                                          <ul className="booking-list">
                                            <li className="highlighted">
                                              Rp.
                                            {(
                                                parseInt(
                                                  campaign[item].contribute[key]
                                                    .donation,
                                                  0
                                                ) +
                                                parseInt(
                                                  campaign[item].contribute[key]
                                                    .uniqueCode,
                                                  0
                                                )
                                              ).toLocaleString()}
                                            </li>
                                          </ul>
                                        </div>

                                        <div className="inner-booking-list">
                                          <h5>Nama: &nbsp;</h5>
                                          <ul className="booking-list">
                                            <li>
                                              {
                                                campaign[item].contribute[key]
                                                  .name
                                              }
                                            </li>
                                            <li>
                                              {
                                                campaign[item].contribute[key]
                                                  .email
                                              }
                                            </li>
                                            <li>
                                              {
                                                campaign[item].contribute[key]
                                                  .phone
                                              }
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="buttons-to-right">
                                    {campaign[item].contribute[
                                      key
                                    ].status.toLocaleLowerCase() ===
                                      "approved" ? (
                                        <button
                                          onClick={this.updateStatusHandler.bind(
                                            this,
                                            item,
                                            key,
                                            campaign[item].contribute[key].email,
                                            (
                                              parseInt(
                                                campaign[item].contribute[key]
                                                  .donation,
                                                0
                                              ) +
                                              parseInt(
                                                campaign[item].contribute[key]
                                                  .uniqueCode,
                                                0
                                              )
                                            ).toLocaleString(),
                                            campaign[item].title,
                                            item,
                                            campaign[item].contribute[key].name
                                          )}
                                          className="button gray reject"
                                          name="Cancel"
                                        >
                                          <i className="sl sl-icon-close" /> Cancel
                                    </button>
                                      ) : campaign[item].contribute[
                                        key
                                      ].status.toLocaleLowerCase() ===
                                        "canceled" ? (
                                          <button
                                            onClick={this.updateStatusHandler.bind(
                                              this,
                                              item,
                                              key,
                                              campaign[item].contribute[key].email,
                                              (
                                                parseInt(
                                                  campaign[item].contribute[key]
                                                    .donation,
                                                  0
                                                ) +
                                                parseInt(
                                                  campaign[item].contribute[key]
                                                    .uniqueCode,
                                                  0
                                                )
                                              ).toLocaleString(),
                                              campaign[item].title,
                                              item,
                                              campaign[item].contribute[key].name
                                            )}
                                            name="Approve"
                                            className="button gray approve"
                                          >
                                            <i className="sl sl-icon-check" /> Approve
                                    </button>
                                        ) : (
                                          <div>
                                            <button
                                              onClick={this.updateStatusHandler.bind(
                                                this,
                                                item,
                                                key,
                                                campaign[item].contribute[key].email,
                                                (
                                                  parseInt(
                                                    campaign[item].contribute[key]
                                                      .donation,
                                                    0
                                                  ) +
                                                  parseInt(
                                                    campaign[item].contribute[key]
                                                      .uniqueCode,
                                                    0
                                                  )
                                                ).toLocaleString(),
                                                campaign[item].title,
                                                item,
                                                campaign[item].contribute[key].name
                                              )}
                                              className="button gray reject"
                                              name="Cancel"
                                            >
                                              <i className="sl sl-icon-close" />{" "}
                                              Cancel
                                      </button>
                                            <button
                                              onClick={this.updateStatusHandler.bind(
                                                this,
                                                item,
                                                key,
                                                campaign[item].contribute[key].email,
                                                (
                                                  parseInt(
                                                    campaign[item].contribute[key]
                                                      .donation,
                                                    0
                                                  ) +
                                                  parseInt(
                                                    campaign[item].contribute[key]
                                                      .uniqueCode,
                                                    0
                                                  )
                                                ).toLocaleString(),
                                                campaign[item].title,
                                                item,
                                                campaign[item].contribute[key].name
                                              )}
                                              name="Approve"
                                              className="button gray approve"
                                            >
                                              <i className="sl sl-icon-check" />{" "}
                                              Approve
                                      </button>
                                          </div>
                                        )}
                                  </div>
                                </li>
                              ))
                          )}
                      </ul>
                    ))}
                </div>
              </div>

              <center hidden={this.state.isLoaded}>
                <br />
                <br />
                <div className="lds-dual-ring" />
                <br />
                <br />
                <br />
              </center>

              <div className="col-md-12">
                <div className="copyrights">
                  © 2018 Satukebaikan indonesia. All Rights Reserved.
                </div>
              </div>
            </div>

            {/*End*/}
          </div>
        </div>
      </div>
    );
  }
}

export default Kontributor;
