import React, { Component } from "react"
import dateFormat from "dateformat"
import firebase from "firebase/app"
import "firebase/database"
import axios from "axios"
import Edotensei from "edotensei"
import izitoast from "izitoast"
import "izitoast/dist/css/iziToast.min.css"
import ReactGA from 'react-ga'


import Footer from "../includes/non-admin-components/footer"
import Navigation from "../includes/non-admin-components/navigation"

const { add } = Edotensei;

const Scripts = [{ src: "/scripts/jquery-2.2.0.min.js", rel: "preload" }, { src: "/scripts/mmenu.min.js", defer: true }, { src: "/scripts/chosen.min.js", defer: true }, { src: "/scripts/slick.min.js", defer: true }, { src: "/scripts/rangeslider.min.js", defer: true }, { src: "/scripts/magnific-popup.min.js", defer: true }, { src: "/scripts/waypoints.min.js", defer: true }, { src: "/scripts/counterup.min.js", defer: true }, { src: "/scripts/tooltips.min.js", defer: true }, { src: "/scripts/custom.js", defer: true }]

/**
 * make a generate uid string function
 */
const makeId = () => {
  var text = "";
  var possible = "0123456789101112131415161718192021222324252627282930";

  for (var i = 0; i < 20; i++)  text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};

/**
 * upload data
 */
const createData = (id, data, pathname) => {
  firebase
    .database()
    .ref("/campaign/" + id + "/contribute")
    .push()
    .set(data, err => {
      /**
       * cek error
       */
      if (err) return alert(err.message);
      /**
       * redirect to
       */
      window.location.href = pathname;
    });
};

/**
 * read data
 */
const readData = (path, callback) => {
  firebase
    .database()
    .ref()
    .child(path)
    .on("value", snap => {

      // redirect to 404 when data not found
      if (!snap.exists()) return (window.location.href = "/404")

      callback(snap.val())
    })
}

class Donasi extends Component {
  constructor(props) {
    super(props)

    this.state = {
      //json form
      judulCampaign: "",
      gambarCampaign: "",
      lokasiCampaign: "",
      donasi: "",
      kodeUnik: 0,
      komentar: "",
      potongan: 0,
      nama: props.auth.data ? props.auth.data.name : "",
      email: props.auth.data ? props.auth.data.email : "",
      telepon: props.auth.data ? props.auth.data.phone_number.replace("+62", "") : "",
      metodePembayaran: "bni",

      //json function
      anonim: false,
      nextStep: false,
      isUploading: false,
      isLoaded: false
    };
    this.handleInput = this.handleInput.bind(this);
  }

  componentDidMount() {

    add(Scripts);

    const key = this.props.match.params.id;

    ReactGA.pageview("/kontribusi/" + key)

  }

  /**
   * dieksekusi ketika html selesai dirender
   */
  componentWillMount() {

    const { uid } = this.props.auth ? this.props.auth.data : false

    const val = uid === process.env.REACT_APP_SUPER_ADMIN_UID ? 0 : Math.floor(Math.random() * 999)

    this.setState({ kodeUnik: val });

    const key = this.props.match.params.id;

    readData("/campaign/" + key, snap => {

      firebase.database().ref("/tags").orderByChild("title").equalTo(snap.category).once("value", snapshot => {

        const piece = snapshot.exists() ? Object.values(snapshot.val())[0].piece : 5

        snap.piece = piece

        this.setState({
          judulCampaign: snap.title,
          gambarCampaign: snap.image,
          lokasiCampaign: snap.location,
          potongan: snap.piece,
          isLoaded: true
        })
      })

      this.setState({ isLoaded: true })

    })
  }

  handleInput(e) {

    this.setState({
      [e.target.name]: e.target.value
    });
  }

  /**
   * comment handler
   * @param {event} e
   */
  handleComment(e) {
    /**
     * cek apakah panjangnya masih lebih kecil dari 140
     */
    if (e.target.value.length >= 141) return;
    /**
     * update state
     */
    this.setState({
      komentar: e.target.value
    });
  }

  handlePhone(e) {
    /**
     * parse ke int
     */
    let phone = parseInt(e.target.value, 0);
    /**
     * cek apakah valuenya NAN atau tidak
     */
    if (isNaN(phone)) {
      this.setState({ telepon: "" });
      return;
    }
    /**
     * update state
     */
    this.setState({ telepon: phone.toString() });
  }

  /**
   * handle anonim donation
   */
  handleAnonim() {

    this.setState({ anonim: !this.state.anonim });
  }

  /*
      Handle next step
    */
  handleNextStep(e) {

    e.preventDefault();

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    const donation = parseInt(this.state.donasi.replace(/\.|,/gi, ""), 0) + parseInt(this.state.kodeUnik, 0);

    if (donation <= 9999 || isNaN(donation)) return izitoast.info({

      title: "Donasi minimal 10.000 atau lebih banyak lebih baik.",
      position: "topRight",
      backgroundColor: "rgb(35, 203, 245)",
      titleColor: "#fff",
      color: "#fff",
      iconColor: "#fff",
      progressBarColor: "#fff",
      layout: 2,
      balloon: true,
      icon: "fa fa-info-circle",
      progressBar: false,
      displayMode: 2
    })

    this.setState({ isLoaded: false });

    setTimeout(() => {

      this.setState({ isLoaded: true, nextStep: !this.state.nextStep });

      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

    }, 500);

    setTimeout(() => {

      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

    }, 1000);


  }

  /**
   * format currancy
   * @param {event} e
   */
  formateCurrancy(e) {

    let { value } = e.target;

    value = value.replace(new RegExp(",", "g"), "");
    value = value.replace(/\./g, "");

    value = parseInt(value, 0);

    if (isNaN(value)) return this.setState({ donasi: "" });

    const valueToLocale = value.toLocaleString();

    this.setState({ donasi: valueToLocale })
  }

  handleSubmit(e) {

    e.preventDefault();

    if (!this.state.nextStep) return

    const donation = parseInt(this.state.donasi.replace(/\.|,/gi, ""), 0) + parseInt(this.state.kodeUnik, 0);

    if (donation <= 9999 || isNaN(donation)) {

      izitoast.info({
        title: "Donasi minimal 10.000 atau lebih banyak lebih baik.",
        position: "topRight",
        backgroundColor: "rgb(35, 203, 245)",
        titleColor: "#fff",
        color: "#fff",
        iconColor: "#fff",
        progressBarColor: "#fff",
        layout: 2,
        balloon: true,
        icon: "fa fa-info-circle",
        progressBar: false,
        displayMode: 2
      })

      return
    }

    const layout = window.screen.availWidth < 991 ? 1 : 2

    console.log(this.state)

    izitoast.show({
      timeout: 20000,
      close: true,
      overlay: true,
      // image: this.state.gambarCampaign,
      // imageWidth: 200,
      // icon: "fa fa-info-circle",
      title: "Halo baikers (orang baik)",
      titleLineHeight: "40px",
      message: "Apakah informasi yang anda masukkan sudah benar?",
      position: "center",
      backgroundColor: "rgb(35, 203, 245)",
      titleColor: "#fff",
      color: "#fff",
      iconColor: "#fff",
      messageColor: "#fff",
      layout: layout,
      balloon: true,
      transitionIn: "bounceInLeft",
      progressBar: false,
      buttons: [
        [
          "<button class='button' style='background: #fff; border-color:rgb(35, 203, 245); color:rgb(35, 203, 245); float : right'>Ya, Sudah Benar</button>",
          () => {

            izitoast.destroy();

            this.confrimHandler(this);
          },
          true
        ]
      ]
    });
  }

  async confrimHandler() {

    izitoast.info({
      timeout: 50000,
      close: false,
      title: "Sedang menyiapkan pembayaran",
      overlay: true,
      position: "center",
      drag: false,
      backgroundColor: "rgb(35, 203, 245)",
      titleColor: "#fff",
      color: "#fff",
      iconColor: "#fff",
      layout: 2,
      balloon: true,
      icon: "fa fa-info-circle",
      transitionIn: "bounceInLeft"
    })

    this.setState({ isUploading: true });

    const { id } = this.props.match.params;

    const data = {
      campaignTitle: this.state.judulCampaign,
      donation: parseInt(this.state.donasi.replace(/\.|,/gi, ""), 0),
      uniqueCode: this.state.kodeUnik,
      description: this.state.komentar,
      isAnonim: this.state.anonim,
      name: this.state.nama,
      email: this.state.email,
      phone: this.state.telepon,
      payment: this.state.metodePembayaran,
      date: firebase.database.ServerValue.TIMESTAMP,
      uid: makeId(),
      campaign: id,
      expired: dateFormat(new Date().setDate(new Date().getDate() + 1), "dd/mm/yyyy"),
      status: "Pending"
    };

    ReactGA.event({
      category: "donasi",
      action: "donasi untuk " + id
    })

    // try {

    //   const response = await axios.post("https://satukebaikan.com/payment", { data })

    //   izitoast.destroy()

    //   izitoast.success({
    //     timeout: 3000,
    //     close: false,
    //     overlay: true,
    //     message: "Segera lakukan pembayaran sesuai metode pembayaran yang anda pilih.",
    //     position: "center",
    //     backgroundColor: "#25d366",
    //     messageColor: "#fff",
    //     color: "#fff",
    //     iconColor: "#fff",
    //     layout: 2,
    //     balloon: true,
    //     icon: "fa fa-check",
    //     transitionIn: "bounceInLeft",
    //     onClosing: () => window.location.href = response.data.invoice_url
    //   })

    // } catch (error) {

    //   console.log(error)
    // }

    /**
     * set redirect path
     */
    const redirect = "/ringkasan/" + data.uid + "/" + id + "?summary";
    /**
     * get date
     */
    let expired = new Date();

    expired = expired.setDate(expired.getDate() + 1);

    axios
      .post("https://satukebaikan.com/sendEmail", {
        idDonasi: data.uid.substr(0, 4),
        tanggal: dateFormat(new Date(), "dd/mm/yyyy"),
        donasi: data.donation,
        kodeUnik: data.uniqueCode,
        email: data.email,
        nama: data.name,
        masaBerlaku: dateFormat(expired, "dd/mm/yyyy"),
        judul: this.state.judulCampaign
      })
      .then(res => {
        //desttroy
        izitoast.destroy();
        /**
         * insert data to database
         */
        izitoast.success({
          timeout: 3000,
          close: false,
          overlay: true,
          message: "Segera lakukan pembayaran sesuai metode pembayaran yang anda pilih.",
          position: "center",
          backgroundColor: "#25d366",
          messageColor: "#fff",
          color: "#fff",
          iconColor: "#fff",
          layout: 2,
          balloon: true,
          icon: "fa fa-check",
          transitionIn: "bounceInLeft",
          onClosing: () => {
            createData(id, data, redirect);
          }
        });
      })
      .catch(err => {
        //desttroy
        izitoast.destroy();
        /**
         * insert data to database
         */
        izitoast.success({
          timeout: 2000,
          close: false,
          overlay: true,
          title: "Terimakasih",
          message: "Segera lakukan pembayaran.",
          position: "center",
          onClosing: () => {
            createData(id, data, redirect);
          }
        });
      });
  }

  /**
   * handle payment method
   * radio button on change
   */
  handlePaymentMethod(e) {
    this.setState({ metodePembayaran: e.target.value });
  }

  render() {

    const { name, picture } = this.props.auth.data || { name: undefined, picture: undefined }

    return (
      <div>
        {/* Load Navigation html Syntax */}
        <Navigation auth={{ name, picture }} />
        {/*End*/}

        {/* Title bar*/}

        {/*End title*/}

        {/*splash*/}
        {/* <center hidden={this.state.isLoaded} className="vertical-center">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <br />
          <br />
          <img style={{ width: "200px" }} src="/images/logo-a.png" alt="satukebaikan" />
        </center> */}
        {/*End splash*/}

        {/* Content*/}
        <div >
          <br />
          <div className="container">
            <form id="form-contribute" onSubmit={this.handleSubmit.bind(this)}>
              <div className="row">
                <div className="col-lg-8 col-md-8 padding-right-30">
                  <div hidden={this.state.nextStep}>
                    <h3 className="margin-top-0 margin-bottom-30">
                      Donasi Anda
                    </h3>
                    <div className="row">
                      <div className="col-md-12 col-sm-12">
                        <div className="input-with-icon medium-icons">
                          <label style={{ marginBottom: "-5px" }}>
                            Masukkan Nominal
                          </label>
                          <p style={{ fontSize: "12px", margin: "0" }}>
                            Donasi minimal Rp.10.000 dengan kelipatan ribuan
                            (contoh: 15.000, 50.000)
                          </p>

                          <div className="row" style={{ margin: "0", overflowX: "auto" }}>
                            <table width="100%" style={{ border: "none", margin: "0" }}>
                              <thead>
                                <tr>
                                  <td>
                                    <button onClick={e => this.setState({ donasi: "50,000" })} type="button" style={{ width: "95%", borderRadius: "5px", fontFamily: "Open Sans", background: "#fff", color: "#23cbf5", border: "1px solid #23cbf5", marginTop: "3px" }} className="button fullwidth">
                                      Rp. 50.000
                                    </button>
                                  </td>

                                  <td>
                                    <button onClick={e => this.setState({ donasi: "100,000" })} type="button" style={{ width: "95%", borderRadius: "5px", fontFamily: "Open Sans", background: "#fff", color: "#23cbf5", border: "1px solid #23cbf5", marginTop: "3px" }} className="button fullwidth">
                                      Rp. 100.000
                                    </button>
                                  </td>

                                  <td>
                                    <button onClick={e => this.setState({ donasi: "125,000" })} type="button" style={{ width: "95%", borderRadius: "5px", fontFamily: "Open Sans", background: "#fff", color: "#23cbf5", border: "1px solid #23cbf5", marginTop: "3px" }} className="button fullwidth">
                                      Rp. 125.000
                                    </button>
                                  </td>

                                  <td>
                                    <button onClick={e => this.setState({ donasi: "200,000" })} type="button" style={{ width: "95%", borderRadius: "5px", fontFamily: "Open Sans", background: "#fff", color: "#23cbf5", border: "1px solid #23cbf5", marginTop: "3px" }} className="button fullwidth">
                                      Rp. 200.000
                                    </button>
                                  </td>

                                  <td>
                                    <button onClick={e => this.setState({ donasi: "500,000" })} type="button" style={{ width: "95%", borderRadius: "5px", fontFamily: "Open Sans", background: "#fff", color: "#23cbf5", border: "1px solid #23cbf5", marginTop: "3px" }} className="button fullwidth">
                                      Rp. 500.000
                                    </button>
                                  </td>
                                </tr>
                              </thead>
                            </table>
                          </div>

                          <p style={{ fontSize: "12px", marginBottom: "0" }}>Atau angka lainnya</p>

                          <input
                            type="text"
                            style={{ fontSize: "24px", fontFamily: "Open Sans" }}
                            onChange={this.formateCurrancy.bind(this)}
                            value={this.state.donasi}
                            name="donasi"
                            required
                          />
                          <i style={{ fontStyle: "normal" }}>
                            <b>Rp</b>
                          </i>
                        </div>
                      </div>
                      <div className="col-md-12 margin-top-15">
                        <label style={{ marginBottom: "-5px" }}>
                          Tulis komentar atau Doa untuk mendukung kampanye (opsional)
                        </label>
                        <p style={{ fontSize: "12px", margin: "0" }}>
                          Text saja, tanpa URL/kode HTML
                        </p>
                        <textarea
                          className="WYSIWYG"
                          name="komentar"
                          cols="40"
                          rows="3"
                          id="summary"
                          spellCheck="true"
                          onChange={this.handleComment.bind(this)}
                          value={this.state.komentar}
                        />
                        <p
                          style={{
                            fontSize: "12px",
                            textAlign: "right",
                            margin: "0"
                          }}
                        >
                          {this.state.komentar.length} / 140 karakter
                        </p>

                      </div>
                    </div>
                  </div>

                  {/* Pembayaran */}
                  <div
                    hidden={!this.state.nextStep}
                  >
                    <h3 className="margin-top-0 margin-bottom-30">
                      Identitas Anda
                    </h3>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-with-icon medium-icons">
                          <label>Nama Lengkap</label>
                          <input
                            type="text"
                            name="nama"
                            onChange={this.handleInput}
                            value={this.state.nama}
                            required
                          />
                          <i className="im im-icon-User" />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-with-icon medium-icons">
                          <label>Email</label>
                          <input
                            type="email"
                            name="email"
                            onChange={this.handleInput}
                            value={this.state.email}
                            required
                          />
                          <i className="im im-icon-Mail" />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="input-with-icon medium-icons">
                          <label>
                            Masukkan nomor HP anda untuk menerima SMS atau
                            donasi
                          </label>
                          <input
                            style={{ fontFamily: "Open Sans" }}
                            type="text"
                            placeholder="format 823xxx  (angka saja)"
                            name="telepon"
                            onChange={this.handlePhone.bind(this)}
                            value={this.state.telepon}
                            required
                          />
                          <i style={{ fontStyle: "normal", fontSize: "16px" }}>
                            +62
                          </i>
                        </div>

                        <div className="checkboxes margin-top-10">
                          <input
                            id="anonim"
                            type="checkbox"
                            name="anonim"
                            defaultChecked={this.state.anonim}
                            onClick={this.handleAnonim.bind(this)}
                          />
                          <label
                            htmlFor="anonim"
                          >
                            Sembunyikan Identitas saya (anonim)
                          </label>
                        </div>
                      </div>
                    </div>

                    <h3 className="margin-top-55 margin-bottom-30">
                      Pilih Metode Pembayaran
                    </h3>

                    <div className="payment">
                      {/* Transfer BNI */}
                      <div className="payment-tab payment-tab-active">
                        <div className="payment-tab-trigger">
                          <input
                            defaultChecked
                            id="bni"
                            name="cardType"
                            type="radio"
                            value="bni"
                            onChange={this.handlePaymentMethod.bind(this)}
                          />
                          <label htmlFor="bni">
                            Transfer BANK SYARIAH INDONESIA (BSI)<br />
                          </label>
                          <img
                            className="payment-logo"
                            width="150"
                            src="https://1.bp.blogspot.com/-whCKrPGUY80/YCK2KdGONrI/AAAAAAAAD34/FD0JXqUg5Fk8fAqgQcqLrTFwTRdSnmDUgCLcBGAsYHQ/s1600/Logo%2BBSI%2B%2528Bank%2BSyariah%2BIndonesia%2529.png"  //"https://upload.wikimedia.org/wikipedia/id/thumb/5/55/BNI_logo.svg/1024px-BNI_logo.svg.png"
                            alt=""
                          />
                        </div>

                        <div className="payment-tab-content">
                          <p>
                            Kode Bank : <b>451</b>
                            <br />
                            No. Rekening : <b>3033 3033 47</b>
                            <br />
                            Atas Nama : <b>YAYASAN SATUKEBAIKAN</b>
                            <br />
                          </p>
                        </div>
                      </div>
                      {/* End Transfer BNI*/}

                      {/* Transfer BRI */}
                      {/* <div className="payment-tab">
                        <div className="payment-tab-trigger">
                          <input
                            type="radio"
                            name="cardType"
                            id="bri"
                            value="bri"
                            onChange={this.handlePaymentMethod.bind(this)}
                          />
                          <label htmlFor="bri">Transfer BRI</label>
                          <img
                            className="payment-logo"
                            width="100"
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/68/BANK_BRI_logo.svg/1280px-BANK_BRI_logo.svg.png"
                            alt=""
                          />
                        </div>
                      </div> */}
                      {/* End Transfer BRI */}

                      {/* Transfer Mandiri */}
                      {/* <div className="payment-tab">
                        <div className="payment-tab-trigger">
                          <input
                            type="radio"
                            name="cardType"
                            id="mandiri"
                            value="mandiri"
                            onChange={this.handlePaymentMethod.bind(this)}
                          />
                          <label htmlFor="mandiri">Transfer Mandiri</label>
                          <img
                            className="payment-logo"
                            width="100"
                            src="https://upload.wikimedia.org/wikipedia/id/thumb/f/fa/Bank_Mandiri_logo.svg/1280px-Bank_Mandiri_logo.svg.png"
                            alt=""
                          />
                        </div>
                      </div> */}
                      {/* End Transfer Mandiri */}

                      {/* Transfer BCA */}
                      {/* <div className="payment-tab">
                        <div className="payment-tab-trigger">
                          <input
                            type="radio"
                            name="cardType"
                            id="bca"
                            value="bca"
                            onChange={this.handlePaymentMethod.bind(this)}
                          />
                          <label htmlFor="bca">Transfer BCA</label>
                          <img
                            className="payment-logo"
                            width="70"
                            src="https://bank-photos.s3.amazonaws.com/logo_BCA_2016.png"
                            alt=""
                          />
                        </div>
                      </div> */}
                      {/* End Transfer bca */}

                      {/* alfamart */}
                      {/* <div className="payment-tab">
                        <div className="payment-tab-trigger">
                          <input
                            type="radio"
                            name="cardType"
                            id="alfamart"
                            value="alfamart"
                            onChange={this.handlePaymentMethod.bind(this)}
                          />
                          <label htmlFor="alfamart">Bayar di Alfamart/Alfamidi</label>
                          <img
                            className="payment-logo"
                            width="80"
                            src="https://dashboard.xendit.co/images/alfamart.png"
                            alt=""
                          />
                        </div>
                      </div> */}
                      {/* alfamart*/}
                    </div>
                  </div>
                  {/* end Pembayaran */}
                  <br />
                </div>

                <div className="col-lg-4 col-md-4 margin-top-0 margin-bottom-60">
                  <div className="listing-item-container compact order-summary-widget">
                    <div id="listing-item" className="listing-item">
                      <img
                        src={this.state.gambarCampaign}
                        alt={this.state.judulCampaign}
                      />

                      <div className="listing-item-content">
                        <h3>{this.state.judulCampaign}</h3>
                        <span>{this.state.lokasiCampaign}</span>
                      </div>
                    </div>
                  </div>
                  <div className="boxed-widget opening-hours summary margin-top-0">
                    <h3>
                      <i className="sl sl-icon-user-following" /> Rincian Donasi
                    </h3>
                    <ul>
                      <li>
                        Tanggal{" "}
                        <span>{dateFormat(new Date(), "dd mmmm yyyy")}</span>
                      </li>
                      <li>
                        Kode Unik <span>Rp. {this.state.kodeUnik}</span>
                      </li>
                      <li>
                        Donasi{" "}
                        <span>
                          Rp.{" "}
                          {
                            isNaN(parseInt(this.state.donasi.replace(/\.|,/gi, ""), 0))
                              ? 0
                              : parseInt(this.state.donasi.replace(/\.|,/gi, ""), 0).toLocaleString()
                          }
                        </span>
                      </li>
                      <li className="total-costs">
                        Total{" "}
                        <span>
                          Rp.{" "}
                          {isNaN(
                            parseInt(this.state.donasi.replace(/\.|,/gi, ""), 0)
                          )
                            ? 0
                            : (parseInt(this.state.donasi.replace(/\.|,/gi, ""), 0) + this.state.kodeUnik).toLocaleString()}
                        </span>
                        <br />
                        <br />
                        <i style={{ fontSize: "10px" }}>
                          *Kode Unik Akan didonasikan
                        </i>
                      </li>
                      <li className="total-costs">
                        <center>
                          <div hidden={!this.state.nextStep}>
                            <button
                              style={{ marginTop: "15px", background: "#fff", border: "1px solid #23cbf5", color: "#23cbf5", borderRadius: "5px" }}
                              type="submit"
                              className="button border booking-confirmation-btn margin-top-10 fullwidth"

                            >
                              Lanjutkan
                            </button>
                          </div>

                          <button
                            type="button"
                            className="button booking-confirmation-btn margin-top-10 fullwidth"
                            style={this.state.nextStep
                              ? { background: "#fff", marginTop: "15px", border: "1px solid rgb(255, 81, 0)", color: "rgb(255, 81, 0)", borderRadius: "5px" }
                              : { marginTop: "15px", background: "#fff", border: "1px solid #23cbf5", color: "#23cbf5", borderRadius: "5px" }}
                            onClick={this.handleNextStep.bind(this)}
                          >
                            {this.state.nextStep ? "Kembali" : "Lanjut ke pembayaran"}
                          </button>{" "}
                        </center>
                      </li>
                    </ul>
                  </div>

                </div>
              </div>
            </form>
          </div>

          {/* End Content*/}

          {/* Load footer html Syntax */}
          <Footer />
        </div>
      </div>
    );
  }
}

export default Donasi;
