import React, { Component } from "react"
import firebase from "firebase/app"
import "firebase/functions"
import LazyLoad from "react-lazyload"

import Placeholder from "../../includes/non-admin-components/placeholder"

const totalDonasi = arr => {

  let value = 0

  arr = arr.map(i => i.status.toLowerCase() === "approved" ? i : null)

  for (let i in arr) {

    if (arr[i]) {

      value += (arr[i].donation + arr[i].uniqueCode)

    }
  }

  return value
}

/**
 * conver array to object
 * @param arr
 */
const arrayToObject = (arr, item) => {

  var rv = {}

  for (var i = 0; i < arr.length; i++) {
    rv[arr[i]] = item[arr[i]];
  }

  return rv;
};

const randomArray = (arr, item) => {

  if (arr.length < 1) return []

  let ctr = arr.length
  let temp
  let index

  // While there are elements in the array
  while (ctr > 0) {
    // Pick a random index
    index = Math.floor(Math.random() * ctr)
    // Decrease ctr by 1
    ctr--
    // And swap the last element with it
    temp = arr[ctr]

    arr[ctr] = arr[index]

    arr[index] = temp
  }

  return arrayToObject(arr, item);
}

class CampaignAllitem extends Component {

  constructor(props) {
    super(props);

    this.state = {
      campaign: {},
      item: {},
      isLoaded: false,
      endSlice: 9
    }
  }

  async componentWillMount() {

    const getAllCampaign = firebase.functions().httpsCallable("getAllCampaign")

    const snap = await getAllCampaign()

    const value = randomArray(Object.keys(snap.data), snap.data)

    const { search } = this.props

    let result = Object.keys(value).filter(i => {

      return value[i].title.toLowerCase().search(search.toLowerCase()) !== -1

    })

    result = arrayToObject(result, value)

    this.setState({ campaign: result, item: result, isLoaded: true })
  }

  /**
   * dieksekusi ketika component menerima props
   * @param nextProps
   */
  componentWillReceiveProps(nextProps) {

    if (!this.state.isLoaded) return;

    if (nextProps === this.props) return

    let campaign = this.state.item

    let { category, regency, sort } = nextProps.filter

    category = category.toLowerCase() !== "semua kategori" ? category.toLowerCase() : ""

    regency = regency.toLowerCase() !== "semua" ? regency.toLowerCase() : ""

    sort = sort.toLowerCase() !== "acak" ? sort.toLowerCase() : ""

    let result = Object.keys(campaign).filter(i => {

      return campaign[i].category.toLowerCase().search(category) !== -1
    })

    campaign = arrayToObject(result, campaign)

    result = Object.keys(campaign).filter(i => {

      return campaign[i].location.toLowerCase().search(regency) !== -1
    })

    campaign = arrayToObject(result, campaign)

    const sortDateDesc = (a, b) => {

      const dateA = Date.parse(new Date(campaign[a].date))

      const dateB = Date.parse(new Date(campaign[b].date))

      if (dateA > dateB) return 1

      if (dateA < dateB) return -1

      return 0
    }

    const sortDateAsc = (a, b) => {

      const dateA = Date.parse(new Date(campaign[a].date))

      const dateB = Date.parse(new Date(campaign[b].date))

      if (dateA > dateB) return -1

      if (dateA < dateB) return 1

      return 0
    }

    switch (sort) {
      case "terbaru":
        result = Object.keys(campaign).sort(sortDateDesc)
        break;

      case "terlama":
        result = Object.keys(campaign).sort(sortDateAsc)
        break;

      default:
        break;
    }

    campaign = arrayToObject(result, campaign)

    this.setState({ campaign })

  }

  loadmoreHandler(end) {

    this.setState({ endSlice: end + 6 });
  }

  render() {

    const { campaign, isLoaded, endSlice } = this.state;

    const totalPage = Object.keys(campaign).length;

    return !isLoaded
      ? <center className="vertical-center" hidden={isLoaded}>
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <br />
        <br />
        <img style={{ width: "200px" }} src="/images/logo-a.png" alt="satukebaikan" />
      </center>
      : <div>

        <center hidden={!isLoaded || Object.keys(campaign).length > 0}>
          <h3>Campaign Tidak Ditemukan</h3>
        </center>

        {
          Object.keys(campaign).slice(0, endSlice).map((item, i) => (

            <div key={i} className="carousel-item col-md-4" style={{ paddingLeft: "0" }}>

              <a
                href={"/" + item}
                className="listing-item-container"
                style={{
                  boxShadow: "0 0 5px 1px #ececec",
                  background: "white"
                }}
              >
                {/**listing item */}
                <div style={{ height: "230px" }} className="listing-item">
                  <LazyLoad placeholder={<Placeholder />}>
                    <img src={campaign[item].image} alt={campaign[item].title} />
                  </LazyLoad>
                </div>

                <div className="star-rating" style={{ padding: "0 16px" }} >
                  <div className="row" style={{ minHeight: "224.32px", position: "relative" }}>
                    <div className="col-md-12">
                      <h3 style={{ fontSize: "18px", lineHeight: "25px" }} >
                        {campaign[item].title}
                      </h3>
                      <br />
                    </div>

                    {/** card footer */}
                    <div style={{ marginBottom: "10px", position: "absolute", bottom: "0", width: "100%" }} className="col-md-12 clearfix">
                      <div className="clearfix">
                        <div style={{ width: "40px", height: "40px", borderRadius: "50%", backgroundImage: "url( " + campaign[item].ownerPhoto + " )", backgroundSize: "100% 100%", float: "left" }} className="tag" />
                        <span style={{ fontSize: "18px", marginLeft: "10px", color: "#ff5100", lineHeight: "20px", float: "left" }}>
                          <i style={{ display: "block" }} className="fa fa-check-circle" />
                          <span style={{ fontSize: "12px", color: "black" }}>
                            {" " + campaign[item].ownerName}
                          </span>
                        </span>
                        <div />
                      </div>
                      <hr style={{ marginBottom: "10px" }} />
                      <div style={{ float: "left", lineHeight: "1.5", fontFamily: "Open Sans", fontSize: "14px", fontStyle: "normal", fontVariant: "normal", fontWeight: "0" }} className="left">
                        <span style={{ fontSize: "12px", display: "block" }} >
                          Terkumpul
                              </span>
                        Rp.{" "}
                        {
                          campaign[item].contribute
                            ? (totalDonasi(Object.values(campaign[item].contribute)) + campaign[item].donationFromFundraiser).toLocaleString()
                            : 0
                        }
                      </div>
                      <div style={{ float: "right", textAlign: "right", fontSize: "14px", fontFamily: "Open Sans", fontStyle: "none", lineHeight: "1.5" }} className="right">
                        <span style={{ fontSize: "12px", display: "block" }} >
                          Sisa hari
                              </span>
                        {Date.parse(new Date(campaign[item].deadline)) < Date.parse(new Date())
                          ? "Selesai"
                          : Math.floor((Date.parse(campaign[item].deadline) - Date.parse(new Date())) / (1000 * 60 * 60 * 24) + 1)}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="progress-bar"
                  style={{ overflow: "hidden" }}
                >
                  <span
                    className="progress-bar-fill"
                    style={{
                      backgroundColor: "#ff5100",
                      width: Math.ceil((totalDonasi(Object.values(campaign[item].contribute || [])) + campaign[item].donationFromFundraiser) / parseInt(campaign[item].target, 0) * 100) + "%",
                    }}
                  />
                </div>
              </a>
            </div>
          ))
        }
        <div hidden={!isLoaded} className="col-md-12 centered-content">
          {
            totalPage > endSlice ? (
              <button
                onClick={this.loadmoreHandler.bind(this, endSlice)}
                className="button border margin-top-10 margin-bottom-10"
                style={{borderRadius: "5px"}}
              >
                Tampilkan lebih banyak
            </button>
            ) : null
          }
          <br />
          <br />
        </div>

      </div>
  }
}

export default CampaignAllitem;
