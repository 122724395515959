export default () => [
    {
        nama: 'WASDLABS',
        width : "100px",
        img: "https://darahkita.co.id/img/team1.png"
    },
    {
        nama: 'Darah Kita',
        width : "80px",
        img: "https://lh3.googleusercontent.com/TSThpWH5MFM56Xub2Psk3XCZKsOkmVCeYGRqKj5WYbo3_mCDHbl72olfFRGFaa8zDeCZ"
    },
    {
        nama: 'Aksi Cepat Tanggap',
        width : "200px",
        img: "https://www.trzcacak.rs/myfile/detail/410-4100300_act-png-logo-aksi-cepat-tanggap.png"
    },
]