export default () => [
    {
        nama: 'Muhammad Rizal Umar Sukma',
        jabatan: 'Founder',
        img: "https://aireworthvets.co.uk/wp-content/uploads/2018/07/team-placeholder.jpg"
    },
    {
        nama: 'Andi Dedi Zulkarnain Putra',
        jabatan: 'Co-Founder',
        img: "https://aireworthvets.co.uk/wp-content/uploads/2018/07/team-placeholder.jpg"
    },
    {
        nama: 'Uswatun Khasanah',
        jabatan: 'Co-Founder',
        img: "https://aireworthvets.co.uk/wp-content/uploads/2018/07/team-placeholder.jpg"
    },
    {
        nama: 'Ryan Fachryansyah',
        jabatan: 'Sekertaris',
        img: "https://aireworthvets.co.uk/wp-content/uploads/2018/07/team-placeholder.jpg"
    },
    {
        nama: 'Andi Arvan Adrian Dharwis',
        jabatan: 'Wakil Sekertaris',
        img: "https://aireworthvets.co.uk/wp-content/uploads/2018/07/team-placeholder.jpg"
    },
    {
        nama: 'Nurjanna, SE. M.AK',
        jabatan: 'Bendahara',
        img: "https://aireworthvets.co.uk/wp-content/uploads/2018/07/team-placeholder.jpg"
    },
    {
        nama: 'Nursalam',
        jabatan: 'Legal',
        img: "https://aireworthvets.co.uk/wp-content/uploads/2018/07/team-placeholder.jpg"
    },
    {
        nama: 'Renaldi Pranata',
        jabatan: 'Full Stack Programmer',
        img: "https://aireworthvets.co.uk/wp-content/uploads/2018/07/team-placeholder.jpg"
    },
    {
        nama: 'William Afif',
        jabatan: 'Backend Programmer',
        img: "https://aireworthvets.co.uk/wp-content/uploads/2018/07/team-placeholder.jpg"
    }
]