import React, { Component } from "react";
import iziToast from "izitoast";
import firebase from "firebase/app"
import "firebase/functions"

const updatePassword = async (newPassword, uid, name) => {

  iziToast.info({
    close: false,
    timeout: false,
    drag: false,
    overlay: true,
    position: "center",
    title: "Halo " + name,
    message: "mohon tunggu, sedang memperbarui password"
  });

  try {

    const updateUserPassword = firebase.functions().httpsCallable("updateUserPassword")

    const result = await updateUserPassword({ newPassword, uid })

    iziToast.destroy()

    iziToast.success({
      title: "Sukses",
      message: result.data.message,
      position: "topRight"
    });

  } catch (error) {

    iziToast.destroy()

    iziToast.error({
      title: "Gagal pemperbarui password",
      message: "coba lagi",
      position: "topRight"
    });

  }

};

class Password extends Component {
  constructor() {
    super();
    this.state = {
      newPassword: "",
      confirmNewPassword: ""
    };

    this.inputHandler = this.inputHandler.bind(this);
  }

  /**
   * handle input
   * @param {event} e
   */
  inputHandler(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  submitHandler(e) {

    e.preventDefault();

    const { uid, name } = this.props.info

    if (this.state.newPassword !== this.state.confirmNewPassword) {
      iziToast.error({
        title:
          "Hi " + name,
        message: "Password baru tidak cocok dengan konfirmasi password baru.",
        position: "topRight"
      });

      this.setState({
        newPassword: "",
        confirmNewPassword: ""
      });

      return;
    }

    //dialog
    iziToast.info({
      timeout: 20000,
      close: true,
      overlay: true,
      zindex: 999,
      title:
        "Halo " + name,
      icon: "fa fa-question-circle",
      message: "Apakah anda ingin mengganti password?",
      position: "center",
      buttons: [
        [
          "<button className='button'>Ya.</button>",
          () => {
            //hide
            iziToast.destroy();

            //logic here
            updatePassword(
              this.state.newPassword,
              uid,
              name
            )

          },
          true
        ]
      ]
    });
  }

  render() {

    const { newPassword, confirmNewPassword } = this.state

    return (
      <div className="dashboard-list-box margin-top-0">
        <h4 className="gray">Ganti Password</h4>
        <div className="dashboard-list-box-static">
          {/** ganti password */}
          <div className="my-profile">
            <form onSubmit={this.submitHandler.bind(this)}>
              <label>Password baru</label>
              <input
                type="password"
                name="newPassword"
                onChange={this.inputHandler}
                value={newPassword}
                required
              />
              <label>Konfirmasi Password Baru</label>
              <input
                type="password"
                name="confirmNewPassword"
                onChange={this.inputHandler}
                value={confirmNewPassword}
                required
              />
              <button type="submit" className="button margin-top-15">
                Ganti Password
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Password;
