import React, { Component } from "react"
import firebase from "firebase/app"
import "firebase/database"
import "firebase/functions"
import Edotensei from "edotensei"
import izitoast from "izitoast"
import "izitoast/dist/css/iziToast.min.css"
import iziToast from "izitoast"

import Navbar from "../includes/admin-components/admin-navigations"
import Sidebar from "../includes/admin-components/admin-sidebar"
import ChangePassword from "./password"


const { add } = Edotensei

const Scripts = [{ src: "/scripts/jquery-2.2.0.min.js", rel: "preload" }, { src: "/scripts/mmenu.min.js", defer: true }, { src: "/scripts/chosen.min.js", defer: true }, { src: "/scripts/slick.min.js", defer: true }, { src: "/scripts/rangeslider.min.js", defer: true }, { src: "/scripts/magnific-popup.min.js", defer: true }, { src: "/scripts/waypoints.min.js", defer: true }, { src: "/scripts/counterup.min.js", defer: true }, { src: "/scripts/tooltips.min.js", defer: true }, { src: "/scripts/custom.js", defer: true }];

const updateData = async (uid, data) => {

  try {

    data.uid = uid

    const updateUserInfo = firebase.functions().httpsCallable("updateUserInfo")

    const result = await updateUserInfo(data)

    iziToast.destroy()

    iziToast.success({
      title: "Profil",
      message: result.data.message,
      position: "topRight"
    })

  } catch (error) {

    iziToast.destroy()

    iziToast.error({
      message: "Nomor telpon yang anda masukkan telah digunakan",
      title: "Gagal memperbarui profil",
      position: "topRight"
    })

  }

}


class Profil extends Component {
  constructor() {
    super()

    this.state = {
      photoFile: [],
      photoDisplay: "",
      name: "",
      regency: "",
      phone: "",
      address: "",
      description: "",
      progress: 0,
      isLoaded: false
    }

    this.inputHandler = this.inputHandler.bind(this)
  }

  componentDidMount() {
    //add scripts
    add(Scripts)
  }

  async componentWillMount() {

    const { picture, uid, name, phone_number } = this.props.auth.data

    let snap = await firebase.database().ref("users").child(uid).once("value")

    snap = snap.exists() ? snap.val() : { regency: "", description: "", address: "" }

    !this.isCancelled &&
      this.setState({
        name: name,
        photoDisplay: picture,
        regency: snap.regency,
        phone: phone_number,
        address: snap.address,
        description: snap.description,
        isLoaded: true
      })

  }

  componentWillUnmount() {

    this.isCancelled = true
  }


  photoHandler(e) {

    const { picture } = this.props.auth.data

    if (e.target.files.length === 0) {

      this.setState({
        photoFile: [],
        photoDisplay: picture
      })

      return
    }

    this.setState({
      photoFile: e.target.files,
      photoDisplay: URL.createObjectURL(e.target.files[0])
    })
  }

  /**
   * handle input
   * @param {event} e
   */
  inputHandler(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  submitWithPhoto() {

    izitoast.info({
      close: false,
      timeout: false,
      drag: false,
      overlay: true,
      position: "center",
      title: "halo " + this.state.name,
      message: "mohon tunggu, sedang memperbarui profil"
    })

    const files = this.state.photoFile[0]

    /**
     * Create a root reference
     * @type {firebase.storage.Reference}
     */
    let storageRef = firebase.storage().ref()

    /**
     *  Create a reference to 'campaign/image_name'
     * @type {firebase.storage.Reference}
     */
    let blogImage = storageRef.child("users/" + files.lastModified + ".jpg")

    /**
     * Create the file metadata
     * @type {{contentType: string}}
     */
    let metadata = {
      contentType: "image/jpeg"
    }

    /**
     * update isuploading to true
     */
    this.setState({ progress: 0 })

    /**
     * Upload file and metadata to the object 'campaign/image_name'
     * @type {firebase.storage.UploadTask}
     */
    let uploadTask = blogImage.put(files, metadata)

    /**
     * Listen for state changes, errors, and completion of the upload.
     * or 'state_changed'
     */
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      snapshot => {
        //Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        let progress = Math.ceil(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )

        //update state
        this.setState({ progress })
      },
      /**
       * error handler
       * @param error
       */
      error => {
        /**
         * A full list of error codes is available at
         * https://firebase.google.com/docs/storage/web/handle-errors
         */
        this.setState({ image: false })
        //alert
        alert(error.code)
      },
      () => {
        //Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          //todo update state image
          const data = {
            displayName: this.state.name,
            phoneNumber: this.state.phone,
            photoURL: downloadURL,
            address: this.state.address,
            regency: this.state.regency,
            description: this.state.description
          }

          //get uid
          const { uid } = this.props.auth.data

          //update data
          updateData(uid, data)
        })
      }
    ) //end uploadtask
  }

  submitWithOutPhoto() {

    izitoast.info({
      close: false,
      timeout: false,
      drag: false,
      overlay: true,
      position: "center",
      title: "halo " + this.state.name,
      message: "mohon tunggu, sedang memperbarui profil"
    })

    const data = {
      displayName: this.state.name,
      phoneNumber: this.state.phone,
      photoURL: this.state.photoDisplay,
      address: this.state.address,
      regency: this.state.regency,
      description: this.state.description
    }

    //get uid
    const { uid } = this.props.auth.data

    //update data
    updateData(uid, data)
  }

  /**
   * submit detail handler
   * @param {event} e
   */
  submitDetail(e) {

    e.preventDefault()

    //dialog
    izitoast.info({
      timeout: 20000,
      close: true,
      overlay: true,
      zindex: 999,
      title: "Halo " + this.state.name,
      icon: "fa fa-question-circle",
      message: "Apakah informasi yang anda masukkan sudah benar?",
      position: "center",
      buttons: [
        [
          "<button className='button'>Ya, Sudah benar.</button>",
          () => {
            //hide
            izitoast.destroy()
            if (this.state.photoFile.length > 0) {
              this.submitWithPhoto(this)
            } else {
              this.submitWithOutPhoto(this)
            }
          },
          true
        ]
      ]
    })
  }

  render() {

    const { picture, uid, name } = this.props.auth.data

    return (
      <div>
        {/* Load Navigation html Syntax */}
        <Navbar picture={picture} name={name} />
        {/*End*/}

        <div id="dashboard">
          {/* Sidebar*/}
          <Sidebar uid={uid} />
          {/* End Sidebar*/}

          {/*Content*/}
          <div className="dashboard-content">
            <div id="titlebar">
              <div className="row">
                <div className="col-md-12 col-s-12">
                  <h2>Profil</h2>
                  <nav id="breadcrumbs">
                    <ul>
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li>profil</li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>

            <div hidden={!this.state.isLoaded} className="row">
              {/**profil */}
              <div className="col-lg-6 col-md-12">
                <div className="dashboard-list-box margin-top-0">
                  <h4 className="gray">Detail Profil</h4>
                  <div className="dashboard-list-box-static clearfix">
                    {/** avatat */}
                    <div
                      style={{ width: "240px", height: "240px" }}
                      className="edit-profile-photo"
                    >
                      <img
                        width="100%"
                        height="100%"
                        src={this.state.photoDisplay}
                        alt=""
                      />
                      <div className="change-photo-btn">
                        <div className="photoUpload">
                          <span>
                            <i className="fa fa-upload" /> Ganti poto profil
                          </span>
                          <input
                            type="file"
                            onChange={this.photoHandler.bind(this)}
                            accept="image/x-png,image/gif,image/jpeg"
                            className="upload"
                          />
                        </div>
                      </div>
                    </div>

                    <form onSubmit={this.submitDetail.bind(this)}>
                      {/** detail */}
                      <div className="my-profile">
                        <label>Nama</label>
                        <input
                          type="text"
                          name="name"
                          onChange={this.inputHandler}
                          value={this.state.name}
                          required
                        />

                        <label>Kota/Kabupaten</label>
                        <input
                          type="text"
                          onChange={this.inputHandler}
                          name="regency"
                          value={this.state.regency}
                          required
                        />

                        <label>No Telp</label>
                        <input
                          type="text"
                          name="phone"
                          onChange={this.inputHandler}
                          value={this.state.phone}
                          required
                        />

                        <label>Alamat</label>
                        <input
                          type="text"
                          name="address"
                          onChange={this.inputHandler}
                          value={this.state.address}
                          required
                        />

                        <label>Ceritakan diri anda</label>
                        <textarea
                          name="description"
                          id="notes"
                          cols="30"
                          rows="10"
                          onChange={this.inputHandler}
                          value={this.state.description}
                          required
                        />
                      </div>

                      <button
                        style={{ float: "right" }}
                        className="button margin-top-15 right"
                      >
                        Simpan perubahan
                      </button>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <ChangePassword info={{ uid, name }} />
              </div>
            </div>

            <center hidden={this.state.isLoaded}>
              <br />
              <br />
              <div className="lds-dual-ring" />
              <br />
              <br />
              <br />
            </center>

            <div className="col-md-12">
              <div className="copyrights">
                © 2018 Satukebaikan indonesia. All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
        {/*End*/}
      </div>
    )
  }
}

export default Profil
