import React, { Component } from "react"
import Edotensei from "edotensei"
import firebase from "firebase/app"
import "firebase/database"

import Navbar from "../includes/admin-components/admin-navigations"
import Sidebar from "../includes/admin-components/admin-sidebar"

const { add } = Edotensei

const Scripts = [{ src: "/scripts/jquery-2.2.0.min.js", rel: "preload" }, { src: "/scripts/mmenu.min.js", defer: true }, { src: "/scripts/chosen.min.js", defer: true }, { src: "/scripts/slick.min.js", defer: true }, { src: "/scripts/rangeslider.min.js", defer: true }, { src: "/scripts/magnific-popup.min.js", defer: true }, { src: "/scripts/waypoints.min.js", defer: true }, { src: "/scripts/counterup.min.js", defer: true }, { src: "/scripts/tooltips.min.js", defer: true }, { src: "/scripts/custom.js", defer: true }]

const donatur = (obj, email) => {

  let jumlahDonasi = Object.keys(obj).filter(key => obj[key].contribute)

  jumlahDonasi = jumlahDonasi.map(i => obj[i].contribute)

  jumlahDonasi = [].concat.apply([], jumlahDonasi.map(i => Object.values(i).map(item => item)))

  jumlahDonasi = jumlahDonasi.filter(i => i.email === email)

  const panding = jumlahDonasi.filter(i => i.status.toLowerCase() === "pending")

  const approved = jumlahDonasi.filter(i => i.status.toLowerCase() === "approved")

  const canceled = jumlahDonasi.filter(i => i.status.toLowerCase() === "canceled")

  let totalDonasi = jumlahDonasi.filter(i => i.status.toLowerCase() === "approved") // switch to approved

  totalDonasi = totalDonasi.map(item => item.donation ? (item.donation + item.uniqueCode) : 0)

  totalDonasi = totalDonasi.length > 0 ? totalDonasi.reduce((total, num) => total + num) : 0

  return { jumlahDonasi: jumlahDonasi.length, panding: panding.length, approved: approved.length, canceled: canceled.length, totalDonasi }
}

const campaigner = obj => {

  if (!obj) return { total: 0, campaignSelesai: 0, campaignBerjalan: 0, totalDanaTerkumpul: 0 }

  const campaignSelesai = Object.keys(obj).filter(key => Date.parse(new Date(obj[key].deadline)) < Date.parse(new Date()))

  const campaignBerjalan = Object.keys(obj).filter(key => Date.parse(new Date(obj[key].deadline)) > Date.parse(new Date()))

  let totalDanaTerkumpul = Object.keys(obj).filter(key => obj[key].contribute)

  totalDanaTerkumpul = totalDanaTerkumpul.map(i => Object.values(obj[i].contribute).filter(item => item.status.toLowerCase() === "approved"))

  totalDanaTerkumpul = totalDanaTerkumpul.map(i => i.map(item => item.donation ? (item.donation + item.uniqueCode) : 0))

  totalDanaTerkumpul = [].concat.apply([], totalDanaTerkumpul.filter(i => i[0]))

  totalDanaTerkumpul = totalDanaTerkumpul.length > 0 ? totalDanaTerkumpul = totalDanaTerkumpul.reduce((total, num) => total + num) : 0

  return { total: Object.keys(obj).length, campaignSelesai: campaignSelesai.length, campaignBerjalan: campaignBerjalan.length, totalDanaTerkumpul }
}

class Dashboard extends Component {

  constructor(props) {
    super(props)

    this.state = {
      campaigner: {},
      donatur: {},

      isLoaded: false
    }

  }

  async componentWillMount() {

    const { uid, email } = this.props.auth.data

    const snap = await firebase.database().ref("campaign").orderByChild("uid").equalTo(uid).once("value")

    const snapshot = await firebase.database().ref("campaign").once("value")

    add(Scripts)

    if (!snap.exists() && !snapshot.exists()) return this.setState({ isLoaded: true })

    const { total, campaignBerjalan, campaignSelesai, totalDanaTerkumpul } = campaigner(snap.val())

    const { jumlahDonasi, panding, approved, canceled, totalDonasi } = donatur(snapshot.val(), email)

    this.setState({
      campaigner: {
        total,
        campaignSelesai,
        campaignBerjalan,
        totalDanaTerkumpul
      },
      donatur: {
        jumlahDonasi,
        panding,
        approved,
        canceled,
        totalDonasi
      },
      isLoaded: true
    })

  }

  render() {

    const { campaigner, donatur, isLoaded } = this.state

    const { picture, uid, name } = this.props.auth.data

    return !isLoaded
      ? (
        <center className="vertical-center">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <br />
          <br />
          <img style={{ width: "200px" }} src="/images/logo-a.png" alt="satukebaikan" />
        </center>
      )
      : (
        <div>
          <Navbar picture={picture} name={name} />

          <div id="dashboard">

            <Sidebar uid={uid} />

            <div className="dashboard-content">

              <div className="row">
                <div className="col-md-6">
                  <div className="dashboard-list-box" style={{ background: "#fff" }}>
                    <h4>
                      Donasiku
                  </h4>
                    <div className="row" style={{ padding: "25px 30px" }} >
                      <div className="col-md-12">
                        <center>
                          <h3>
                            <b>Jumlah Donasi</b>
                          </h3>
                          <h1><b style={{ color: "rgb(255, 81, 0)" }}>{donatur.jumlahDonasi || 0}</b></h1>
                          <br />
                          <table style={{ border: "none" }}>
                            <thead>
                              <tr>
                                <td style={{ textAlign: "center", width: "33.3%" }}>
                                  <b>Pending</b>
                                  <br />
                                  <b style={{ color: "rgb(255, 81, 0)" }}>{donatur.panding || 0}</b>
                                </td>
                                <td style={{ textAlign: "center", width: "33.3%" }}>
                                  <b>Approved</b>
                                  <br />
                                  <b style={{ color: "rgb(255, 81, 0)" }}>{donatur.approved || 0}</b>
                                </td>
                                <td style={{ textAlign: "center", width: "33.3%" }}>
                                  <b>Canceled</b>
                                  <br />
                                  <b style={{ color: "rgb(255, 81, 0)" }}>{donatur.canceled || 0}</b>
                                </td>
                              </tr>
                            </thead>
                          </table>

                        </center>
                      </div>

                      <div className="col-md-12">
                        <center>
                          <h3>
                            <b>Total Donasi</b>
                          </h3>
                          <h1><b style={{ color: "rgb(255, 81, 0)" }}>Rp. {donatur.totalDonasi ? donatur.totalDonasi.toLocaleString() : 0}</b></h1>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="dashboard-list-box" style={{ background: "#fff" }}>
                    <h4>
                      Kampanyeku
                  </h4>
                    <div className="row" style={{ padding: "25px 30px" }} >
                      <div className="col-md-12">
                        <center>
                          <h3>
                            <b>Jumlah Kampanye</b>
                          </h3>
                          <h1><b style={{ color: "rgb(255, 81, 0)" }}>{campaigner.total || 0}</b></h1>
                          <br />
                          <table style={{ border: "none" }}>
                            <thead>
                              <tr>
                                <td style={{ textAlign: "center", width: "50%" }}>
                                  <b>Berjalan</b>
                                  <br />
                                  <b style={{ color: "rgb(255, 81, 0)" }}>{campaigner.campaignBerjalan || 0}</b>
                                </td>
                                <td style={{ textAlign: "center", width: "50%" }}>
                                  <b>Selesai</b>
                                  <br />
                                  <b style={{ color: "rgb(255, 81, 0)" }}>{campaigner.campaignSelesai || 0}</b>
                                </td>
                              </tr>
                            </thead>
                          </table>

                        </center>
                      </div>

                      <div className="col-md-12">
                        <center>
                          <h3>
                            <b>Total Dana Terkumpul</b>
                          </h3>
                          <h1><b style={{ color: "rgb(255, 81, 0)" }}>Rp. {campaigner.totalDanaTerkumpul ? campaigner.totalDanaTerkumpul.toLocaleString() : 0}</b></h1>
                        </center>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div className="col-md-12">
                <div className="copyrights">
                  © 2018 Satukebaikan indonesia. All Rights Reserved.
                </div>
              </div>

            </div>
            {/*End*/}
          </div>
        </div>
      );
  }
}

export default Dashboard;
