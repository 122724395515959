import React, { Component } from "react"
import Edotensei from "edotensei"
import izitoast from "izitoast"
import firebase from "firebase/app"
import "firebase/functions"
import dateFormat from "dateformat"

import Navbar from "../includes/admin-components/admin-navigations"
import Sidebar from "../includes/admin-components/admin-sidebar"

const { add } = Edotensei

const Scripts = [{ src: "/scripts/jquery-2.2.0.min.js", rel: "preload" }, { src: "/scripts/mmenu.min.js", defer: true }, { src: "/scripts/chosen.min.js", defer: true }, { src: "/scripts/slick.min.js", defer: true }, { src: "/scripts/rangeslider.min.js", defer: true }, { src: "/scripts/magnific-popup.min.js", defer: true }, { src: "/scripts/waypoints.min.js", defer: true }, { src: "/scripts/counterup.min.js", defer: true }, { src: "/scripts/tooltips.min.js", defer: true }, { src: "/scripts/custom.js", defer: true }]

class Users extends Component {

  constructor() {

    super()

    this.state = {
      users: [],
      nama: "",
      email: "",
      password: "",
      rePassword: "",
      isLoading: false,
      isLoaded: true
    }
  }


  async componentWillMount() {

    //get users
    const getUsers = firebase.functions().httpsCallable("getUsers")

    const result = await getUsers()

    this.setState({ users: result.data.users, isLoaded: false })
  }

  componentDidMount() {
    // add script
    add(Scripts)
  }

  async addUserHandler(e) {

    //return false
    e.preventDefault()

    //get data
    let { nama, email, password, rePassword } = this.state

    if (password !== rePassword) {
      // tampilkan pesan error
      izitoast.error({
        message: "Password yang anda masukkan tidak sesuai",
        position: "topRight"
      })

      // kembalikan
      return
    }

    //update state
    this.setState({ isLoading: true })

    //test request
    try {

      const createUser = firebase.functions().httpsCallable("createUser")

      const result = await createUser({ displayName: nama, email, password })

      //tampilkan notif
      izitoast.success({
        title: "Sukses",
        message: result.data.message,
        position: "topRight"
      })

      setTimeout(() => {
        window.location.reload()
      }, 2000)

      //set State to default
      this.setState({
        nama: "",
        email: "",
        password: "",
        rePassword: "",
        isLoading: false
      })

    } catch (error) {

      izitoast.error({
        message: "email pengguna telah digunakan",
        position: "topRight"
      })

      //update state
      this.setState({ isLoading: false })

    }
  }

  /**
   * handle delete
   * @param {string} uid
   * @param {event} e
   */
  deleteUserHandler(uid, e) {

    e.preventDefault()

    izitoast.info({
      timeout: 20000,
      close: true,
      overlay: true,
      zindex: 999,
      title: "Hai..",
      icon: "fa fa-question-circle",
      message: "Apakah anda ingin menghapus pengguna ini?",
      position: "center",
      buttons: [
        [
          "<button className='button'>Ya, hapus sekarang!</button>",
          async () => {
            //hide
            izitoast.destroy()

            try {

              const deleteUser = firebase.functions().httpsCallable("deleteUser")

              const result = await deleteUser({ uid })

              izitoast.success({
                timeout: 2000,
                title: "Sukses",
                message: result.data.message,
                position: "topRight"
              })

              setTimeout(() => {
                window.location.reload()
              }, 2000)

            } catch (error) {

              izitoast.error({
                title: "Gagal menghapus pengguna",
                message: "Coba lagi",
                position: "topRight"
              })
            }
          },
          true
        ]
      ]
    })
  }

  render() {

    const { picture, uid, name } = this.props.auth.data

    if (uid !== process.env.REACT_APP_SUPER_ADMIN_UID) return (window.location.href = "/dashboard/login")

    return (
      <div>
        {/* Load Navigation html Syntax */}
        <Navbar picture={picture} name={name} />
        {/*End*/}

        <div id="dashboard">
          {/* Sidebar*/}
          <Sidebar uid={uid} />
          {/* End Sidebar*/}

          {/*Content*/}
          <div className="dashboard-content">
            <div id="titlebar">
              <div className="row">
                <div className="col-md-12">
                  <h2>Setting Pengguna</h2>
                  <nav id="breadcrumbs">
                    <ul>
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li>Pengguna</li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>

            <div className="row" >
              <div className="col-lg-12 col-md-12">
                <div className="dashboard-list-box margin-top-0 clearfix" hidden={this.state.isLoaded}>
                  <div className="sort-by">
                    <div className="sort-by-select clearfix">
                      <a
                        style={{ float: "right" }}
                        href="#small-dialog"
                        className="button popup-with-zoom-anim"
                      >
                        <i className="sl sl-icon-plus" /> Tambah Pengguna
                      </a>
                    </div>
                  </div>

                  <div id="small-dialog" className="zoom-anim-dialog mfp-hide" style={{ margin: "40px auto" }}>
                    <div
                      style={{ background: "transparent", marginBottom: "0" }}
                      className="small-dialog-header"
                    >
                      <h3>Tambah Pengguna</h3>
                    </div>
                    <div className="message-reply margin-top-0 clearfix">
                      <form onSubmit={this.addUserHandler.bind(this)}>
                        <div className="form-row form-row-wide">
                          <label htmlFor="username">Nama</label>
                          <input
                            type="text"
                            className="input-text"
                            name="nama"
                            onChange={e =>
                              this.setState({ nama: e.target.value })
                            }
                            value={this.state.nama}
                            required
                          />
                        </div>

                        <div className="form-row form-row-wide">
                          <label htmlFor="username">Email</label>
                          <input
                            type="email"
                            className="input-text"
                            name="email"
                            onChange={e =>
                              this.setState({ email: e.target.value })
                            }
                            value={this.state.email}
                            required
                          />
                        </div>

                        <div className="form-row form-row-wide">
                          <label htmlFor="username">Password</label>
                          <input
                            type="password"
                            className="input-text"
                            name="password"
                            onChange={e =>
                              this.setState({ password: e.target.value })
                            }
                            value={this.state.password}
                            required
                          />
                        </div>

                        <div className="form-row form-row-wide">
                          <label htmlFor="username">Ulangi Password</label>
                          <input
                            type="password"
                            className="input-text"
                            name="rePassword"
                            onChange={e =>
                              this.setState({ rePassword: e.target.value })
                            }
                            value={this.state.rePassword}
                            required
                          />
                        </div>

                        <button
                          style={{
                            background: "rgb(255, 81, 0)",
                            float: "right"
                          }}
                          className="button"
                          disabled={this.state.isLoading}
                        >
                          {this.state.isLoading
                            ? "Loading ..."
                            : "Tambahkan Pengguna"}
                        </button>
                      </form>
                    </div>
                  </div>

                  <h4>Daftar Pengguna</h4>

                  <ul>
                    {this.state.users.map((item, i) => (
                      <li key={i} className={item.accountVerified === "pending" ? "pending-booking" : "approved-booking"}>
                        <div className="list-box-listing bookings">
                          <div className="list-box-listing-img">
                            <img src={item.photoURL} alt="" />
                          </div>
                          <div className="list-box-listing-content">
                            <div className="inner">
                              <h3>
                                {item.displayName}{" "}
                                {item.uid ===
                                  process.env.REACT_APP_SUPER_ADMIN_UID ? (
                                    <span className="booking-status approved">
                                      Super Admin
                                    </span>
                                  ) : item.accountVerified === "pending"
                                    ? <span className="booking-status">
                                      Akun Belum Terverifikasi
                                      </span>
                                    : <span className="booking-status">
                                      Akun Terverifikasi
                                      </span>
                                }
                              </h3>

                              <div className="inner-booking-list">
                                <h5 style={{ marginRight: "5px" }}>Dibuat :  </h5>
                                <ul className="booking-list">
                                  <li className="highlighted">
                                    {dateFormat(new Date(item.metadata.creationTime), "dd mmmm yyyy H:MM")}
                                  </li>
                                </ul>
                              </div>

                              <div className="inner-booking-list">
                                <h5 style={{ marginRight: "5px" }}>Terakhir login : </h5>
                                <ul className="booking-list">
                                  <li className="highlighted">
                                    {item.metadata.lastSignInTime
                                      ? dateFormat(new Date(item.metadata.lastSignInTime), "dd mmmm yyyy H:MM")
                                      : "pengguna belum login sama sekali"}
                                  </li>
                                </ul>
                              </div>

                              <div className="inner-booking-list">
                                <h5 style={{ marginRight: "5px" }}>Email & No. Telp : </h5>
                                <ul className="booking-list">
                                  <li>{item.email}</li>
                                  <li>{item.phoneNumber}</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="buttons-to-right">
                          {
                            item.uid !== process.env.REACT_APP_SUPER_ADMIN_UID ?
                              <a
                                href={`/dashboard/pengaturan/pengguna/detail/${item.uid}`}
                                className="button gray"
                              >
                                <i className="sl sl-icon-user-following" /> Detail akun
                              </a> : null
                          }

                          {item.uid !==
                            process.env.REACT_APP_SUPER_ADMIN_UID ? (
                              <a
                                href="#!"
                                onClick={this.deleteUserHandler.bind(this, item.uid)}
                                className="button gray reject"
                              >
                                <i className="sl sl-icon-close" /> Hapus Pengguna
                            </a>
                            ) : null}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <center hidden={!this.state.isLoaded}>
                <br />
                <br />
                <div className="lds-dual-ring" />
                <br />
                <br />
                <br />
              </center>

              <div className="col-md-12">
                <div className="copyrights">
                  © 2018 Satukebaikan indonesia. All Rights Reserved.
                </div>
              </div>

            </div>
          </div>
          {/*End*/}
        </div>
      </div>
    )
  }
}

export default Users
